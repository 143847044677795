@import '../../../styles/Variables.module'

@mixin sm-text
    font-size: 4.2rem
    line-height: 10rem

.players_subscription
    display: flex
    flex-direction: column
    margin-right: 7rem
    &__title
        font-size: 2.667rem
        line-height: 2.333rem
        color: $blue-color
        font-weight: 400
        font-family: "Lato"
        .red
            color: $contrast-color
.heading
    display: flex
    justify-content: flex-start
    margin: 2rem 0 1rem
    gap: 6rem
    &__players,
    &__info
        font-size: 1.11rem
        line-height: 1.889rem
        font-weight: 700
        color: $blue-color

@media (max-width: $screen-sm)
    .players_subscription
        width: 95%
        box-sizing: border-box
        padding: 2rem 5rem 2rem 2rem
        align-self: start
        margin: 7rem 3rem 3rem
        background-color: #fff
        border-radius: 1rem
        box-shadow: 0 .1563rem .651rem #c6dceabf
        &__title
            font-size: 7rem
            line-height: 11rem
    .heading
        gap: 6rem
        &__players,
        &__info
            @include sm-text
