$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px
$text-color: #003056
$contrast-color: #dd373e

@mixin button
    border: none
    border-radius: 2rem
    font-size: 1.1rem
    line-height: 1.8rem
    text-align: center
    padding: 0.7rem 2rem
    margin: 1rem 0.5rem
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none
        color: #ffffff
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
        color: #ffffff

.popup__wrapper
    position: fixed
    z-index: 40
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0.9
    background-color: $text-color
.popup
    display: flex
    justify-content: flex-start
    align-items: center
    position: fixed
    z-index: 40
    top: 15%
    left: 18%
    width: 60%
    border-radius: 0.2rem
    background-color: #FFF
    margin: 0 auto
.closeImg
    position: absolute
    top: -3.8rem
    right: -2.2rem
    color: #FFF
    font-size: 3.5rem
    font-weight: 300
    cursor: pointer
.popup__description
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: flex-start
    height: 35rem
    padding-right: 2rem
.popup__title
    color: $text-color
    font-size: 1.7rem
    line-height: 3.7rem
    font-weight: 700
.popup__text
    color: $text-color
    font-size: 1.0rem
    line-height: 1.4rem
.popup__decorative_image
    width: 23rem
    height: 35rem
    margin-right: 2rem
    background-image: url('//img/young-football-player.webp')
    background-size: cover
    background-position: center center
    background-repeat: no-repeat
    border-radius: 0.2rem 0 0 0.2rem
.horizontal_line
    height: 12rem
    margin-left: -2rem
    width: calc(100% + 4rem)
    overflow: hidden
    border-bottom: 0.1rem solid #bfcdd9
.buttons
    align-self: flex-end
    display: flex
    justify-content: flex-end
.popup__button_cancel
    @include button
    background-color: #fff
    color: #003056
    text-decoration: underline
.popup__button_submit
    display: flex
    justify-content: center
    align-items: center
    @include button
    background-color: $contrast-color
    color: #fff
    font-weight: 500
.arrow
    padding-left: 1rem
    width: 2rem

@media (max-width: $screen-lg)
    .popup
        top: 20%
        left: 15%
        width: 65%
    .popup__title
        font-size: 2rem
        line-height: 4rem
    .popup__text
        font-size: 1.3rem
        line-height: 2rem
    .popup__button_cancel,
    .popup__button_submit
        font-size: 1.5rem
        line-height: 2.5rem

@media (max-width: $screen-md)
    .popup
        top: 20%
        left: 12%
        width: 75%
    .popup__description
        height: 45rem
    .popup__decorative_image
        height: 45rem
        width: 33rem
    .popup__title
        font-size: 2.5rem
        line-height: 5rem
    .popup__text
        font-size: 1.7rem
        line-height: 2.5rem
    .horizontal_line
        height: 15rem
    .popup__button_cancel,
    .popup__button_submit
        font-size: 1.9rem
        line-height: 2.7rem
        padding: 1rem 2.2rem
        margin: 1rem 0.5rem
        border-radius: 3rem

@media (max-width: $screen-sm)
    .popup
        top: 20%
        left: 5%
        width: 90%
        flex-direction: column
        border-radius: 1rem
    .closeImg
        top: -6.5rem
        right: -3.8rem
        font-size: 6rem
    .popup__decorative_image
        width: 100%
        background-position: 100% 100%
        margin-right: 0
        border-radius: 1rem 1rem 0 0
    .popup__title
        font-weight: normal
        font-size: 5rem
        line-height: 9rem
    .popup__text
        padding: 3rem 2.5rem
        font-size: 3rem
        line-height: 4rem
        text-align: center
    .horizontal_line
        margin: 0
        height: 5rem
    .popup__description
        justify-content: center
        align-items: center
        padding: 2rem
    .buttons
        padding-top: 2rem
        width: 100%
        justify-content: space-evenly
        align-self: center
    .popup__button_cancel,
    .popup__button_submit
        width: 40%
        font-size: 3rem
        line-height: 6rem
        border-radius: 4rem
    .arrow
        padding-left: 2rem
        width: 4.5rem
