@import ../../../../styles/Variables.module

.invite_popup
    position: fixed
    z-index: 40
    font-family: "Lato"
    background-color: #fff
    border-radius: 0.33rem
    top: 20%
    max-width: 34rem
    left: calc((100% - 34rem) / 2)
    &__title
        font-size: 1.5rem
        line-height: 4rem
        color: $blue-color
        text-align: center
        border-bottom: 0.1rem solid #bfcdd9
    &__label
        font-size: 1rem
        line-height: 2rem
        color: $blue-color
        font-weight: 700
        display: flex
        flex-direction: column
        align-items: flex-start
    &__textarea
        padding: 0.5rem
        font-size: 1rem
        color: $blue-color
        width: 30rem
        height: 11rem
        border: 0.1rem solid #bfcdd9
        border-radius: 0.33rem
    &__description
        font-size: 0.8rem
        line-height: 2rem
        color: $contrast-color
        font-style: italic
    .buttons
        display: flex
        justify-content: space-evenly
        align-items: center
        margin-top: 6rem
    .container
        padding: 2rem

@media (max-width: $screen-sm)
    .invite_popup
        border-radius: 0.66rem
        top: 20%
        max-width: 80rem
        left: calc((100% - 65rem) / 2)
        &__title
            font-size: 3rem
            line-height: 7rem
        &__label
            font-size: 2.5rem
            line-height: 6rem
        &__textarea
            padding: 1rem
            font-size: 2rem
            width: 60rem
            height: 30rem
            border-radius: 0.5rem
        &__description
            font-size: 2rem
            line-height: 3rem
