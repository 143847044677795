@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

input {
  outline: none;
}
select {
  outline: none;
}
select option {
  outline: none;
}
option {
  outline: none;
}
button {
  outline: none;
}

html,
body {
  font-family: 'Lato', sans-serif;
  font-size: 1vw;
  background: #ffffff;
}
a,
ol,
ul,
li {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}
li,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 11px;
}
::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
  border-color: #777;
  background-color: #aaa;
  transition: 0.2s linear;
}
::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  border-color: #555;
  background-color: #777;
}


.ant-switch-checked {
  background-color: #e1343f !important;
}