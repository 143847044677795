@import '../../../../styles/Variables.module'
@import '../../../../styles/VisuallyHidden.module'

.search
    display: flex
    justify-content: center
    align-items: flex-start
    gap: 2rem
    align-self: center
    margin: 2rem 0
    font-family: "Lato"
    &__input
        border: 0.1rem solid #b9cfdd
        border-radius: 0.33rem
        width: 30rem
        height: 3rem
        font-size: 1rem
        color: $blue-color
        padding-left: 0.5rem
    &__info
        font-size: 0.9rem
        line-height: 1.5rem
        color: $contrast-color
.search_result
    &__title
        font-size: 1.78rem
        line-height: 2.5rem
        color: $blue-color
        font-weight: 700

@media (max-width: $screen-sm)
    .search
        gap: 4rem
        align-self: center
        margin: 2rem 0
        &__input
            border-width: 0.2rem
            border-radius: 1rem
            width: 70rem
            height: 8rem
            font-size: 2.5rem
        &__info
            font-size: 3rem
            line-height: 4.5rem
    .search_result
        &__title
            display: none
