.img_back
  padding-top: 1.8rem
  position: absolute
  right: 0
  top: 3.75rem
  width: 100%
  z-index: 0

.main_title
  width: 100%
  height: 30.25rem
  background-size: cover
  padding: 5rem 13rem
  display: flex
  flex-direction: column
  justify-content: center
  .h2
    width: 24.938rem
    font-size: 3rem
    font-weight: 400
    line-height: 3rem
    color: #003056
    padding-bottom: 1.26rem
  .p
    font-size: 1.1458rem
    line-height: 1.875rem
    font-weight: 300
    color: #ffff1e
    width: 79.5%
.main_container::before
  content: ''
  position: absolute
  width: 100%
  height: 30.25rem
  padding: 5rem 13rem
  background-color: rgba(0,0,0,0.3)
  z-index: 1

.main_container__block
  z-index: 2
  width: 29.625rem
  height: 19.063rem
  margin-right: 55rem
  padding: 1.5rem 2rem 3.73rem
  box-shadow: 0 0 3.188rem rgba(0, 0, 0, 0.75)
  position: absolute
  display: flex
  flex-direction: column
  justify-content: flex-start
  background-color: #f4fbff
  border-radius: 2%

  h2
    width: 25.938rem
    margin: 0 auto
    font-size: 2.8rem
    font-weight: 400
    line-height: 3rem
    color: #003056
    padding-bottom: 1.26rem
  p
    width: 13.875rem
    height: 1.25rem
    color: #003056
    font-family: Lato, serif
    font-size: 1.375rem
    font-weight: 300
    line-height: 2.25rem
    padding-top: .5rem
    padding-bottom: 2.68rem

  .span_block_email
    width: 16.188rem
    color: #78a3be
    padding-right: 1.2rem
    &__a
      height: 1rem
      color: #dd373e
      font-weight: 400
      font-size: 1.125rem
      line-height: 2.25rem

.header_block
  color: #003056
  font-size: 1.375rem
  font-weight: 300
  font-family: Lato, serif
  line-height: 2.25rem
  height: 4.5rem
  width: 13.875rem