@import ../../../styles/Variables.module

@mixin text
  font-size: 1rem
  color: $blue-color


.invite_popup
  position: fixed
  z-index: 40
  background-color: #fff
  border-radius: 0.33rem
  top: 20%
  max-width: 34rem
  left: calc((100% - 34rem) / 2)
  &__title
    font-size: 1.5rem
    line-height: 4rem
    color: $blue-color
    text-align: center
    border-bottom: 0.1rem solid #bfcdd9
  &__label
    font-size: 1rem
    line-height: 2rem
    color: $blue-color
    font-weight: 700
    display: flex
    flex-direction: column
    align-items: flex-start
  &__description
    display: flex
    justify-content: center
    align-items: center
    font-size: 1rem
    line-height: 2rem
  .buttons
    display: flex
    justify-content: space-evenly
    align-items: center
    margin-top: 2rem
    button:not(:last-child)
      margin-right: 2rem
  .container
    padding: 2rem
    @media (max-width: $screen-sm)
      padding-bottom: 10rem

@media (max-width: $screen-sm)
  .invite_popup
    border-radius: 0.66rem
    top: 20%
    width: 93rem
    max-width: 93rem
    left: calc((100% - 94rem) / 2)
    &__title
      font-size: 6rem
      line-height: 14rem
    &__label
      font-size: 5rem
      line-height: 9rem
    &__textarea
      padding: 2rem
      font-size: 3rem
      width: 90rem
      height: 45rem
      border-radius: 0.5rem
    &__description
      margin-top: 1rem
      font-size: 4.5rem
      line-height: 5rem
      margin-bottom: 4rem
    button
      font-size: 5rem
      line-height: 8rem
.form
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 1rem 2.5rem 0.5rem
  &__label
    display: flex
    flex-direction: column
    @include text
    font-weight: 700
    line-height: 2.5rem
    @media (max-width: $screen-sm)
      line-height: 6rem
      font-size: 3.5rem
  &__input
    border: 0.1rem solid #b9cfdd
    border-radius: 0.33rem
    width: 22rem
    @include text
    height: 3rem
    padding-left: 0.5rem
    @media (max-width: $screen-sm)
      border: 0.35rem solid #b9cfdd
      width: 77rem
      height: 10.5rem
      padding-left: 1.75rem
      font-size: 3.5rem
  &__checkbox
    margin-right: 1rem
  &__label__checkbox
    font-size: 1rem
    margin-top: 1rem
    margin-left: 0.5rem
    @media (max-width: $screen-sm)
      font-size: 4rem
      margin-top: 3rem
      margin-left: 2rem
    input[type="checkbox"]:checked,
    input[type="checkbox"]:not(:checked)
      position: absolute
      left: -300rem
    input[type="checkbox"]:checked + label,
    input[type="checkbox"]:not(:checked) + label
      display: inline-block
      position: relative
      padding-left: 1.75rem
      line-height: 1.25rem
      cursor: pointer
      @media (max-width: $screen-sm)
        padding-left: 4.6rem
        line-height: 1.1
    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before
      content: ""
      position: absolute
      left: -0.5rem
      top: -0.2rem
      width: 1.625rem
      height: 1.625rem
      border: 0.125rem solid #dddddd
      background-color: #ffffff
      @media (max-width: $screen-sm)
        left: -1.5rem
        top: -0.6rem
        width: 4.875rem
        height: 4.875rem
        border: 0.375rem solid #dddddd
    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before
      border-radius: 0.125rem
    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after
      content: ""
      position: absolute
      -webkit-transition: all 0.2s ease
      -moz-transition: all 0.2s ease
      -o-transition: all 0.2s ease
      transition: all 0.2s ease
    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after
      left: -0.3125rem
      top: 0
      width: 1.35rem
      height: 0.9rem
      border-radius: 0.125rem
      border-left: 0.4rem solid #dd373e
      border-bottom: 0.4rem solid #dd373e
      -ms-transform: rotate(-45deg)
      transform: rotate(-45deg)
      @media (max-width: $screen-sm)
        left: -0.9375rem
        width: 4.05rem
        height: 2.7rem
        border-left: 1.2rem solid #dd373e
        border-bottom: 1.2rem solid #dd373e
    input[type="checkbox"]:not(:checked) + label:after
      opacity: 0
    input[type="checkbox"]:checked + label:before
      opacity: 1


.form_radio [type="radio"]:checked,.form_radio [type="radio"]:not(:checked)
  position: absolute
  left: -9999px


.form_radio [type="radio"]:checked + .form_radio_label,.form_radio [type="radio"]:not(:checked) + .form_radio_label
  position: relative
  padding: 0.375rem 0 0.25rem 2.375rem
  cursor: pointer
  line-height: 1.25rem
  display: inline-block
  margin-bottom: 0.25rem

.form_radio [type="radio"]:checked + .form_radio_label:before,.form_radio [type="radio"]:not(:checked) + .form_radio_label:before
  content: ''
  position: absolute
  left: 0
  top: 0
  width: 1.625rem
  height: 1.625rem
  border: 0.125rem solid #ddd
  border-radius: 100%
  background: #fff


.form_radio [type="radio"]:checked + .form_radio_label:after,.form_radio [type="radio"]:not(:checked) + .form_radio_label:after
  content: ''
  width: 1.25rem
  height: 1.3rem
  background: red
  position: absolute
  top: 0.31rem
  left: 0.31rem
  border-radius: 100%
  -webkit-transition: all .2s ease
  transition: all .2s ease


.form_radio [type="radio"]:not(:checked) + .form_radio_label:after
  opacity: 0
  -webkit-transform: scale(0)
  transform: scale(0)


.form_radio [type="radio"]:checked + .form_radio_label:after
  opacity: 1
  -webkit-transform: scale(1)
  transform: scale(1)


.button
  display: flex
  justify-content: center
  align-items: center
  color: #ffffff
  font-weight: 700
  border-radius: 6rem
  background-color: $blue-color
  padding: 1rem 1.5rem
  border: none
  outline: none
  &__image
    width: 1rem
    height: 1rem
    margin: 0 0.75rem 0 0
  &__award
    width: 100%
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: $hover-blue-color
    text-decoration: none
    color: #ffffff
    &:focus
      outline: 0.01rem solid $hover-blue-color
      outline-offset: 0.3rem
      color: #ffffff


@media (max-width: $screen-sm)
  .button
    font-size: 3.3rem
    line-height: 4rem
  .button
    padding: 1rem 3rem
    &__image
      width: 3rem
      height: 3rem
