@import '../../../styles/Variables.module'

@mixin text
  color: $blue-color
  font-size: 1rem
  line-height: 1.7rem

@mixin bold-text
  @include text
  font-weight: 700

.container
  overflow: hidden
  margin-left: 20rem
  padding-top: 3.7rem
  .main_title
    color: $blue-color
    font-size: 2.5rem
    line-height: 2.3rem

.subTitle1
  @include bold-text
  margin-top: 1.5rem
.text
  @include text
  color: #78a3be
.subTitle2
  @include bold-text
  margin-top: 1.7rem
.toggMakeActInact
  @include bold-text
  margin-top: 1.7rem
  margin-left: 7.8rem
  cursor: pointer
  &:hover
    text-decoration: underline

.payWereBtn
  margin-top: 1.7rem
  margin-left: 6.3rem

.contactEmailDiv
  display: flex

.contactEmailInput
  display: flex
  height: 3.1rem
  width: 14rem
  @include bold-text
  border: thin solid #78a3be
  padding: .6512rem .3rem
  border-radius: .3125rem
  margin-top: .5rem

.childEmailInput
  display: flex
  width: 14rem
  color: #000000
  padding: .6512rem .3rem
  line-height: 1.4rem
  border: thin solid #78a3be
  border-radius: .3125rem
  margin-top: .5rem

.button
  margin: 0
  padding: 0
  display: flex
  align-items: center
  color: $contrast-color
  font-size: 1rem
  margin-left: 2.6rem
  border: none
  background-color: inherit
  &:hover,
  &:active
    transform: scale(1.01)
    text-decoration: underline
    &:focus
      border-radius: 0.33rem
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem

.greyText
  color: #78a3be
  font-size: 1rem
  width: 14rem
.btnContEmail1
  color: $contrast-color
  font-size: 1rem
  margin-left: 12rem

.screenName
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 400
  color: #78a3be
  border-radius: .3125rem
  border: thin solid #b9cfdd
  padding: .6512rem 1rem
  margin: 1rem 3rem 1rem 0

.contactEmail
  display: flex

.status
  display: flex

.SquarContainer
  display: flex
  margin-left: 21rem

.editToolChangeOutPut
  background-color: #fff
  max-width: 2.3rem
  min-width: 2.3rem

.subAndCancelBtn
  display: flex
  margin-top: 2.1rem
  margin-bottom: 4.2rem
  .submitButton
    width: 13rem
    height: 3.1rem
    background-color: $contrast-color
    color: #fff
    border-radius: 2rem
    border: none
    text-align: center
  .cancelButton
    margin-left: 2.1rem
    width: 13rem
    height: 3.1rem
    color: #78a3be
    border-radius: 2rem
    text-align: center
    background-color: transparent
    border: thin solid #78a3be
.online_profiles
  .online_profiles_title
    font-size: 2rem
    line-height: 4rem
    margin-top: 2.5rem
    color: $blue-color
  h5
    color: $blue-color
    font-size: 1rem
    font-weight: 700
    line-height: 1.8rem
    margin-top: 1rem
  p
    color: #78a3be
    font-size: 1rem
    line-height: 1.8rem
    margin-top: 1px

.textGroup
  display: flex

.redText
  color: #f01515 !important
  margin-left: 3.1rem

.redText2
  color: #f01515 !important
  margin-left: 12rem

.changeParPassDiv
  margin-top: 1rem

.editTool
  background-color: #78a3be
  color: #ffffff
  font-size: 1rem
  font-weight: 700
  line-height: 1.8rem
  padding: .3rem .26rem .26rem 1.8rem
  border-radius: 2.6rem
  margin-top: .5rem
  display: flex
  width: 17rem
  height: 3.1rem
  display: flex
  align-items: center
  p img
    width: 1.4rem
.editToolText
  display: flex
  color: #ffffff
  align-items: center
  margin-left: .67rem

.screenBirthDiv
  display: flex
  span
    color: #ec1414
    margin-left: .26rem

.NameForm
  width: 14rem
  height: 2.1rem
  color: #78a3be
  font-size: 1rem
  border-radius: .1rem

.birthFormDiv
  margin-left: 2.1rem

.birthForm
  height: 3.1rem
  width: 8.4rem
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 400
  color: #78a3be
  border-radius: .3125rem
  border: thin solid #b9cfdd
  padding: .6512rem 1rem
  margin: 1rem 3rem 1rem 0

.activeInactiveDiv
  display: flex

.iconContainer
  img
    display: inline-block
    margin: 2rem .2rem 2rem .2rem
    width: 5rem
    height: 5rem
  .submitButton
    margin-left: 18rem
    width: 11.5rem
    height: 3.1rem
    background-color: #dd373e
    color: #fff
    border-radius: 2rem
    border: none
    text-align: center
  .spinner
    margin-top: 1rem

.iconEffects:hover
  -webkit-transform: scale(1.1)
  -ms-transform: scale(1.1)
  transform: scale(1.1)
  box-shadow: 0 0 3px 4px #bebcbc
  transition: .2s
  cursor: pointer

.iconChoiseEffect
  -webkit-transform: scale(1.1)
  -ms-transform: scale(1.1)
  transform: scale(1.1)
  box-shadow: 0 0 3px 4px #bebcbc
  transition: .2s
  cursor: pointer
  width: 200px
  height: 200px
  transition: transform .8s
  -webkit-transition: -webkit-transform .8s ease-in-out
  -ms-transition: -ms-transform .8s ease-in-out
  &:hover
    transform: rotate(90deg)
    -ms-transform: rotate(360deg)
    -webkit-transform: rotate(360deg)

.infoPageContainer
  display: flex

.card
  max-width: 20.52rem
  min-width: 20.52rem
  margin-left: 13rem
  display: flex
  flex-direction: column
  justify-content: space-between
  background: #ffffff
  padding: 2rem 1.5789rem
  border-radius: .3125rem
  box-shadow: 0 .1563rem .651rem #c6dceabf
  .cardTitle
    margin-bottom: 1rem
    h4
      font-size: 1.4583rem
      line-height: 1.7708rem
      font-weight: 400
      color: #003056
    p
      font-size: .7292rem
      font-weight: 400
      color: #78a3be

.cardTitleGreyStr
  padding-top: 0.5rem

.dirthFormDiv
  select
    flex-direction: column
    justify-content: center
    width: 11.85rem
    margin-bottom: .6rem
    height: 3.15rem
    padding: .6512rem .8rem
    font-size: .9rem
    line-height: 1.7708rem
    font-weight: 400
    color: #78a3be
    border-radius: .3125rem
    border: thin solid #b9cfdd
    background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
    background-size: 1.3rem
    -webkit-appearance: none
    background-position-x: 9.5rem

@media (max-width: $screen-sm)
  .container
    margin-left: 5rem
    .main_title
      font-size: 5rem
      line-height: 7rem
      text-align: center
  .subTitle1,
  .subTitle2
    font-size: 3rem
    line-height: 4rem
    margin-top: 2.5rem
  .greyText,
  .button,
  .text
    font-size: 3rem
    line-height: 4rem
    width: 40rem
  .btnContEmail1
    font-size: 3rem
    line-height: 4rem
  .online_profiles
    .online_profiles_title
      font-size: 4rem
      line-height: 5rem
      margin-top: 3rem
    p,
    h5
      font-size: 3rem
      line-height: 4rem
      margin-top: 2rem
  .childEmailInput
    font-size: 3rem
    line-height: 4rem
    border-radius: 1rem
    width: 40rem
  .contactEmailInput
    font-size: 3rem
    line-height: 4rem
    border-radius: 1rem
    width: 40rem
    height: 7rem
  .screenName
    font-size: 3rem
    line-height: 4rem
    border-radius: 1rem
  .screenBirthDiv
    flex-wrap: wrap
  .editTool
    height: 8rem
    width: 45rem
    border-radius: 6rem
    margin-top: 2rem
    p img
      width: 3rem
      margin-left: 1rem
    .editToolText
      font-size: 3rem
      line-height: 4rem
      margin-left: 2rem
  .dirthFormDiv
    select
      font-size: 3rem
      line-height: 4rem
      width: 25rem
      height: 7rem
      background-size: 2.3rem
      padding: 1rem 2rem
      background-position-x: 11rem
      border-radius: 1rem
  .subAndCancelBtn
    margin-top: 2.1rem
    margin-bottom: 4.2rem
    .submitButton,
    .cancelButton
      width: 25rem
      height: 7rem
      border-radius: 6rem
      font-size: 3rem
      line-height: 4rem
