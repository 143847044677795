@import ../../../../../../../styles/Variables.module

@mixin text
    padding: 0 1rem
    box-sizing: border-box
    font-size: 1rem
    line-height: 4rem
@mixin cellWithLogo
    display: flex
    align-items: center
@mixin sm-text
    font-size: 2.5rem
    line-height: 7rem

.row
    border-bottom: 0.05rem solid #ccdfe9
    width: 100%
    box-sizing: border-box
.icon
    width: 1.2rem
    height: auto
.cell
    @include text
    color: $contrast-color
    &_first
        @include text
        width: 8%
        color: $blue-color
        font-weight: 500
    &_player_name
        @include text
        @include cellWithLogo
        color: $blue-color
    &_player_manager
        @include text
        @include cellWithLogo
        color: $blue-color
        &::after
            color: $contrast-color
            content: ' *'
    &_logo
        margin-right: 1rem
        border-radius: 50%
        border: 0.1rem solid $border-color
        max-width: 3rem
        max-height: 3rem
        padding: 0.25rem 0.5rem
        overflow: hidden

@media (max-width: $screen-sm)
    .icon
        width: 2rem
        height: auto
    .cell
        @include sm-text
        &_first,
        &_player_name,
        &_player_manager
            @include sm-text

