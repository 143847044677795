$screen-sm: 576px
.createPlayer
  display: flex
  justify-content: center
  align-items: center
  background: #dd373e
  font-size: 1.2rem
  height: 14%
  border-radius: 10rem
  color: #ffffff
  font-weight: 1000
  margin: 30px
  margin-left: auto
  margin-right: auto
  width: 18%
  &:hover
    text-decoration: underline
    color: #ffff
.mobile_container
  display: none
.section
  margin-bottom: 10rem
  .thead
    display: flex
    font-size: .9375rem
    line-height: 1.7708rem
    color: #78a3be
    margin-left: 5rem
    @media (max-width: $screen-sm)
      display: none
    &__row
      margin-top: 1rem
      margin-bottom: 1rem
      width: 13rem
      padding: 0 1.1rem
      &_small
        padding-left: 1rem
        width: 5rem
        margin: 1rem 5rem 1rem .5rem
  .tbody
    display: flex
    flex-direction: column
    font-size: .9375rem
    line-height: 1.7708rem
    font-weight: 400
    color: #003056
    background-color: #ffffff
    padding: 0 1rem 1rem
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    .mobile_header
      display: none
    .photo_item
      width: 4rem
      display: flex
      justify-content: center
      align-items: center
      border-radius: 0.33rem
      padding: 0.2rem 1rem
      &:hover
        -webkit-transform: scale(1.01)
        -ms-transform: scale(1.01)
        transform: scale(1.01)
        box-shadow: 0px 5px 9px #adc8d8
        cursor: pointer
    .photo
      width: 4rem
      max-height: 4.5rem
      border-radius: 0.33rem
    .avatar_item
      width: 4rem
      display: flex
      justify-content: center
      align-items: center
      border-radius: 0.33rem
      background-color: #e3f0f7
      border: 0.1px solid #b9cfdd
      padding: 0.2rem 0.5rem
      filter: saturate(130%)
      &:hover
        -webkit-transform: scale(1.01)
        -ms-transform: scale(1.01)
        transform: scale(1.01)
        box-shadow: 0px 5px 9px #adc8d8
        filter: saturate(150%)
        cursor: pointer
    &__row
      border-bottom: thin double #c6dceabf
      display: flex
      align-items: center
      height: 5rem
      @media (max-width: $screen-sm)
        display: none
      &_bigEl
        width: 12.5rem
        padding: 0 0 0 1rem
      &_bigEmail
        padding-left: 1.5rem
        width: 11rem
      &_smallEl
        display: flex
        justify-content: center
        width: 10rem
        padding-left: 2.5rem
        margin-right: 2rem
  .button
    font-size: .9375rem
    line-height: 1.7708rem
    font-weight: 400
    color: #ffffff
    background-color: #dd373e
    padding: .235rem .9rem
    margin: 0 1.2rem
    border-radius: 2rem
    border: none
    outline: none
    cursor: pointer
  .bodered
    color: #78a3be
    background-color: transparent
    border: .1rem solid #78a3be
  .img
    width: 2rem
.avatar_picture
  width: 2.8rem
  padding: 0.2rem 0.5rem

@media (max-width: $screen-sm)
  .section
    margin-top: 9rem
    margin-bottom: 20rem
    .tbody
      .avatar_item
        border-radius: 1rem
      .photo
        width: 15rem
        padding: 1rem 1.5rem
        border-radius: 2rem
        max-height: 16rem
        &:hover
          -webkit-transform: scale(1.01)
          -ms-transform: scale(1.01)
          transform: scale(1.01)
          box-shadow: 0px 5px 9px #adc8d8
          cursor: pointer
      .avatar_item
        width: 12rem
      .mobile_header
        display: flex
        margin-top: 3rem
        margin-right: 2rem
        margin-left: 2rem
        flex-direction: row
        justify-content: space-between
        font-size: 4rem
        align-items: center
        &__screenname
          margin-left: 8rem
        &__action
          display: flex
          justify-content: space-around
          width: 30rem
          margin-right: 2.99rem
          p
            span
              color: #dd373e
              font-size: 7rem
    .createPlayer
      display: flex
      width: 50%
      height: 12rem
      font-size: 4rem
    .button
      display: flex
      height: 7rem
      width: 16rem
      justify-content: center
      align-items: center
      font-size: 3.575rem
      line-height: 3.7708rem
      background-color: #dd373e
      padding: .235rem .9rem
      margin: 0 1.2rem
      border-radius: 3.5rem
    .bodered
      color: #78a3be
      background-color: transparent
      border: .1rem solid #78a3be
  .mobile_container
    margin-top: 3rem
    display: flex
  .user_container
    width: 56rem
    display: flex
    flex-direction: column
    p
      font-size: 3.5rem
      margin-left: 2rem
    &_screenname
      display: flex
      margin-top: 1rem
      flex-direction: row
      align-content: center
      align-items: center
      p
        font-size: 4.2rem
  .action_container
    display: flex
    justify-items: center
    align-items: center
    margin-top: 2rem
    .action_slider
      &__action
        display: flex
        flex-direction: row
        justify-content: space-around
        margin-top: 1rem
        &_btn
    .container_status
      width: 35rem
      display: flex
      margin-top: 2rem
      justify-items: center
      justify-content: center
      font-size: 4rem
  .avatar_picture
    width: 2.8rem
