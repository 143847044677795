$screen-sm: 576px

.mobile_navpanel
  display: none
.section
  display: flex
  justify-content: flex-end
  align-items: center
  background: #ffffff
  padding: 1rem 1.5789rem
  border-radius: .3125rem
  box-shadow: 0 .1563rem .651rem #c6dceabf
  margin: 2rem 0
  &__title
    font-size: 1.6667rem
    line-height: 1.25rem
    font-weight: 300
    color: #dd373e
    margin: 0
    flex-grow: 1
  .createPlayer
    font-size: 1.0417rem
    line-height: 1.7708rem
    font-weight: 700
    color: #ffffff
    background-color: #78a3be
    padding: .7039rem 3.1413rem
    margin: 0 .5rem
    border-radius: 2rem
    border: none
    outline: none
    cursor: pointer
    &:last-child
      margin-right: 0
  .bordered
    color: #78a3be
    background-color: transparent
    border: thin solid #78a3be
  @import ../TrialActivated.module.sass

@media (max-width: $screen-sm)
  .menuButton
    display: flex
    width: 7rem
    font-size: 3rem
    z-index: 30
  .section
    width: 100%
    height: 12.5rem
    &__title
      font-size: 4rem
    // div
    //   img
    //     position: absolute
    //     top: 19.5rem
    //     right: 0rem
    //     width: 14.5rem
