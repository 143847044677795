@import ../../../styles/Variables.module

.radio_button
    border-radius: 0
    -moz-appearance: none
    -webkit-appearance: none
    appearance: none
    margin: 0
    width: 0
    &:checked+.label
        border: 0.1rem solid $contrast-color
        background-color: $contrast-color
        color: #f4fbff
.label
    margin: 0
    padding: 0.5rem 1.5rem
    font-size: 1rem
    line-height: 2rem
    color: #78a3be
    border: 0.1rem solid #78a3be
    background-color: #fff
    border-radius: inherit
    &:focus
        outline: 0.1rem solid #0c4c81
        outline-offset: -0.3rem
    @media (max-width: $screen-sm)
        padding: 1rem 3rem
        font-size: 3rem
        line-height: 6rem
.selector_item
    margin: 0
    &:first-child
        border-radius: 3rem 0 0 3rem
    &:last-child
        border-radius: 0 3rem 3rem 0
    @media (max-width: $screen-sm)
        &:first-child
            border-radius: 6rem 0 0 6rem
        &:last-child
            border-radius: 0 6rem 6rem 0
