@import ../../../../../styles/Variables.module

@mixin text
    padding: 0 1rem
    box-sizing: border-box
    font-size: 1rem
    line-height: 4rem
@mixin cellWithLogo
    display: flex
    align-items: center
@mixin sm-text
    font-size: 2.5rem
    line-height: 7rem

.team_summary
    display: flex
    flex-direction: row
    gap: 2.5rem
    font-size: 1.3rem
    color: $light-blue-color
    padding: 0 0 1rem 0
    &__goal_not_reached
        padding: 0 0.5rem
        font-weight: 700
        color: $contrast-color
    &__goal_reached
        padding: 0 0.4rem
        font-weight: 700
        color: #2fdcbd
.title
    font-size: 1.778rem
    line-height: 3rem
    color: $blue-color
    font-weight: 700
    font-family: "Lato"
    margin: 1rem 0 1rem
.reached_icon
    width: 1.2rem
    height: auto
    margin: 0 1rem 0.25rem 0.5rem
.manager_player
    margin-top: 1rem
    font-size: 0.8rem
    line-height: 1.2rem
    color: $blue-color
    font-style: italic
    &::before
        content: "* "
        color: $contrast-color
.player_data
    width: 100%
    &__header
        background-color: $background-color
        font-size: 1.1rem
        line-height: 4rem
        color: $blue-color
        font-weight: 700
        border-radius: 0.33rem 0.33rem 0 0
        border: 0.05rem solid #ccdfe9
        &__item
            width: 20%
            padding: 0 1rem
            box-sizing: border-box
            &:first-child
                width: 5%
            &:nth-child(2)
                width: 30%
        &__manager_item
            width: 5%
            padding: 0 1rem
            box-sizing: border-box
            &:nth-child(2)
                width: 70%
    &__main
        width: 100%
        border-radius: 0 0 0.33rem 0.33rem
        border: 0.05rem solid #ccdfe9

@media (max-width: $screen-sm)
    .team_summary
        font-size: 3rem
    .title
        font-size: 5rem
        line-height: 6rem
    .reached_icon
        width: 2.4rem
        height: auto
        margin: 0 0 0.25rem 0.5rem
    .manager_player
        margin-top: 1rem
        font-size: 1.8rem
        line-height: 2.5rem
    .player_data
        width: 100%
        &__header
            font-size: 2.5rem
            line-height: 6rem
            border-radius: 0.66rem 0.66rem 0 0
            &__item
        &__main
            border-radius: 0 0 0.66rem 0.66rem


.row
    border-bottom: 0.05rem solid #ccdfe9
    width: 100%
    box-sizing: border-box
.icon
    width: 1.2rem
    height: auto
.cell
    @include text
    color: $contrast-color
    text-align: center
    &_first
        @include text
        width: 8%
        color: $blue-color
        font-weight: 500
    &_player_name
        @include text
        @include cellWithLogo
        color: $blue-color
        white-space: nowrap
    &_player_manager
        @include text
        @include cellWithLogo
        color: $blue-color
        &::after
            color: $contrast-color
            content: ' *'
    &_logo
        margin-right: 1rem
        border-radius: 50%
        border: 0.1rem solid $border-color
        max-width: 3rem
        max-height: 3rem
        padding: 0.25rem 0.5rem
        overflow: hidden

@media (max-width: $screen-sm)
    .icon
        width: 2rem
        height: auto
    .cell
        @include sm-text
        &_first,
        &_player_name,
        &_player_manager
            @include sm-text

