@import ../../../../styles/Variables.module

@mixin text
  font-size: 1rem
  color: $blue-color

.invite_popup
  position: fixed
  z-index: 40
  background-color: #fff
  border-radius: 0.33rem
  top: 15%
  max-width: 34rem
  left: calc((100% - 34rem) / 2)
  &__title
    font-size: 1.5rem
    line-height: 4rem
    color: $blue-color
    text-align: center
    border-bottom: 0.1rem solid #bfcdd9
  &__textarea
    height: 11rem
    width: 30rem
  &__label
    font-size: 1rem
    line-height: 2rem
    color: $blue-color
    font-weight: 700
    display: flex
    flex-direction: column
    align-items: flex-start
  &__description
    display: flex
    justify-content: center
    align-items: center
    font-size: 1rem
    line-height: 2rem
  .buttons
    display: flex
    justify-content: space-evenly
    align-items: center
    margin-top: 2rem
    button:not(:last-child)
      margin-right: 2rem
  .container
    padding: 2rem
    @media (max-width: $screen-sm)
      padding-bottom: 10rem

@media (max-width: $screen-sm)
  .invite_popup
    border-radius: 0.66rem
    top: 20%
    width: 93rem
    max-width: 93rem
    left: calc((100% - 94rem) / 2)
    &__title
      font-size: 6rem
      line-height: 14rem
    &__label
      font-size: 5rem
      line-height: 9rem
    &__textarea
      padding: 2rem
      font-size: 3rem
      width: 90rem
      height: 45rem
      border-radius: 0.5rem
    &__description
      font-size: 4rem
      line-height: 6rem
    button
      font-size: 5rem
      line-height: 8rem
.form
  display: flex
  flex-direction: column
  align-items: flex-start
  &__label
    display: flex
    flex-direction: column
    @include text
    font-weight: 700
    line-height: 2.5rem
  &__input
    border: 0.1rem solid #b9cfdd
    border-radius: 0.33rem
    width: 22rem
    @include text
    height: 3rem
    padding-left: 0.5rem
    @media (max-width: $screen-sm)
      height: 6rem
  &__checkbox
    margin-right: 1rem
  &__label__checkbox
    font-size: 1rem
    margin-top: 1rem
    margin-left: 0.5rem
    @media (max-width: $screen-sm)
      font-size: 4rem
      margin-top: 3rem
      margin-left: 2rem
    input[type="checkbox"]:checked,
    input[type="checkbox"]:not(:checked)
      position: absolute
      left: -300rem
    input[type="checkbox"]:checked + label,
    input[type="checkbox"]:not(:checked) + label
      display: inline-block
      position: relative
      padding-left: 1.75rem
      line-height: 1.25rem
      cursor: pointer
      @media (max-width: $screen-sm)
        padding-left: 4.6rem
        line-height: 1.1
    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before
      content: ""
      position: absolute
      left: -0.5rem
      top: -0.2rem
      width: 1.625rem
      height: 1.625rem
      border: 0.125rem solid #dddddd
      background-color: #ffffff
      @media (max-width: $screen-sm)
        left: -1.5rem
        top: -0.6rem
        width: 4.875rem
        height: 4.875rem
        border: 0.375rem solid #dddddd
    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before
      border-radius: 0.125rem
    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after
      content: ""
      position: absolute
      -webkit-transition: all 0.2s ease
      -moz-transition: all 0.2s ease
      -o-transition: all 0.2s ease
      transition: all 0.2s ease
    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after
      left: -0.3125rem
      top: 0
      width: 1.35rem
      height: 0.9rem
      border-radius: 0.125rem
      border-left: 0.4rem solid #dd373e
      border-bottom: 0.4rem solid #dd373e
      -ms-transform: rotate(-45deg)
      transform: rotate(-45deg)
      @media (max-width: $screen-sm)
        left: -0.9375rem
        width: 4.05rem
        height: 2.7rem
        border-left: 1.2rem solid #dd373e
        border-bottom: 1.2rem solid #dd373e
    input[type="checkbox"]:not(:checked) + label:after
      opacity: 0
    input[type="checkbox"]:checked + label:before
      opacity: 1




