.main
  display: flex
  flex-direction: column
  align-items: center  
  padding-top: 6rem
  padding-bottom: 25rem
  h2
    text-align: center
    font-size: 1.4583rem
    line-height: 1.7708rem
    font-weight: 400
    color: #003056
    padding-bottom: 0.5rem
  p
    font-size: 0.9583rem
    line-height: 1.7708rem
    font-weight: 400
    color: #003056
    padding-bottom: 2rem

  form
    padding: 2rem
    margin-bottom: 1rem
    background: #ffffff
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    display: flex
    flex-direction: column
    label
      font-size: .9375rem
      font-weight: 400
      margin-bottom: .5rem
    .errorsMessages
      border-color: #dd373e
      box-shadow: 0 0 .3rem #dd373e
    input
      height: 2.3rem
      width: 21.0526rem
      font-size: .9375rem
      line-height: 1.7708rem
      font-weight: 400
      border-radius: .3125rem
      border: .0521rem solid #b9cfdd
      padding-left: 0.2rem
      padding-right: 0.2rem
      margin-bottom: 1.5rem
    span
      font-size: .7292rem
      font-weight: 400
      color: #78a3be
      margin-top: -1rem
      margin-bottom: 1.5rem
    button
      color: #ffffff
      font-size: 1.0417rem
      line-height: 1.7708rem
      font-weight: 700
      background-color: #dd373e
      padding: .7039rem 3.1413rem
      border-radius: 1.5625rem
      border: none
      outline: none
      cursor: pointer
      width: 10rem
      margin: 0 auto