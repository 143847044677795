@import ../../../../styles/Variables.module

.selector_container
    padding: 1.5rem 0 1rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
.roster
    padding: 1rem
    &__title
        font-size: 1.778rem
        line-height: 3rem
        color: $blue-color
        font-weight: 700
        font-family: "Lato"
        margin: 1rem 0 1rem
.title_buttons_container
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap

@media (max-width: $screen-sm)
    .selector_container
        padding: 1rem 0 2rem
    .roster
        padding: 3rem 2rem
        &__title
            font-size: 4.5rem
            line-height: 6rem
            order: 2
    .title_buttons_container
        margin: 2rem 0
