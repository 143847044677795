$screen-sm: 576px
@mixin main-font-lato
  font-family: Lato, serif

.DownloadPage
  background: #ffffff
  padding: 0 2rem 4rem
  .container
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    &_img
      width: 23rem
    .container_wrapper
      position: relative
      &__image
        width: 20rem
      &__title
        position: absolute
        top: 6.7rem
        text-align: center
        width: 23rem
        .h2
          margin-top: .5rem
          margin-right: 2.9rem
          text-align: center
          color: #ffffff
          text-transform: uppercase
          font-weight: 700
          font-size: 3.2rem
          line-height: 3rem
        .p
          color: #FFF000
          margin-top: .8rem
          font-size: 1.7rem
          margin-right: 2.9rem
        .p_promo
          font-weight: 700
          color: #002a4c
          font-size: 1.5rem
          margin-top: 2.5rem
          margin-right: 2.9rem
          span
            font-size: 2.5rem
            line-height: 2rem
            color: #dd373e
            text-align: center
    .container_desc
      width: 40rem
      .wrapper_desc
        display: flex
        flex-direction: column
        align-items: flex-end
        &__h2
          color: #DD373E
          font-family: Lato
          font-weight: 700
          font-size: 2.5rem
          text-transform: uppercase
        &__p
          margin-top: 1rem
          font-size: 1.2rem
          span
            color: #dd373e
            text-decoration: underline
      .container_items
        &__block
          display: flex
          justify-content: flex-end
          align-items: center
          .count
            margin-top: .5rem
            margin-left: .3rem
            background-color: #dd373e
            display: flex
            justify-content: center
            align-items: center
            width: 2rem
            height: 2rem
            color: #ffffff
            border-radius: 50%
          .star
            @extend .count
            font-size: 2.7rem
            color: #dd373e
            background-color: #ffffff
        &__description
          margin-top: 1rem
          text-align: right
          display: flex
          flex-direction: column
          color: #003056
          h2
            font-size: 1.5rem
            font-weight: 700
            span
              color: #dd373e
              text-decoration: underline
          p
            margin-top: .3rem
            font-size: .9rem
          h3
            color: #dd373e
            font-weight: 700
          .desc
            color: #003056
            font-size: 1rem
            font-weight: 700
.line_block
  display: none
.main_menu
  display: none

@media (max-width: $screen-sm)
  .DownloadPage
    padding: 0
    .container
      flex-direction: column
      &_img
        display: none
      .container_wrapper
        margin-bottom: 30rem
        &__image
          width: 100%
          padding: 2rem
        &__title
          top: 40%
          width: 100%
          .h2
            font-size: 15rem
            line-height: 13rem
          .p
            font-size: 7rem
          .p_promo
            margin-top: 13rem
            line-height: 11rem
            font-size: 10rem
            span
              font-size: 10rem
      .container_desc
        width: 100%
        .wrapper_desc
          align-items: flex-start
          padding: 2rem
          &__h2
            font-size: 10rem
          &__p
            font-size: 5.8rem
            margin-bottom: 2rem
        .container_items
          &__block
            justify-content: flex-end
            flex-direction: row-reverse
            align-items: flex-start
            .count
              margin-top: 2.5rem
              width: 8rem
              height: 8rem
              font-size: 4rem
            .star
              font-size: 10rem
          &__description
            width: 90%
            text-align: left
            margin-left: 2rem
            h2
              font-size: 6rem
            p
              font-size: 4.5rem
            h3
              font-size: 5.5rem
            .desc
              font-size: 4.8rem
              font-weight: 700
              text-decoration: underline
  .line_block
    position: relative
    padding-top: 4rem
    padding-bottom: 4rem
    display: flex
    flex-direction: row
    color: #BDC6D7
    .first_line
      left: 1.8rem
      position: absolute
      border-top: .105rem solid #BDC6D7
      width: 32rem
      top: 6.7rem
    .second_line
      border-top: .105rem solid #BDC6D7
      position: absolute
      top: 6.7rem
      left: 65rem
      width: 32rem
    .text_line
      font-family: 'Lato', serif
      position: relative
      font-weight: 700
      left: 37rem
      font-size: 4rem
  .main_menu
    width: 100%
    display: flex
    justify-content: space-between
    margin-bottom: 10rem
    .button
      display: flex
      justify-content: center
      align-items: center
      width: 50%
      height: 13rem
      outline: none
      border-radius: 6rem
      padding: 2.5rem
      border: .1rem solid #dd373e
      margin: .5rem
      font-size: 5rem
      line-height: 1.25rem
      font-weight: 700
      background: #dd373e
      color: #ffffff
    .watch_btn
      @extend .button
      border: .1rem solid #b8c7d1
      background: transparent
      color: #b8c7d1


