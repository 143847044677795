@import ../../../../styles/Variables.module
$screen-sm-min: 577px

@media (max-width: $screen-sm)
  .mobile_container
    position: absolute
    top: 0
    background-color: #ffffff
    opacity: .96
    z-index: 40
    width: 100vw
    height: 100vh
.mobile_links
  display: none
  @media(max-width: $screen-sm)
    display: flex
    padding: 1.5rem 3rem
    font-family: 'Lato'
    margin-top: 20rem
    flex-direction: column
    width: 25rem
    line-height: 8rem
    color: #dd373e
    .mobile_links_list
      display: flex
      flex-direction: column
    .close_image
      position: absolute
      top: 2%
      right: 4.5%
      width: 7rem
      height: 7rem
      padding: 1rem
      background-color: #dd373e
    .li
      margin-top: 5rem
      font-size: 7rem
      line-height: 9rem
      &_action
        position: relative
        margin-top: 5rem
        font-size: 7rem
        display: flex
        align-items: center
    .action_button
      padding-left: 3rem
      width: 7rem
      height: 3rem
    .nested_list
      position: absolute
      top: -1rem
      left: 39rem
      &__item
        font-size: 6rem
        line-height: 8rem
        font-style: italic
        text-align: right
        color: $blue-color
    .link
      width: 50rem
      text-decoration: none
      &:hover,
      &:active
        transform: scale(1.01)
        color: $blue-color
        text-decoration: none
      &:focus
        border-radius: 0.3rem
        outline: 0.2rem solid #0c4c81
        outline-offset: 0.3rem
        text-decoration: none


.home_links
  display: none
  @media (max-width: $screen-sm)
    display: flex
    flex-direction: row
    font-size: 6rem
    margin-top: 50%
    justify-content: center
    .visit_btn
      display: flex
      justify-content: center
      align-items: center
      background-color: #dd373e
      font-weight: 400
      font-size: 4rem
      padding: 2rem 12rem
      border-radius: 50rem
      border: .05rem solid #dd373e
      color: #ffffff
      font-family: 'Lato', serif
      margin-right: 3rem
    .signIn
      @extend .visit_btn
      background-color: transparent
      color: #dd373e
