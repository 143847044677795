$screen-sm: 576px
$font-size-input: .9375rem
.container
  display: none
@media(max-width: $screen-sm)
  .container
    display: block
    h2
      font-size: 8rem
      margin: 2.5rem 0
      text-align: center
      color: #dd373e
    .signInTabs
      display: flex
      justify-content: space-between
      text-align: center
      color: #dd373e
      font-size: 5rem
      width: 75.26rem
      margin: 0 auto
      &_parent
        width: 50%
        padding: 1.5rem
        border-radius: 1rem
      &_player
        padding: 1.5rem
        width: 50%
    .container_main
      font-size: 4rem
      &__parent, &__player
        display: flex
        flex-direction: column
        align-items: center
        .p
          text-align: center
          margin-top: 6rem
          height: 7rem
          padding-top: 1.89rem
          font-size: 4.2rem
          line-height: 1.25rem
          font-weight: 400
          color: #78a3be
          a
            font-weight: bold
            color: #dd373e
        .form
          width: 75.26rem
          height: 75rem
          background: #ffffff
          padding: 2.5rem
          border-radius: 0
          box-shadow: 0 .1563rem .651rem #ffffff
          .linkCont
            justify-content: space-between
          .btnCont
            justify-content: flex-end
          .linkCont, .btnCont
            display: flex
            flex-direction: column-reverse
            justify-content: center
            align-items: center
            .btn
              margin-bottom: 5rem
              width: 100%
              height: 10rem
              margin-top: 2.10rem
              color: #ffffff
              font-size: 4.5rem
              line-height: 1.7708rem
              font-weight: 700
              background-color: #dd373e
              padding: .7039rem 3.1413rem
              border-radius: 4.625rem
              border: none
              outline: none
              cursor: pointer
            .fpLink
              display: flex
              align-items: center
              color: #78a3be
              font-size: 4.25rem
              line-height: 1.7708rem
              font-weight: 700
              &:hover
                text-decoration: underline
        .form
          .div
            display: flex
            flex-direction: column
            .label
              font-size: 4rem
              line-height: 1.7708rem
              font-weight: 700
              margin: 3rem 0
              color: #003056
            .input
              padding-left: 2rem
              height: 10.2rem
              font-size: 4rem
              line-height: normal
              border-radius: 1.125rem
              border: .0521rem solid #b9cfdd

            .input::placeholder
              color: #b9cfdd
              line-height: normal
              padding-left: $font-size-input / 2
            .error
              border-color: #ff0000ee
.label__signin
  margin-top: 1.84rem
  font-weight: 700
  color: #003056

.label_user
  margin-bottom: 2.6rem
  $font-size-input: .9375rem
  font-weight: 700
  color: #003056
.label_password
  margin-top: 2rem
  $font-size-input: .9375rem
  font-weight: 700
  color: #003056
