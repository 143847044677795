@import Variables.module

@mixin red-button
    display: flex
    justify-content: center
    align-items: center
    color: #ffffff
    font-weight: 700
    border-radius: 6rem
    background-color: $contrast-color
    padding: .7039rem 3rem
    border: none
    outline: none
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none
        color: #ffffff
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
        color: #ffffff