$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px
$contrast-color: #dd373e
$blue-color: #003056

.avatar_item
  display: flex
  justify-content: center
  align-items: center
  border-radius: 0.33rem
  background-color: #e3f0f7
  border: 0.1px solid #b9cfdd
  padding: 0.2rem 0.5rem
  &:hover
    -webkit-transform: scale(1.01)
    -ms-transform: scale(1.01)
    transform: scale(1.01)
    box-shadow: 0px 5px 9px #adc8d8
    filter: saturate(150%)
    transition: .2s
    cursor: pointer

@mixin button
  display: flex
  justify-content: center
  align-items: center
  line-height: 2.4rem
  font-size: 1.2rem
  border-radius: 2rem
  padding: 0.7rem 3rem
  margin-left: 1.2rem
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: #0c4c81
    text-decoration: none
  &:focus
    outline: 0.01rem solid #0c4c81
    outline-offset: 0.3rem

@mixin text
  font-size: 2.2rem
  line-height: 2.5rem
  font-weight: 700
  text-align: center

.container
  padding: 2rem 0 3rem
  margin: 0 9.4rem
  .menuButton
    display: none
  .header
    display: flex
    justify-content: space-between
    background: #ffffff
    padding: 1.5rem 1.5789rem
    border-radius: 0.3125rem
    box-shadow: 0 0.1563rem 0.651rem #c6dceabf
    margin-bottom: 2rem
    .title
      text-align: start
      text-transform: capitalize
      font-size: 1.6667rem
      line-height: 1.25rem
      font-weight: 300
      color: $contrast-color
      align-self: center
    .buttons
      display: flex
      &__new_plan
        @include button
        color: #ffffff
        background: $contrast-color
        border: none
      &__video_adjustment
        @include button
        color: #ffffff
        border: none
        background-color: $blue-color
      &__awards
        @include button
        color: #78a3be
        background-color: transparent
        border: thin solid #78a3be
    .button__title
      margin-left: 1rem
    .button__img
      display: block
  .mobile_button
    display: none
  .main
    display: grid
    grid-template-rows: 35rem 0.2rem 28rem
    grid-template-columns: repeat(2, 1fr)
    .shield
      display: none
    .tracker
      display: none
    .shield_desktop
      position: relative
      display: grid
      grid-template-rows: repeat(2, 1fr)
      grid-template-columns: repeat(2, 1fr)
      justify-content: center
      align-items: flex-end
      background: no-repeat center/65% url('golden-shiels.png')
    .shield__goal_videos
      grid-column: 1 / 2
      grid-row: 1
      position: absolute
      top: -2.5rem
      left: 10rem
      display: flex
      flex-direction: column
      justify-content: flex-end
      align-items: flex-end
      height: 20rem
      font-size: 1.5rem
      line-height: 1.5rem
      color: #695b37
      font-weight: bold
      text-align: center
      text-transform: uppercase
    .goal
      font-size: 2.2rem
      line-height: 2rem
      font-weight: 300
      text-transform: uppercase
    .number_video
      font-size: 4rem
      line-height: 7rem
      color: $contrast-color
      font-weight: 800
      text-align: center
    .shield__ball
      grid-column: 2 / 3
      grid-row: 1
      position: absolute
      width: 9rem
      top: 8rem
      left: 1.3rem
    .shield__avatar
      grid-column: 2 / 3
      grid-row: 1
      position: absolute
      width: 4rem
      top: 10rem
      left: 3rem
      filter: saturate(130%)
    .shield__photo
      grid-column: 2 / 3
      grid-row: 1
      position: absolute
      width: 8rem
      max-height: 9rem
      top: 9rem
      left: 1.5rem
      border-radius: 0.33rem
    .shield__detalization
      width: 20rem
      grid-column: 1 / 3
      grid-row: 2
      position: absolute
      top: 1.8rem
      left: 10rem
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      .player_name
        font-size: 2.5rem
        line-height: 3rem
        color: #695b37
        font-weight: bold
        text-transform: uppercase
        text-align: center
      .text
        font-size: 1.8rem
        color: #695b37
        font-weight: 300
        text-align: center
      .watched_number_video
        font-size: 3rem
        line-height: 3.2rem
        color: #dd373e
        font-weight: bold
        text-align: center
    .tracker__title
      @include text
      color: $contrast-color
    .trackers_container
      display: flex
      justify-content: space-between
      align-items: center
      width: 90%
      height: 90%
      background: no-repeat url('confetti.png') top 85% right 40%
      .progress_tracker
        width: 18rem
        height: 18rem
        .video_tracker
          display: flex
          flex-direction: column
          justify-content: center
          width: 14.4rem
          height: 14.4rem
          border-radius: 50%
          color: $contrast-color
          background: no-repeat #f4fbff url('background.png') top -10% right 12%
        .day_tracker
          display: flex
          flex-direction: column
          justify-content: center
          width: 14.4rem
          height: 14.4rem
          border-radius: 50%
          color: $blue-color
          background: no-repeat #f4fbff url('background.png') top 0% right 32%
        .tracker_title
          text-align: center
          font-size: 3.5rem
          line-height: 4rem
          font-weight: 700
          color: inherit
        .tracker_description
          font-size: 1.5rem
          line-height: 2.2rem
          text-align: center
          font-weight: normal
          color: inherit

    .hr
      grid-column: 1 / 3
      grid-row: 2
      width: 100%
      border-top: 1px solid #bfcdd9
  .footer
    grid-column: 1 / 3
    grid-row: 3
    display: flex
    .logo
      margin: 3rem 2rem
      display: flex
      flex-grow: 1
      justify-content: center
      align-items: center
      background: no-repeat #f4fbff url('background.png') bottom 35% right 25%
      border: 0.4rem solid #a8192f
      .logo__img
        width: 17rem
        height: 20rem
        padding: 2rem
        margin: 0 1rem
    .get_reward
      margin: 3rem 2rem
      display: flex
      flex-grow: 1
      flex-direction: column
      justify-content: center
      align-items: center
      width: 22rem
      border: 0.4rem solid #0e3f67
      .first_p
        @include text
        color: $blue-color
      .second_p
        @include text
        color: $contrast-color
        margin-bottom: 1rem
      .button__reward
        @include button
        color: #ffffff
        background: #0e3f67
        margin-right: 1.2rem
        border: none
        &_disabled
          @include button
          color: #ffffff
          margin-right: 1.2rem
          border: none
          background: #bfcdd9
      .button__title
        margin-left: 1rem

.bg__button_active
  width: 29px
  height: 29px
  background: no-repeat url('css_sprites.png') -10px -10px

.bg__button_disabled
  width: 29px
  height: 29px
  background: no-repeat url('css_sprites.png') -59px -10px

.bg__awards
  width: 29px
  height: 29px
  background: no-repeat url('css_sprites.png') -10px -59px

@media(max-width: $screen-lg)
  .container
    .header
      .title
        font-size: 2rem
      .buttons
        &__new_plan,
        &__video_adjustment,
        &__awards
          font-size: 1.5rem

@media (max-width: $screen-md)
  .container
    .header
      .buttons
        &__new_plan,
        &__video_adjustment,
        &__awards
          border-radius: 3rem
          font-size: 1.8rem
      .button__img
        display: none
      .button__title
        margin-left: 0
  .bg__awards,
  .bg__button_disabled,
  .bg__button_active
    display: none

@media (max-width: $screen-sm)
  .container
    margin: 0 4rem
    .mobile_button
      margin-top: 3rem
      display: flex
      justify-content: center
      font-size: 4rem
    .mobile_button__name
      display: flex
      justify-content: center
    .mobile_button__badge,
    .mobile_button__tracker
      -moz-appearance: none
      -webkit-appearance: none
      appearance: none
      &+label
        font-size: 3.5rem
        line-height: 8rem
        width: 45rem
        color: #78a3be
        border: 0.33rem solid #dd373e
        border-color: #78a3be
        background-color: #fff
      &:checked+label
        border: 0.33rem solid #dd373e
        background-color: #dd373e
        color: #f4fbff
        border: none
    .mobile_button__badge+label
      border-radius: 4rem 0 0 4rem
      border-right: none
      margin-right: -2rem
    .mobile_button__tracker+label
      border-radius: 0 4rem 4rem 0
      border-left: none
      margin-left: -2rem
    .menuButton
      display: flex
      width: 7rem
      font-size: 3rem
      z-index: 30
    .header
      flex-wrap: wrap
      .title
        font-size: 4rem
      .buttons
        display: none
    .main
      display: flex
      flex-direction: column
      .shield_desktop
        display: none
      .tracker_desktop
        display: none
      .shield
        position: relative
        display: grid
        grid-template-rows: repeat(2, 1fr)
        grid-template-columns: repeat(2, 1fr)
        justify-content: center
        align-items: flex-end
        background: no-repeat center/65% url('golden-shiels.png')
        height: 50rem
        width: 65%
        margin: 2rem auto
      .shield__goal_videos
        grid-column: 1 / 2
        grid-row: 1
        position: absolute
        display: flex
        flex-direction: column
        justify-content: flex-end
        align-items: flex-end
        height: 20rem
        color: #695b37
        font-weight: bold
        text-align: center
        text-transform: uppercase
        top: 7rem
        left: 13.5rem
        font-size: 3rem
        line-height: 5rem
      .goal
        font-size: 3.5rem
        line-height: 4.5rem
      .number_video
        font-size: 6.5rem
        line-height: 8rem
      .shield__avatar
        width: 7rem
        top: 12rem
        left: 4rem
      .shield__photo
        width: 12rem
        max-height: 13rem
        top: 12rem
        left: 2rem
      .shield__ball
        width: 11rem
        top: 15rem
        left: 2rem
      .shield__detalization
        width: 40rem
        top: 1.8rem
        left: 10rem
        .player_name
          font-size: 3rem
          line-height: 5rem
        .text
          font-size: 3.5rem
        .watched_number_video
          font-size: 4rem
          line-height: 5rem
      .tracker
        display: flex
        flex-direction: column
        margin: 2rem auto
        width: 90%
      .tracker__title
        font-size: 5rem
        line-height: 6rem
      .trackers_container
        flex-direction: column
        margin: 2rem auto
        .progress_tracker
          width: 40rem
          height: 40rem
          margin-bottom: 3rem
          .video_tracker
            width: 35rem
            height: 35rem
          .day_tracker
            width: 35rem
            height: 35rem
          .tracker_title
            text-align: center
            font-size: 6rem
            line-height: 6rem
            font-weight: 700
            color: inherit
          .tracker_description
            font-size: 2.5rem
            line-height: 3rem
            text-align: center
            font-weight: normal
            color: inherit
      .hr
        display: none
    .footer
      flex-direction: column
      .logo
        width: 70rem
        height: 50rem
        margin: 2rem auto
        border: 1rem solid #a8192f
        .logo__img
          width: 30rem
          height: 35rem
          padding: 2rem
          margin: 0 1rem
      .get_reward
        margin: 2rem auto
        width: 70rem
        height: 50rem
        border: 1rem solid #0e3f67
        .first_p,
        .second_p
          font-size: 4rem
          line-height: 5rem
        .button__reward
          font-size: 3.5rem
          margin-top: 3rem
          line-height: 5rem
          border: none
          border-radius: 4rem
          &_disabled
            margin-top: 3rem
            border-radius: 4rem
            font-size: 3.5rem
            line-height: 5rem
            border: none
            background: #bfcdd9
        .button__title
          margin: 1rem 3rem
