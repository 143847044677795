.img_back
  padding-top: 1.8rem
  position: absolute
  right: 0
  top: 3.75rem
  width: 100%
  z-index: 0

.main_title
  width: 100%
  height: 30.25rem
  background-size: cover
  padding: 5rem 13rem
  display: flex
  flex-direction: column
  justify-content: center
  .h2
    font-size: 3.3333rem
    line-height: 1.25rem
    font-weight: 700
    color: #ffffff
    margin-bottom: 2.5rem
  .p
    font-size: 1.1458rem
    line-height: 1.875rem
    font-weight: 300
    color: #ffff1e
    width: 79.5%
.main_container::before
  content: ''
  position: absolute
  width: 100%
  height: 30.25rem
  padding: 5rem 13rem
  background-color: rgba(0,0,0,0.3)
  z-index: 1

.main_container__block
  position: absolute
  z-index: 2
  width: 42.75rem
  height: 20.375rem
  margin-right: 55rem
  padding: 1.5rem 1.5rem 1rem
  box-shadow: 0 0 3.188rem rgba(0, 0, 0, 0.75)
  display: flex
  flex-direction: column
  justify-content: space-around
  align-content: center
  background-color: #f4fbff
  border-radius: 2%

  h2
    font-size: 2.5rem
    color: #003056
  span
    color: #dd373e
    font-size: 1rem
  .info_block p
    display: flex
    flex-direction: row
    padding-top: .5rem
  .span_block_phone
    color: #78a3be
    padding-right: .7rem
  .span_block_email
    color: #78a3be
    padding-right: 1.2rem
    &__a
      height: 1rem
      color: #dd373e

.header_block
  color: #003056
  font-size: 1.375rem
  font-weight: 300
  line-height: 1.625rem
  height: 4.5rem
  width: 18.063rem






