@import '../../../../styles/Variables.module'

.title
    font-size: 1.77rem
    line-height: 3rem
    color: $blue-color
    font-weight: 700
    text-align: center
.card_container
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
.info
    margin-top: 1rem
    font-size: 0.8rem
    line-height: 1.2rem
    color: $blue-color
    font-style: italic
    margin-right: 5rem
    &::before
        content: "* "
        color: $contrast-color

@media (max-width: $screen-sm)
    .title
        display: none
    .info
        font-size: 2.8rem
        line-height: 3rem
        width: 100%


