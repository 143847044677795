.main
  display: flex
  padding: 4rem 8rem
  section
    width: 100%
    padding: .5rem
    h1
      font-size: 2.5rem
      font-weight: 700
      line-height: 2.7083rem
      margin: .5rem 0
      color: #000
    h2
      font-size: 1.4583rem
      font-weight: 400
      margin: 3rem 0 1.5rem
    h5
      font-size: .9375rem
      font-weight: 700
      line-height: 1.4583rem
      margin: 1.5rem 0 .5rem
    h6
      display: block
      font-size: .9375rem
      font-weight: 400
      line-height: 1.4583rem
      color: #949494
      margin: .5rem 0 1.5rem
    p,ul,li
      font-size: .9375rem
      font-weight: 400
      line-height: 1.4583rem
      margin: .5rem 0
    ul
      padding-inline-start: 2.0833rem
    li
      display: list-item
    a
      color: #0000ee
      &:hover
        text-decoration: underline
  aside
    width: 40%
    padding: .5rem
    div
      background: #ffffff
      padding: 1.5rem
      border-radius: .3125rem
      box-shadow: 0 .1563rem .651rem #c6dceabf
      h4
        margin: .5rem 0
      a
        color: #0088cc
        margin: .5rem 0
        &:hover
          text-decoration: underline