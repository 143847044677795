@import ../../../../styles/Variables.module
@import '../../../../styles/Button.module'
@mixin text
    font-size: 1.125rem
    line-height: 2rem

@mixin sm-text
    font-size: 4rem
    line-height: 8rem

@mixin input-sm-size
    width: 100%
    height: 12rem
    border-radius: 1rem

@mixin button-sm-size
    border-radius: 6rem
    width: 43rem
    height: 11.5rem

.upgrade_team
    color: $blue-color
    font-family: "Lato"
    padding: 2rem 1.667rem
    display: flex
    flex-direction: column
    align-items: center
    &__title
        font-size: 2rem
        color: $blue-color
        font-weight: 700
        padding-bottom: 1.5rem
        text-align: center
    @media (max-width: $screen-sm)
        background: rgb(244, 251, 255)
        padding: 0
        &__title
            font-size: 5rem
            line-height: 6.5rem
            font-weight: normal
            padding-bottom: 3rem

.container
    display: grid
    grid-template-columns: 1fr 20rem
    grid-template-rows: 1fr 1fr
    grid-template-areas: 'pay counter' 'pay details'
    gap: 1rem
    margin-bottom: 2rem
    @media (max-width: $screen-sm)
        grid-template-columns: 1fr
        grid-template-rows: auto
        grid-template-areas: 'counter' 'pay' 'details'
        gap: 3rem
        margin-bottom: 0

    &>article,
    &>form
        border: 0.1rem solid $border-color
        border-radius: 0.33rem
        padding: 1.8rem 1rem 2rem
        @media (max-width: $screen-sm)
            padding: 3rem 2rem 2rem
            background-color: #fff
            border: none
            border-radius: 0.66rem
            box-shadow: 0 0.1563rem 0.651rem #c6dceabf
    .payment
        grid-area: pay
        &__title
            font-size: 1.5rem
            color: $blue-color
            font-weight: 700
            font-family: "Lato"
            @media (max-width: $screen-sm)
                font-size: 4.5rem
    .info
        display: flex
        gap: 0.5rem
        align-items: center
        flex-wrap: wrap
        &__description
            font-size: 1rem
            color: $blue-color
        &__pics
            width: 13.88rem
            height: auto
        @media (max-width: $screen-sm)
            &__description
                font-size: 3.5rem
                line-height: 7rem
            &__pics
                width: 55rem

    .count_members
        grid-area: counter
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        &__title
            font-size: 1.22rem
            color: $blue-color
            font-weight: 700
            padding-bottom: 1rem
        &__info
            padding-top: 1rem
            font-size: 1rem
            line-height: 1.5rem
            color: $blue-color
            font-style: italic
        &__link
            color: $contrast-color
            text-decoration: underline
            padding-left: 0.3rem
        @media (max-width: $screen-sm)
            padding: 2rem
            &__title
                font-size: 4rem
                line-height: 8rem
            &__info
                padding-top: 1rem
                font-size: 3.3rem
                line-height: 5rem
            &__link
                font-size: 3.3rem
                line-height: 5rem
    .selectors
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        &::after
            content: ''
            padding-top: 1rem
            width: 15rem
            border-bottom: 0.1rem solid $border-color
            @media (max-width: $screen-sm)
                width: 87rem
        &>*
            padding-bottom: 0.25rem
    .details
        grid-area: details
        display: flex
        flex-direction: column
        align-items: flex-start
        &__title
            color: $blue-color
            padding-bottom: 1rem
        @media (max-width: $screen-sm)
            padding: 4rem 2rem
            &__title
                font-size: 4.5rem
                line-height: 8rem
                align-self: center
        
    .promo
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        li 
            display: flex
            align-items: center
        li:first-child span
            color: $contrast-color
            font-weight: 700
.important
    color: $contrast-color
    font-weight: 700
.card_details_form
    display: flex
    flex-wrap: wrap
    margin-top: 1rem
    justify-content: space-between
    @media (max-width: $screen-sm)
        width: 100%
.card_label
    display: flex
    flex-direction: column
    align-items: flex-start
    @include text
    font-weight: 600
    @media (max-width: $screen-sm)
        @include sm-text
        width: 48%
        margin-top: 3rem

.card_label:first-child
    position: relative
    &::after
        content: ''
        position: absolute
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAXCAMAAAC27AbQAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAAuc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/dnaJ+SwAAADV0Uk5TAIL/hIeeOCcsOZyTN5SDR6+2vS0vMLqamZaVqY2Iiae+vLkICbcEBbtoU1Zlw3AqLnLCtbRnWWtlAAAAhklEQVR4nOXSRxaCAAxF0ee3gF3BLij2Lrb9r02YQ3DuG99BkhOgJKsySRVVa46bnVNvqAkttbHqqEtPJgH18eS7g/zcoUaMzYnTJj+ZaaGZMVcQLvILAy2JCvdasdbGJFvt/tpE2pvmkNzQK7zzkZPOJrnoCjfd/fiRXew/9Url2/yMD3wBaw8V0JYtoWgAAAAASUVORK5CYII=')
        background-size: 90% 90%
        top: 3.3rem
        left: 28rem
        background-repeat: no-repeat
        width: 2rem
        height: 1.5rem
    @media (max-width: $screen-sm)
        display: flex
        justify-content: flex-start
        margin-left: 0
        width: 100%
        &::after
            top: 12rem
            left: 78rem
            width: 7rem
            height: 5.5rem

.card_number_input
    width: 31rem
    height: 3.0rem
    border-radius: .33rem
    border: .0521rem solid #b9cfdd
    padding: 1rem 1rem 2rem 0.4rem
    margin: .5rem 0
    @media (max-width: $screen-sm)
        padding: 3.5rem
        @include input-sm-size
        margin-bottom: 1.5rem
.card_expiry_input
    width: 19rem
    height: 3.0rem
    border-radius: .33rem
    border: .0521rem solid #b9cfdd
    padding: 1rem 1rem 2rem 0.4rem
    margin: .5rem 0
    @media (max-width: $screen-sm)
        @include input-sm-size
        margin-right: 4rem
        padding: 3.5rem
.card_cvc_input
    height: 3.0rem
    border-radius: .33rem
    border: .0521rem solid #b9cfdd
    padding: 1rem 1rem 2rem 0.4rem
    margin: .5rem 0
    width: 10rem
    @media (max-width: $screen-sm)
        @include input-sm-size
        padding: 3.5rem
        margin-left: 0
.container_coupon
    display: flex
    justify-content: flex-start
    align-items: flex-end
    @media (max-width: $screen-sm)
        width: 100%
        margin-top: 3rem
.discount_label
    display: flex
    flex-direction: column
    justify-content: flex-start
    margin: 0
    @include text
    font-weight: 600
    padding-top: 1rem
    @media (max-width: $screen-sm)
        display: flex
        width: 50%
        @include sm-text
    input[type="text"]
        margin-right: 1.5rem
        width: 19rem
        background-color: #ffffff
        padding: 1rem 1rem
        @extend .card_expiry_input
        @media (max-width: $screen-sm)
            margin-right: 4rem
            width: 95%
            font-size: 3.3rem
            padding: 3.5rem
.divTotal
    position: relative
    display: flex
    padding: 0.4rem
    background-color: #f0f8fd
    margin: 1rem 0 2rem
    width: 100%
    border: 0.1rem solid #b9cfdd
    border-radius: 0.33rem
    @media (max-width: $screen-sm)
        width: 100%
        padding: 0
        border-radius: 1rem
        margin-top: 6rem
.totalPrice
    display: flex
    width: 98%
    padding: 0.5rem 1rem
    font-weight: 700
    justify-content: space-between
    @media (max-width: $screen-sm)
        font-size: 4rem
        @include input-sm-size
        align-items: center
        padding: 0 3rem
.subPrice
    @extend .totalPrice

.subPrice_firstspan
    width: 10rem
    @media (max-width: $screen-sm)
        width: auto

.totalPrice_hr
    width: 15rem
    position: absolute
    height: 1px
    top: -.1rem
    background-color: red
    @media (max-width: $screen-sm)
        top: 1rem
.grey_button
    @include button
    display: flex
    justify-content: center
    align-items: center
    width: 10rem
    height: 100%
    color: #ffffff
    font-weight: 700
    line-height: 1.8889rem
    background-color: $light-blue-color
    border: none
    outline: none
    margin-bottom: 0.5rem
    @media (max-width: $screen-sm)
        @include button-sm-size
        @include sm-text

.buttonDefault
    @include button
    display: flex
    justify-content: center
    align-items: center
    width: 12rem
    height: 100%
    color: #ffffff
    font-weight: 700
    background-color: #dd373e
    border: none
    border-radius: 3.7625rem
    @media (max-width: $screen-sm)
        @include sm-text
        @include button-sm-size
        margin: 4rem auto
