
$screen-sm: 576px

.btn
  width: 314px
  height: 82px
  border-radius: 41px
  background-color: #dd373e
  border: none
  outline: none
  cursor: pointer
  font-size: 29px
  line-height: 32px
  color: #ffffff
  font-weight: 700
  font-family: "Lato"
  display: flex
  justify-content: center
  align-items: center
  text-decoration: none
  gap: 14px
  transition: all 400ms ease
  &:hover
    text-decoration: none
    color: #fff
    gap: 20px

.contest
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  &__header
    display: flex
    position: relative
    justify-content: center
    align-items: flex-start
    width: 100%
    border: 4px solid #afc4dd
    background-image: url('header.png')
    background-repeat: no-repeat
    background-size: cover
    background-position: center
  &__body
    margin-top: 230px
    padding-bottom: 3rem
    width: 100%
    max-width: 1032px
  &__footer
    width: 100%
    max-width: 1032px
    border-radius: 6px
    background-color: #ffffff
    border: 2px solid #dd373e
    display: flex
    align-items: center
    padding: 20px
    margin-bottom: 50px
    justify-content: space-between
    &_text
      font-size: 32px
      line-height: 34px
      color: #dd373e
      font-weight: 700
      font-family: "Lato"
  &__team
    display: flex
    justify-content: flex-start
    align-items: center
    width: 100%
    gap: 2rem
    max-width: 1032px
    margin-top: 2rem
    margin-bottom: 6rem
    &_logo
      width: 206px
      height: 206px
      border-radius: 103px
      background-color: #ffffff
      overflow: hidden
      padding: 2rem
      flex-shrink: 0
    &_name
      font-size: 72px
      line-height: 100px
      color: #003056
      font-weight: 700
    &_sub
      font-size: 28px
      line-height: 28px
      color: #dd373e
      font-weight: 700
  &__card
    width: 100%
    border-radius: 6px
    filter: drop-shadow(0px 3px 12.5px rgba(198,220,234,0.75))
    background-color: #ffffff
    &_top
      width: 100%
      max-width: 1032px
      position: absolute
      bottom: -200px
    &_content
.topPerformers
  display: flex
  flex-direction: column
  gap: 1rem
  width: 100%
  padding: 1rem
  .topPlayer
    font-size: 32px
    text-transform: uppercase
    color: #dd373e
    font-weight: 700
    text-align: center
    &_avatar
      width: 133px
      height: 133px
      border-radius: 67px
      background-color: #ffffff
      border: 1px solid #b9cfdd
    &_name
      font-size: 16px
      line-height: 24px
      text-transform: uppercase
      color: #003056
      font-weight: 700
      font-family: "Lato"
      text-align: center
    &_value
      font-size: 22px
      line-height: 34px
      color: #dd373e
      font-weight: 700
      font-family: "Lato"
      text-align: center
.content
  display: flex
  &_header
    display: flex
    padding-top: 20px
    align-items: center
    justify-content: center
    gap: 15px
    width: 100%
    &_text
      font-size: 32px
      line-height: 34px
      color: #dd373e
      font-weight: 700
      font-family: "Lato"
      text-transform: capitalize
    
  &_one
    flex: .8
    display: flex
    flex-direction: column
    padding: 20px
    gap: 10px
    &_sub1
      font-size: 36px
      line-height: 38px
      text-transform: uppercase
      color: #dd373e
      font-weight: 700
      font-family: "Lato"
      text-align: center
    &_sub2
      font-size: 48px
      line-height: 50px
      text-transform: uppercase
      color: #dd373e
      font-weight: 900
      font-family: "Lato"
      text-align: center
    &_image
      margin-top: 20px
      width: 401px
      height: 417px
      border-radius: 6px
      background-image: url('congrats.png')
      background-size: cover
      background-position: center
  &_two
    flex: 1.2
    display: flex
    flex-direction: column
    padding: 1.5rem
    &_image
      width: 154px
      height: 206px
      background-image: url('trophy.png')
      background-size: cover
      background-position: center
      flex-shrink: 0
    &_sub1
      font-size: 42px
      line-height: 44px
      color: #003056
      font-weight: 700
      font-family: "Lato"
    &_sub2
      margin-top: 10px
      font-size: 34px
      line-height: 48px
      color: #dd373e
      font-weight: 700
      font-family: "Lato"
      display: flex
      align-items: center
      gap: 10px
    &_sub3
      font-size: 34px
      line-height: 48px
      color: #dd373e
      font-weight: 700
      font-family: "Lato"
      display: flex
      align-items: center
      white-space: nowrap
      gap: 10px
    &_sub4
      font-size: 24px
      line-height: 34px
      color: #003056
      font-weight: 700
      font-family: "Lato"
      text-align: center
  &_earned
    width: 100%
    height: 230px
    border-radius: 6px
    background-color: #f4fbff
    border: 1px solid #ccdfe9
    display: flex
    justify-content: space-around
    flex-direction: row
    align-items: center
    &_name
      font-size: 16px
      line-height: 24px
      text-transform: capitalize
      color: #003056
      font-weight: 700
      font-family: "Lato"
      text-align: center
    &_value
      font-size: 38px
      line-height: 34px
      color: #dd373e
      font-weight: 700
      font-family: "Lato"
      text-align: center
.img
  height: 111px
  width: 92px
  object-fit: cover

.stick
  width: 73px
  height: 2px
  background-color: #c0d6e2

@media (max-width: $screen-sm)
  .img
    height: 70px
    width: 60px
  .btn
    font-size: 4.25rem
    gap: 5px
    height: 50px
    padding: 10px
    white-space: nowrap
  .stick
    width: 30px
  .contest
    padding: 1rem 2.5rem
    &__header
      display: flex
      position: relative
      background: none
      border: none
    &__body
      margin-top: 305px
    &__footer
      flex-direction: column
      &_text
        font-size: 20px
    &__card
      position: relative
      width: 100%
      &_top
        position: absolute
        left: 0px
        bottom: -270px
        width: 100%
    &__team
      display: flex
      justify-content: flex-start
      align-items: center
      width: 100%
      gap: 2rem
      padding: 2rem
      &_logo
        width: 100px
        height: 100px
        border-radius: 103px
        background-color: #ffffff
        overflow: hidden
        padding: 2rem
        flex-shrink: 0
      &_name
        font-size: 52px
        line-height: 64px
        color: #003056
        font-weight: 700
      &_sub
        font-size: 20px
        line-height: 20px
        color: #dd373e
        font-weight: 700
  .content
    display: flex
    flex-direction: column
    &_one
      &_sub1
        font-size: 30px
      &_sub2
        font-size: 40px
      &_image
        margin-top: 20px
        width: 95%
        height: 417px
        border-radius: 6px
        background-image: url('goal.png')
        background-size: cover
        background-position: center
    &_two
      flex: 1.2
      display: flex
      flex-direction: column
      padding: 1.5rem
      &_image
        width: 154px
        height: 206px
        background-image: url('trophy.png')
        background-size: cover
        background-position: center
        flex-shrink: 0
      &_sub1
        font-size: 30px
      &_sub2
        font-size: 24px
      &_sub3
        font-size: 24px