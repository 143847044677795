$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px
$contrast-color: #dd373e
$red-color: #e1343f
$blue-color: #003056
$hover-blue-color: #0c4c81
$light-blue-color: #78a3be
$border-color: #bdc6d7
$background-color: #f4fbff
$box-shadow-color: #c6dcea
