@import '../../../styles/Variables.module'

@mixin red-button
  display: flex
  justify-content: center
  align-items: center
  line-height: 3rem
  color: #ffffff
  font-weight: 700
  border-radius: 6rem
  background-color: $contrast-color
  border: none
  outline: none
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: #0c4c81
    text-decoration: none
    color: #ffffff
  &:focus
    outline: 0.01rem solid #0c4c81
    outline-offset: 0.3rem
    color: #ffffff

.upgrade_popupWrapper
  position: fixed
  z-index: 40
  top: 0
  left: 0
  width: 100%
  height: 120%
  color: red
  opacity: .9
  background-color: $blue-color
.upgrade_popup
  display: flex
  flex-direction: column
  position: fixed
  z-index: 40
  top: calc((100% - 70%) / 2)
  left: 15%
  width: 70%
  border-radius: 6px
  background-color: #FFF
.closeImg
  position: absolute
  top: -3.8rem
  right: -2.2rem
  color: #FFF
  font-size: 3.5rem
  font-weight: 300
  cursor: pointer
.container
  display: flex
  align-items: center
  height: 100%
.suggestions_list
  display: flex
  flex-direction: column
  justify-content: space-evenly
  align-items: center
  height: 100%
  background-color: #eff6fa
  border-radius: 1rem 0 0 1rem
  padding: 2rem 0
  flex-basis: 50%
  &__item
    border: 0.15rem solid $blue-color
    border-radius: 0.33rem
    width: 25rem
    background-color: #fff
    margin: 0.5rem
    &__title
      font-size: 2.0rem
      line-height: 5rem
      color: $blue-color
      font-weight: 700
      text-align: center
      border-bottom: 0.1rem solid #bfcdd9
    &__text
      font-size: 1.5rem
      line-height: 3rem
      color: $blue-color
      font-weight: 300
      text-align: center
    &__price
      font-size: 3rem
      line-height: 4rem
      color: $contrast-color
      font-weight: 400
      padding-bottom: 1.2rem
      text-align: center
    .button
      @include red-button
      width: 20rem
      margin: -0.3rem auto 1.5rem
      padding: 0.5rem
      font-size: 1.35rem
  &__item__link
    display: none
  .arrow
    padding-left: 1rem
    width: 2.5rem
  .year
    font-size: 1.2rem
    line-height: 1rem
    color: $contrast-color
    font-weight: 400
.container__details
  margin-left: 2rem
  align-self: center
.item
  display: flex
  justify-content: flex-start
  align-items: center
  font-size: 1.3rem
  line-height: 2.0rem
  color: $blue-color
  font-weight: 400
  padding: 0.6rem
  text-align: start
.item__image
  margin-right: 1rem
  width: 2rem
  .check
    text-align: start
.header
  font-size: 1.8rem
  line-height: 3.5rem
  color: $contrast-color
  font-weight: 700
  text-align: start
  padding-left: 0.7rem
.mobileImg
  width: 50%
.buttonWrapper
  height: 100%
  display: flex
  justify-content: center
  align-items: flex-end
  .buttonDefault
    @include red-button
    width: 17rem
    height: 4.5rem
    font-size: 1.4rem
    margin-top: 4rem

@media (max-width: $screen-lg)
  .upgrade_popup
    width: 80%
    left: 10%

@media (max-width: $screen-md)
  .upgrade_popup
    width: 90%
    left: 5%
  .suggestions_list
    &__item
      border: 0.2rem solid $blue-color
      border-radius: 0.5rem
      width: 30rem
      margin: 0.75rem
      &__title
        font-size: 2.5rem
        line-height: 6rem
        border-bottom: 0.2rem solid #bfcdd9
      &__text
        font-size: 1.8rem
        line-height: 4rem
      &__price
        font-size: 3.5rem
        line-height: 4.5rem
        padding-bottom: 1.5rem
      .button
        width: 20rem
        margin: -0.5rem auto 2rem
        padding: 1rem 1.5rem
        font-size: 1.7rem
    .arrow
      padding-left: 1.5rem
      width: 3.5rem
    .year
      font-size: 1.5rem
  .header
    font-size: 2.2rem
    line-height: 4rem
  .item
    font-size: 1.8rem
    line-height: 3rem
  .buttonWrapper
    .buttonDefault
      font-size: 2rem
      height: 5.5rem

@media (max-width: $screen-sm)
  .upgrade_popup
    top: 5%
  .container
    flex-direction: column
  .container__list
    padding: 0
  .closeImg
    font-size: 8rem
    right: 0.6rem
    color: $blue-color
  .suggestions_list
    padding-top: 8rem
    width: 100%
    border-radius: 1rem 1rem 0 0
    &__item
      border: none
      box-shadow: 0px 3px 4.5px rgba(198,220,234,0.75)
      border-radius: 0.33rem
      width: 65rem
      background-color: #fff
      margin: 0.8rem
      &__title
        font-size: 5rem
        line-height: 10rem
      &__text
        font-size: 3.5rem
        line-height: 7rem
      &__price
        font-size: 7.5rem
        line-height: 10rem
      .button
        width: 22rem
        margin: -0.5rem auto 2rem
        padding: 1rem 1.5rem
        font-size: 2.2rem
    &__item:nth-child(2)
      display: none
    &__item__link
      display: block
      margin: 2rem 0
      font-size: 3.5rem
      line-height: 6.5rem
      text-decoration: underline
      color: #dd373e
      font-weight: 400
    &__item__link > a
      text-decoration: underline
      &:hover,
      &:active
        font-weight: 600
        transform: scale(1.05)
        color: #0c4c81
      &:focus
        outline: 0.2rem solid #0c4c81
        outline-offset: 0.3rem
        color: #0c4c81
    .year
      font-size: 3rem
  .header
    margin: 4rem 0 3rem
    font-size: 4rem
    line-height: 6rem
  .item
    font-size: 3.7rem
    line-height: 6rem
  .item__image
    width: 5rem
    margin-right: 2rem
  .buttonWrapper
    .buttonDefault
      margin: 7rem 0
      font-size: 4rem
      width: 35rem
      height: 12rem
