@import ../../../styles/Variables.module

@mixin button
  display: flex
  justify-content: center
  align-items: center
  line-height: 2.4rem
  font-size: 1.2rem
  border-radius: 2rem
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: #0c4c81
    text-decoration: none
    color: #fff
  &:focus
    outline: 0.01rem solid #0c4c81
    outline-offset: 0.3rem


.pin
    height: 28px
    width: 28px

.button__reward
  @include button
  color: #ffffff
  padding: 0.7rem 1rem
  background: #0e3f67
  opacity: .7
  border: none
  &_disabled
    @include button
    color: #ffffff
    margin-right: 1.2rem
    border: none
    background: #bfcdd9
.button__title
  margin-left: 1rem
  white-space: nowrap
  
$screen-sm: 576px
.container
  padding: 2rem 0
  .grayBtn
    font-size: 1.0417rem
    line-height: 1.7708rem
    font-weight: 700
    border-radius: 2rem
    cursor: pointer
    color: #78a3be
    border: thin solid #78a3be
    background-color: transparent
    transition: .2s linear
  .reset
    text-decoration: none
    padding: .7039rem 3.1413rem .7039rem 3.1413rem
    outline: none
    &:hover
      background-color: #f1f0f0
  .link
    align-self: flex-end
    margin-right: 11rem
    padding: .7039rem 3.1413rem .7039rem 5.7413rem
    background: url(./../../../asset/videoSeries/download.svg) 15% 50%/ 10% 60% no-repeat
    &:hover
      background-color: #f1f0f0
  .subtitle
    margin: .4rem 0 .5rem 9.4rem
    font-size: 1.25rem
    line-height: 2.1875rem
    font-weight: 700
    color: #003056
  .section
    background: #ffffff
    padding: 1.5rem 1.5789rem
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    margin: 1rem 9.4rem
    &__flexContainer
      display: flex
      justify-content: flex-end
      &_training
        width: 89%
        align-items: center
        display: flex
        justify-content: space-between
      .title
        text-align: center
        margin-left: 16rem
        flex-grow: 1
        font-size: 1.6667rem
        line-height: 1.25rem
        font-weight: 300
        color: #dd373e
        align-self: center

.tbody__row
  padding: 3rem 0
  display: flex
  justify-content: space-between
  align-items: center
  height: 3rem
  border-bottom: thin double #c6dceabf
  .videoNumber
    color: #003056
    .circle
      width: 1.2rem
      height: 1.2rem
      margin-right: 1.7rem
    .circle_true
      width: 1.2rem
      height: 1.2rem
      margin-right: 1.7rem
      background: red
  .button
    font-size: .9375rem
    line-height: 1.7708rem
    color: #ffffff
    background: #dd373e url(./../../../asset/childDashboard/caret-right-solid.svg) 27% 50%/ 10% 60% no-repeat
    padding: .235rem 1.5rem .235rem 2.5rem
    margin: 0 .5rem
    border-radius: 2rem
    border: none
    outline: none
    cursor: pointer
@media (max-width: $screen-sm)
  .pin
    height: 7rem
    width: 7rem
  .container
    .grayBtn
      position: absolute
      font-size: 3.5rem
      top: 34rem
      line-height: 3.5rem
    .section
      font-size: 3.5rem
      margin: 3rem
      &__flexContainer
        height: 10rem
        &_training
          justify-content: space-between
          margin-top: 45px
          .subtitle
            line-height: 5rem
            font-size: 4.25rem
          .btn_workout
            display: none
        .title
          position: static
          display: flex
          margin-left: 0
          justify-content: center
          font-size: 5rem

  .tbody__row
    height: 20rem
    .videoNumber
      max-width: 70rem
      margin-right: 1rem
      .circle
        width: 4rem
        height: 4rem
    .button
      font-size: 4rem
      width: 19rem
      background: #dd373e url(./../../../asset/childDashboard/caret-right-solid.svg) 15% 50%/ 10% 60% no-repeat
      height: 8rem
      border-radius: 4rem

  
  .button__reward
    font-size: 3.3rem
    line-height: 7rem

//This is modal Video Vimeo class for central position of videoPlayer and size of it
.modal_vimeo
  padding: 0rem 0rem
  margin: 0rem 0rem
  height: 0
  border: none
  @media (max-width: $screen-sm)
    padding: 0rem 0rem
    margin: 0rem 0rem
    position: relative
    top: 42rem
    right: 0rem

@media (min-width: 800px)
  .modal_vimeo
    padding: 0rem 0rem
    margin: 0rem -20rem
  .pin
    height: 28px
    width: 28px

  .button__reward
    font-size: 1rem
    line-height: 2rem
    padding: .5rem 1rem
