$screen-sm: 576px
.createPlayer
  margin: 0
  display: flex
  justify-content: space-between
  align-items: center
  color: #ffffff
  padding: 4rem 5rem 3.5rem 5rem
  border-radius: 0.3125rem
  box-shadow: 0 0 0.7813rem #003056 insert
  background-color: #003056
.container
  display: flex
  justify-content: space-between
  align-items: center
.createPlayer__logo
  display: flex
  justify-content: center
  width: 13.5rem
  height: 13.5rem
  margin: 0 2rem 0 3.5rem
  padding: 1rem 3rem
  background-color: #003056
  border-radius: 50%
  box-shadow: 0 0 5rem rgba(0, 17, 31,0.78)
.createPlayer__logoImg
  margin: auto
  width: 8.5rem
  height: 10rem
.textWrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  margin-left: 4.5rem
  margin-right: 5.5rem
.createPlayer__logoText
  font-size: 1.325rem
  line-height: 1.325rem
  font-weight: 900
  font-style: italic
  margin-bottom: 1.2rem
.createPlayer__info
  font-size: 1.75rem
  line-height: 1.75rem
  font-weight: 300
  margin-bottom: 1.575rem
.createPlayer__description
  font-size: 1.02rem
  line-height: 1.25rem
  font-weight: 500
  margin-bottom: 1.15rem
.createPlayer__button
  display: flex
  justify-content: center
  align-items: center
  font-weight: 400
  background-color: #dd373e
  padding: 0.7rem 3rem
  margin: 1.5rem 0
  border-radius: 2rem
  border: none
  outline: none
  cursor: pointer
.createPlayer__title
  position: absolute
  clip: rect(1px, 1px, 1px, 1px)
  padding: 0
  border: 0
  height: 1px
  width: 1px
  overflow: hidden
.createPlayer__img
  height: 0.8rem
  width: 0.8rem
.createPlayer__link
  padding-left: 0.5rem
  font-size: 1.05rem
  line-height: 1.7708rem
  font-weight: 400
  color: #ffffff
  &:hover
    color: #ffffff
  &:active
    color: #ffffff
@media (max-width: $screen-sm)
  .createPlayer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: #ffffff
    padding: 0
    border-radius: 1.5rem
    font-size: 7.5rem
    line-height: 7.5rem
    box-shadow: 0 0 2rem #003056 insert
  .container
    margin: 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    font-size: 7.5rem
    line-height: 7.5rem
  .createPlayer__logo
    width: 45rem
    height: 45rem
    margin: 10rem auto
    .createPlayer__logoImg
      width: 28rem
      height: 33rem
  .textWrapper
    align-items: center
    text-align: center
  .createPlayer__logoText
    font-size: 5.25rem
    line-height: 5rem
    margin: 5rem auto 5rem
  .createPlayer__info
    margin: 0 auto 5rem
    font-size: 7rem
    line-height: 7.5rem
  .createPlayer__description
    font-size: 4.25rem
    line-height: 5.25rem
    margin-bottom: 5rem
  .createPlayer__button
    padding: 5rem 15rem
    margin: 1.5rem 0 12rem
    border-radius: 8rem
  .createPlayer__img
    height: 4rem
    width: 4rem
  .createPlayer__link
    padding-left: 3rem
    font-size: 5.5rem
    line-height: 5.5rem
    font-weight: 400
    color: #ffffff
