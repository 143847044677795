@import ../../../styles/Variables.module

@mixin text
    font-family: "Lato"
    font-size: 1.2rem
    line-height: 3rem
.reports
    margin: 0 10rem
    padding-top: 2rem
.header_description
    display: flex
    justify-content: flex-start
    align-items: center
    border-radius: 0.33rem
    width: 80rem
    border: 0.1rem solid $border-color
    padding: 0.5rem 3rem
    margin-top: 1.5rem
    &__item
        @include text
        color: $red-color
        padding: 0 2rem
        &:first-child
            display: flex
            color: $blue-color
            position: relative
            font-weight: 700
            &::before
                content: ''
                background: no-repeat url('../flag.png') 50% / 90%
                position: absolute
                left: -1rem
                top: 0.2rem
                width: 2rem
                height: 2.5rem
        &_link
            font-weight: 700
            text-decoration: underline
            position: relative
            padding-left: 2rem
            &::before
                content: ''
                background: no-repeat url('../../../asset/childDashboard/medal_2.webp') 50% / 90%
                position: absolute
                left: -1rem
                top: 0.2rem
                width: 2rem
                height: 2.5rem
    .important
        font-weight: 700
        padding-left: 0.5rem
    .select
        padding: 0 2rem 4rem
        width: 12rem
        margin-left: 2rem
        border-radius: 0.33rem
        background-color: #ffffff
        border: 0.1rem solid #b9cfdd
        padding: 0.5rem 1rem
        color: #b9cfdd
        -moz-appearance: none
        -webkit-appearance: none
        appearance: none
        background-repeat: no-repeat
        background-position: 90% 50%
        background-size: 1.5rem
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAMAAAACJixMAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEhQTFRFuc/duc/duc/dAAAAuc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/dAv+DlAAAABh0Uk5TS9qgAJrbUv+vqOGw5iDs8SUf8CSVkM7Q/L8KcgAAAGlJREFUeJxlzFkOgCAMRdFqVVRUQBz2v1PBUkY+SHgnXICux+YM4wRCiLneFzfC6i7Z7hvsR/3H70oDmkr8fmoEJJHFbtFD8Yc6SJBJ6DDEGncikFw3dxKQxE4GQUInh1+4UwCa57Xp9QEbrgfddyKvhQAAAABJRU5ErkJggg==')
        &:hover
            box-shadow: 0 0.1rem 0.5rem rgba(103, 180, 243, 0.5)
        &:focus
            outline: 0.01rem solid #0c4c81
            outline-offset: 0.3rem
        &__item
            font-size: 1.2rem
            line-height: 3rem
            color: $light-blue-color
            font-weight: 400
.wrapper
    display: flex
.reports_statistics__header
    margin-top: 1rem
    display: flex
    align-items: center
    justify-content: center
    @include text
    font-weight: 700
    color: $blue-color
    &__item
        display: flex
        justify-content: center
        width: 20rem
        &:first-child
            padding-left: 2rem
.decorative_img
    width: 12rem
    height: 49rem
    margin: 4rem 2rem 0

@media (max-width: $screen-sm)
    .reports
        margin: 0 auto
    .header_wrapper
        width: 90rem
        margin: 2rem auto
        background-color: #fff
        border-radius: 1rem
        box-shadow: 0px 3px 12.5px rgba(198,220,234,0.75)
    .decorative_img
        display: none
    .header_description
        flex-wrap: wrap
        justify-content: center
        padding: 0
        border: none
        border-width: 0.3rem
        width: 90rem
        &__item
            font-size: 3.5rem
            line-height: 6rem
            height: 15rem
            display: flex
            flex-direction: column
            justify-content: center
            text-align: center
            flex-wrap: wrap
            width: 28rem
            &:first-child
                margin: 0
                width: 90rem
                flex-direction: row
                justify-content: space-between
                align-items: center
                border-top: 1px solid $border-color
                border-bottom: 1px solid $border-color
                padding-left: 13rem
                &::before
                    left: 4rem
                    top: 4rem
                    width: 6rem
                    height: 6.5rem
                    background-size: 90% 90%
            &_link
                padding-top: 5rem
                &::before
                    left: 9rem
                    top: -1rem
                    width: 6rem
                    height: 6.5rem
        .select
            border-radius: 0.5rem
            padding: 0 2rem 4rem
            width: 23rem
            margin: 1rem 4rem
            padding: 3rem
            background-size: 3rem
    .text
        width: 10rem
        line-height: 4rem
    .wrapper
        justify-content: center
    .reports_statistics__header
        justify-content: space-evenly
        &__item
            display: flex
            flex-wrap: wrap
            width: 28rem
            font-size: 3.5rem
            line-height: 4rem
            padding: 2rem 0 1rem
            text-align: center
            &:first-child
                margin-left: -2.5rem
