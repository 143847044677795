@import ../../../../../../styles/Variables.module

@mixin text
    padding: 0 1rem
    width: 100%
    box-sizing: border-box
    font-size: 1rem
    line-height: 4rem

@mixin sm-text
    font-size: 2.8rem
    line-height: 7rem

.row
    border-bottom: 0.05rem solid #ccdfe9
    width: 100%
    box-sizing: border-box
.row_container
    display: flex
    flex-direction: row
    gap: 1rem
    align-items: center
    justify-content: space-between
.cell
    @include text
    color: $contrast-color
    &_first
        @include text
        color: $blue-color
        font-weight: 500
    &_player_name
        @include text
        color: $blue-color

@media (max-width: $screen-sm)
    .cell
        @include sm-text
        &_first
            @include sm-text
        &_player_name
            @include sm-text
