.container
  display: flex
  padding: 5rem 0rem 6rem 0rem
  justify-content: center

.container_main
  display: flex
  width: 40rem
  height: 40.625rem
  padding: 2rem 3rem 2rem 3rem
  justify-content: center
  color: #000000
  border-radius: 2%
  background-color: #ffffff
  box-shadow: 2px 2px 2px 2px rgba(0.15,0.15,0.15,0.15)
  &__desc
    text-align: center
    color: #dd373e
  &__subtitle
    padding-top: 1.15rem
    padding-bottom: 1.68rem
    width: 21.438rem
    height: 2.5rem
    margin: 0 auto
    text-align: center
    color: #003056
    font-family: Lato, serif
    font-size: 1.125rem
    font-weight: 400
    line-height: 1.5rem

  &__form
    padding-top: 2rem
    display: flex
    justify-content: space-between
    flex-wrap: wrap

.select_col
  display: flex
  flex-direction: column
  width: 16.25rem
  height: 3.15rem
  padding: .8512rem 1rem
  margin-top: .5rem
  font-size: 1.125rem
  line-height: 1.7708rem
  font-weight: 400
  color: #78a3be
  border-radius: .3125rem
  border: thin solid #b9cfdd

.form_inputs select
  display: flex
  flex-direction: column
  width: 16.25rem
  height: 3.15rem
  padding: .8512rem .8rem
  font-size: 1.2rem
  line-height: 1
  border-radius: .3125rem
  border: thin solid #b9cfdd
  background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
  background-size: 1.3rem
  -webkit-appearance: none
  background-position-x: 13.8rem

.email_form
  padding-top: 1rem
  display: flex
  flex-direction: column
  justify-content: center
  width: 35rem

.container_main__forminputs
  display: flex
  flex-direction: column
  width: 15rem
  &__label
    color: #003056

input[name="firstname"],input[name="lastname"]
  display: flex
  width: 16.25rem
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 400
  color: #000000
  border-radius: .3125rem
  border: thin solid #b9cfdd
  padding: .6512rem 1rem

input[name="child"], input[name="childbirth"]
  display: flex
  width: 16.25rem
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 400
  color: #000000
  border-radius: .3125rem
  border: thin solid #b9cfdd
  padding: .6512rem 1rem
  margin: .5rem .10rem 1rem 0
.form_labels
    color: #003056
    font-family: Lato
    font-size: 1.125rem
    font-weight: 700
    line-height: 2.125rem
    margin-bottom: 0

.send_button
  outline: none
  margin-left: 19rem
  width: 15rem
  height: 3rem
  color: #ffffff
  background-color: #dd373e
  border-radius: 3rem
  border: none
select option
  outline: none
.modal
  margin: 30vmin auto
  .buttonStrap
    padding: .5rem 3rem

.container_main__roleinput
  display: flex
  flex-direction: column
  width: 100%
  select
    flex-direction: column
    justify-content: center
    width: 16.25rem
    margin-bottom: .6rem
    height: 3.15rem
    padding: .7512rem .8rem
    font-size: 1.125rem
    line-height: 1.7708rem
    font-weight: 400
    color: #78a3be
    border-radius: .3125rem
    border: thin solid #b9cfdd
    background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
    background-size: 1.3rem
    -webkit-appearance: none
    background-position-x: 13.8rem
