$screen-sm: 576px
.container
  padding: 2rem 0
  .link
    align-self: flex-end
    margin-right: 11rem
    padding: .7039rem 3.1413rem .7039rem 5.7413rem
    background: url(./../../../asset/videoSeries/download.svg) 15% 50%/ 10% 60% no-repeat
    &:hover
      background-color: #f1f0f0
  .section
    background: #ffffff
    padding: 1.5rem 1.5789rem
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    margin: 1rem 9.4rem
    &__flexContainer
      display: flex
      justify-content: flex-start
      .title
        text-align: center
        flex-grow: 1
        font-size: 1.6667rem
        line-height: 1.25rem
        font-weight: 300
        color: #dd373e
        align-self: center
  .searchForm
    margin: 2rem 9.4rem
    align-items: center
    display: flex
    justify-content: space-between
    &__label
      position: absolute
      width: 1px
      height: 1px
      margin: -1px
      border: 0
      padding: 0
      white-space: nowrap
      clip-path: inset(100%)
      clip: rect(0 0 0 0)
      overflow: hidden
    &__input
      margin-right: 1rem
      padding: 0.5rem 1rem
      height: 60px
      flex-grow: 1
      border-radius: 0.2rem
      border: 1px solid #b9cfdd
      &::placeholder
        color: #b4c3ccbf
      &:focus
        box-shadow: 0 0.1563rem 2rem rgba(103, 180, 243, 0.5)
    .searchForm__button_reset
      padding: 0
      box-sizing: border-box
      position: relative
      left: -3rem
      border: none
      background-color: inherit
      .button__img_reset
        width: 1rem
        height: 1rem
      &:hover,
      &:active
        transform: scale(1.01)
        text-decoration: none
      &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
  .searchForm__button_reset__hidden
      padding: 0
      box-sizing: border-box
      position: relative
      left: -3rem
      border: none
      background-color: inherit
      opacity: 0
  .searchForm__button_submit
    display: flex
    justify-content: flex-start
    align-items: center
    font-size: 1.2rem
    line-height: 2.4rem
    color: #ffffff
    background: #dd373e
    padding: 0.235rem 2rem 0.235rem
    border-radius: 2rem
    border: none
    &:hover,
    &:active
      transform: scale(1.01)
      background-color: #0c4c81
      text-decoration: none
    &:focus
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
    .button__img
      margin-right: 1rem
.select
  display: flex
  justify-content: flex-end
  border: 0.1rem solid #b9cfdd
  margin: 0 9.4rem
  &:focus
    outline: 0.01rem solid #0c4c81
    outline-offset: 0.3rem
  option:disabled
    color: #b4c3ccbf
.section_result
  display: flex
  justify-content: center

@media (max-width: $screen-sm)
  .container
    .section
      font-size: 3.5rem
      margin: 3rem
      &__flexContainer
        height: 10rem
        &_searchForm
          justify-content: space-between
          .subtitle
            line-height: 5rem
            font-size: 4.25rem
          .btn_workout
            display: none
        .title
          position: static
          display: flex
          margin-left: 0
          justify-content: center
          font-size: 5rem
    .searchForm
      flex-direction: column
      margin: 5rem 3rem 8.2rem
      &__input
        width: 100%
        height: 10rem
        font-size: 3.5rem
        padding-right: 8rem
      .searchForm__button_submit
        justify-content: space-around
        font-size: 3.5rem
        width: 28rem
        line-height: 8rem
        height: 9rem
      .searchForm__button_reset
        top: -7.5rem
        left: 42rem
        .button__img_reset
          width: 3.5rem
          height: 3.5rem
    .select
      font-size: 3.5rem
      margin-left: 3rem

//This is modal Video Vimeo class for central position of videoPlayer and size of it
  @media (max-width: $screen-sm)
    padding: 0rem 0rem
    margin: 0rem 0rem
    position: relative
    top: 42rem
    right: 0rem

@media (min-width: 800px)
  .modal_vimeo
    padding: 0rem 0rem
    margin: 0rem -20rem
