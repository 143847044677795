@import '../../../../styles/Variables.module'

.placingText
    display: flex
    flex-direction: column
    justify-content: center
.placingTextTitle
    margin-top: 2rem
    color: $blue-color
    font-size: 0.889rem
    font-style: italic
    line-height: 1.333rem
    @media (max-width: $screen-sm)
        font-size: 3.5rem
        line-height: 5rem
        text-align: center
        padding: 2rem 4rem
.linkTerms
    color: #dd373e
    text-decoration: underline
    padding-left: 0.2rem
    @media (max-width: $screen-sm)
        color: $blue-color

.secure_info
    width: 30rem
    font-size: 0.889rem
    line-height: 2.2rem
    font-family: "Lato"
    color: $contrast-color
    position: relative
    margin-top: 1rem
    padding-left: 3.2rem
    border-radius: 0.33rem
    background-color: #e7eff5
    &::before
        content: ''
        position: absolute
        left: 1.5rem
        top: 0.35rem
        background-repeat: no-repeat
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAMAAABcgh8DAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAALRQTFRFAAAA3Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc9e39gDgAAADx0Uk5TAFi+8fLAXJj/nWWtTUupbwT0o5v5NRQM/Tkp/DErdE9DSvZ5LM3OxMPQubJ/dQfv++YJ3/7twtFU5+hZcSZkWQAAAIpJREFUeJxNzdcOwjAQRNHBGBh66CX0mtAJCfX//ws7Rmjn5epIaxkwy6iszuXxW4Hpik4lslyp1si6lddg07bFtmfSIbuWvT4HJkP67pHmCBhP6E9nZvMFlyusKbbBVjKAShu6I+W4A/aCB+AoqE84CxK4CF6j6OYY2MRJEtveEcp/H3i+9H/vzxdWPgxXTI1KUgAAAABJRU5ErkJggg==')
        background-position: 90% 90%
        background-size: 90% 90%
        width: 1rem
        height: 1.2rem
    @media (max-width: $screen-sm)
        font-size: 3.5rem
        line-height: 6rem
        width: 100%
        padding-left: 8rem
        border-radius: 1rem
        &::before
            left: 1.5rem
            top: 3rem
            width: 4rem
            height: 4.5rem