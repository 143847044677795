.mainDiv
  margin: 0 auto
  display: flex
  justify-content: center

.subDiv
  display: flex
  flex-direction: column
  img
    margin-top: 3rem
    width: 20rem
    align-self: center
  h4
    align-self: center
    color: #113356
    font-size: 3rem
    font-weight: 400
    line-height: normal
    margin-top: 57px
    margin-bottom: 1rem
  h5
    color: #1f4071
    align-self: center
    font-size: 1.5rem
  p
    margin-top: 25px
    color: #78a3be
    font-size: 24px
    font-weight: 300
    line-height: 52px
.buttonDefault
    color: #ffffff
    width: 30rem
    align-self: center
    text-align: center
    font-size: 2.0417rem
    line-height: normal
    font-weight: 700
    background-color: #dd373e
    border-radius: 3.8625rem
    border: none
    margin: 56px 0 154px 0px
    padding: 1.3rem



