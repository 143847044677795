$screen-sm: 576px
.footer
  display: flex
  flex-direction: column
  color: #ffffff
  .section
    background: #003056
    padding: 1.8rem
    display: flex
    .logo
      display: flex
      align-items: center
      margin-bottom: .7rem
      .img
        width: 1.84rem
      .h2
        font-size: 2.1875rem
        line-height: 1.25rem
        font-weight: 700
        margin-left: .5rem
    .p
      font-size: .7292rem
      color: #78a3be
      line-height: 1.7708rem
      font-weight: 400
      width: 61.5%
      margin-bottom: 2.5rem
    .social
      color: #78a3be
      font-size: 1.25rem
      margin-right: 1rem
    .nav
      width: 50%
      display: flex
      justify-content: space-around
      .h4
        font-size: 1.25rem
        line-height: 1.25rem
        font-weight: 700
        text-transform: uppercase
        margin-bottom: 2rem
      .li
        display: block
        color: #78a3be
        font-size: .9375rem
        line-height: 1.9792rem
        font-weight: 700
        text-transform: capitalize
        a
          text-decoration: none
          &:hover
            text-decoration: underline
  .copyright
    background: #002a4c
    color: #78a3be
    padding: 1.14rem
    .h5
      font-size: .8333rem
      line-height: 1.9792rem
      font-weight: 700
      text-align: center
@media (max-width: $screen-sm)
  .footer
    .section
      flex-wrap: wrap
      article
        display: flex
        flex-direction: column
        align-items: center
      .logo
        margin: 0 auto
        .img
          width: 11rem
          display: flex
          justify-content: flex-end
      .h2
        font-size: 5rem !important
        line-height: 2.25rem
        font-weight: 400 !important
        margin-left: 2.5rem !important
    .p
      width: 98% !important
      text-align: left
      margin-left: 2rem
      display: flex
      justify-content: center
      font-size: 3.5rem !important
      line-height: 5.5rem !important
      margin-top: 1rem
      margin-bottom: .5rem !important
    .ul_links
      width: 100%
      margin-top: 6rem
      display: flex
      justify-content: space-between
    .social
      font-size: 8.25rem !important

    .nav
      flex-direction: column
      text-align: left
      margin-left: 2rem
      .h4
        font-size: 5.25rem !important
        line-height: 6.25rem !important
        font-weight: 400 !important
        margin: 6rem 0rem
      .li
        display: flex
        flex-direction: column
        color: #78a3be
        font-size: 4rem !important
        line-height: 10rem !important
        font-weight: 700
        text-transform: capitalize
        a
          text-decoration: none
          &:hover
            text-decoration: underline
  .copyright
    background: #002a4c
    color: #78a3be
    padding: 8rem 0rem !important
    .h5
      font-size: 4rem !important
      line-height: 1.9792rem
      font-weight: 700
      text-align: center
