
@import '../../../styles/Variables.module'
@import '../../../styles/VisuallyHidden.module'

@mixin mobile-menu-item
    font-size: 3rem
    line-height: 4rem
    font-weight: 700

.main_screen
    font-family: "Lato"
    padding: 3rem 0
    margin-bottom: 2rem
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    color: $blue-color
    &__header
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        align-self: center
    &__title
        font-size: 1.77rem
        line-height: 3rem
        color: $contrast-color
        font-weight: 700
        text-align: center
    &__promo
        font-size: 1.22rem
        margin-top: 1rem
        font-weight: 400
        text-align: center
.selectors,
.buttons
    display: flex
    margin: 2rem 0

.container
  margin: 0rem

.search
    display: flex
    justify-content: center
    align-items: flex-start
    gap: 2rem
    align-self: center
    margin: 2rem 0
    font-family: "Lato"
    &__input
        border: 0.1rem solid #b9cfdd
        border-radius: 0.33rem
        width: 30rem
        height: 3rem
        font-size: 1rem
        color: $blue-color
        padding-left: 0.5rem
    &__info
        font-size: 0.9rem
        line-height: 1.5rem
        color: $contrast-color
.search_result
    &__title
        font-size: 1.78rem
        line-height: 2.5rem
        color: $blue-color
        font-weight: 700

@media (max-width: $screen-sm)
    .main_screen
        padding: 5rem 0
        margin-bottom: 2rem
        &__title
            font-size: 6rem
            line-height: 8rem
        &__promo
            font-size: 3rem
            line-height: 4rem
            margin-top: 2rem
            padding: 0 6rem
    .buttons
        justify-content: center
    .mobile_selector
        display: flex
        margin: 3rem 0 2rem
        width: 97%
        height: 8rem
        padding-left: 2.5rem
        justify-content: space-between
        align-items: center
        @include mobile-menu-item
        color: $blue-color
        border: 0.01rem solid #b9cfdd
        border-radius: 0.66rem
        background-color: #ffffff
        &__image
            width: 5rem
            height: auto
            padding-right: 1.5rem
    .mobile_menu_container
        position: relative
        width: 97%
    .mobile_menu
        position: absolute
        border: 0.01rem solid #b9cfdd
        display: flex
        flex-direction: column
        align-items: flex-start
        background-color: #ffffff
        border-radius: 0 0 0.66rem 0.66rem
        width: 100%
        top: -2.2rem
    .search
        gap: 4rem
        align-self: center
        margin: 2rem 0
        &__input
            border-width: 0.2rem
            border-radius: 1rem
            width: 70rem
            height: 8rem
            font-size: 2.5rem
        &__info
            font-size: 3rem
            line-height: 4.5rem
    .search_result
        &__title
            display: none
