$screen-sm: 576px
$screen-md: 768px
$screen-lg: 992px

.listGridViewButton
  margin-top: 5px
  padding-bottom: 5px
  text-align: center
  margin-inline: auto
  font-weight: bold
  font-size: 2rem
  border-bottom: solid thin
  color: lightgrey
  width: 75%

.header
  display: flex
  justify-content: space-between
  align-items: flex-end
  border-bottom: 1px solid #bdc6d7
  margin: 0 10rem
  padding: 2rem 0 1rem

.container_title
  display: flex
  justify-content: center
  color: #003056
  font-family: Lato, serif
  font-size: 2rem
  font-weight: 400
  line-height: 2.625rem
  padding-top: 1.5rem
.header__statistics
  display: flex
  justify-content: flex-end
  &_video_goal
    position: relative
    margin-left: 4rem
    font-size: 1.2rem
    line-height: 2rem
    color: #e1343f
    font-weight: 400
    &::before
      content: ''
      background: no-repeat url('./flag.png') 50% / 90%
      position: absolute
      left: -2.5rem
      top: -0.25rem
      width: 2rem
      height: 2.5rem
  &_weak_streak
    position: relative
    margin-left: 5rem
    font-size: 1.2rem
    line-height: 2rem
    color: #e1343f
    font-weight: 400
    &::before
      content: ''
      background: no-repeat url('./calendar.png') 50% / 90%
      position: absolute
      left: -3rem
      top: -0.25rem
      width: 2.5rem
      height: 2.5rem

.buttons
  display: flex
  justify-content: flex-start
  align-items: center
  margin-left: 15rem
  .button__item_red
    display: flex
    justify-content: flex-start
    align-items: center
    width: 10.2rem
    height: 2.5rem
    border-radius: 0.375rem
    background-color: #e1343f
    margin-right: 1rem
    transition: .2s linear
    &:hover,
    &:active
      transform: scale(1.01)
      background-color: #f71c2b
      text-decoration: none
    &:focus
      outline: 0.4rem solid #0c4c81
      outline-offset: 0.3rem
  .button__item_search
    display: flex
    justify-content: flex-start
    align-items: center
    width: 10.2rem
    height: 2.5rem
    border-radius: 0.375rem
    background-color: #003056
    margin-right: 1rem
    transition: .2s linear
    &:hover,
    &:active
      transform: scale(1.01)
      background-color: #0c4c81
      text-decoration: none
    &:focus
      outline: 0.4rem solid #0c4c81
      outline-offset: 0.3rem
  .button__img
    width: 1.5rem
    height: 1.5rem
    margin-left: 0.9rem
  .button__title
    font-size: 0.8rem
    line-height: 2rem
    padding-left: 0.6rem
    color: #ffffff
    font-weight: 700
  .button__link
    &:hover,
    &:active
      cursor: pointer
      text-decoration-color: #fff

@media(max-width: $screen-sm)
  .container_title
    display: flex
    justify-content: center
    font-size: 4rem
    line-height: 5.5rem
    padding-top: 3.5rem
    font-weight: bold
    text-align: center
  .header
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    margin: 0 3rem
  .grid_cards
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 1rem
    margin-top: 1.73rem
    justify-content: center
    text-align: center
    color: black
  .header__statistics
    display: flex
    justify-content: space-between
    margin-top: 4rem
    margin-bottom: 2rem
    width: 90rem
    &_video_goal
      font-size: 3.5rem
      line-height: 4.5rem
      &::before
        left: -5rem
        width: 4rem
        height: 4.5rem
        background-size: 90% 90%
    &_weak_streak
      font-size: 3.5rem
      line-height: 4.5rem
      &::before
        left: -5rem
        width: 4rem
        height: 4.5rem
        background-size: 90% 90%
