@import ../../../styles/Variables.module

.container
    padding: 2rem 0 3rem
    margin: 0 9.4rem
    .header
        display: flex
        justify-content: space-between
        background: #ffffff
        padding: 1.5rem 1.5789rem
        border-radius: 0.3125rem
        box-shadow: 0 0.1563rem 0.651rem #c6dceabf
        margin-bottom: 2rem
    .title
        text-align: start
        text-transform: capitalize
        font-size: 1.6667rem
        line-height: 1.25rem
        font-weight: 300
        color: $contrast-color
        align-self: center

@media(max-width: $screen-lg)
    .container
        .header
        .title
            font-size: 2rem

@media (max-width: $screen-sm)
    .container
        margin: 0 4rem
        .header
            flex-wrap: wrap
        .title
            font-size: 4rem
