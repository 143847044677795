@import ../../../../styles/Variables.module

@mixin text
    font-size: 1.125rem
    line-height: 1.5rem
    font-weight: 700
    font-family: "Lato"

.radio_button_label
    display: flex
    justify-content: center
    align-items: center
    margin-right: 2rem
    cursor: pointer

.radio_button
    appearance: none
.button_picture
    width: auto
    height: 1.75rem
    margin-right: 0.5rem
.button_text
    @include text
    border-bottom: 0.1rem solid $contrast-color
    color: $contrast-color
.active_button
    @include text
    color: $blue-color

@media (max-width: $screen-sm)
    .radio_button_label
        flex-direction: column
        gap: 1rem
        margin: 0 3rem
        cursor: pointer
    .button_picture
        height: 4rem
        margin-right: 0
        margin-bottom: 1rem
    .button_text,
    .active_button
        font-size: 3rem
        height: 3rem
