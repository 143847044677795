@import '../../../styles/RedButton.module'
@import '../../../styles/Select.module'

@mixin sm-text
  font-size: 4.2rem
  line-height: 6rem

.container
  display: flex
  flex-direction: column

.modal
  margin: 15vmin auto !important
  max-width: 35% !important
  .header
    font-size: 1.5rem
    color: $blue-color

  h4
    font-size: 2rem
    line-height: 1.7708rem
    font-weight: 400
    color: $blue-color

  form
    h5
      font-size: 1.25rem
      line-height: 1.7708rem
      font-weight: 500
      color: $blue-color

  .acceptBtn
    width: 9rem
    height: 3rem
    border-radius: 2rem

  // .cancelBtn
  //   width: 9rem
  //   height: 3rem
  //   border-radius: 2rem

  .formStyle
    span
      font-size: 8.25rem

  .errStep2
    color: red

.child_payment_row
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 1rem

.child__name
  display: flex
  flex-wrap: wrap
  width: 8rem
  font-size: 1rem
  line-height: 1.889rem
  color: $blue-color

.button
  @include red-button
  font-size: 1.11rem
  line-height: 1.889rem
  align-self: end
  margin-top: 2rem
  margin-right: -2rem
  height: 3.33rem
  .arrow
    padding-left: 1rem
    width: 2.5rem

  // STYLES FOR RADIOBUTTON
.radioBtnContainer
  display: block
  position: relative
  cursor: pointer
  font-size: 1.2rem
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  input
    position: absolute
    opacity: 0
    cursor: pointer
    background-color: #fff
    appearance: none

  .checkmark
    position: absolute
    top: -1rem
    left: -1rem
    width: 2rem
    height: 2rem
    background-color: #fff
    border: 0.1rem solid #b9cfdd
    border-radius: 0.33rem

.radioBtnContainer
  &:hover input ~ .checkmark,
  &:focus input ~ .checkmark
    border-color: $hover-blue-color

  input:checked ~ .checkmark
    background-color: #fff
    background-repeat: no-repeat
    background-position: 50% 50%
    background-size: 70% 50%
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAMAAAAVBLyFAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAHJQTFRFAAAA3Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc9gIkGaQAAACZ0Uk5TAJ1vpP9oo6b5OxSnMQXG8CWo+DDc7yD7reofqfc3my+uub3AnkDsjkfDAAAAcUlEQVR4nF2N2Q6DMAwEt9RcbUo5ypFCoRf//4vYoMhK5sWr0WoN+JyiQOBMFPsmIcYzKYss53Bx5irGALfiXh6mYlM3HB5Ebec6vRgMnOwTGPlORr/Y1yw7i1t908HU6O/PbrQjfIOO8KN+QcB/1bwB7aQEkFYjD9sAAAAASUVORK5CYII=')

.otherTextField
  min-height: 5rem
  width: 100%

input[type=checkbox]
  transform: scale(2)
  -ms-transform: scale(2)
  -webkit-transform: scale(2)

.select
  @include select
  display: flex
  justify-content: center
  background-color: #fff
  border: 0.1rem solid #b9cfdd
  border-radius: 0.33rem
  font-size: 1rem
  line-height: 2rem
  color: #78a3be
  font-weight: 400
  font-family: "Lato"
  width: 12rem
  height: 3.33rem
  padding-left: 0.5rem
  background-size: 1.5rem
  &:focus
    outline: 0.01rem solid #0c4c81
    outline-offset: 0.3rem
  option:disabled
    color: #b4c3ccbf

@media (max-width: $screen-sm)
  .container
  .child_payment_row
    margin-bottom: 3rem
  .child__name
    inline-size: 32rem
    word-break: break-all
    @include sm-text
  .button
    @include sm-text
    margin: 7rem 0
    height: 12rem
    width: 45rem
    .arrow
      padding-left: 3rem
      width: 7rem
  .select
    margin-left: -3rem
    border-radius: 1rem
    font-size: 4rem
    line-height: 7rem
    width: 40rem
    height: 13rem
    padding-left: 4rem
    background-size: 4.5rem
    background-position: 90% 50%
  .radioBtnContainer
    font-size: 4rem
    .checkmark
      top: -3rem
      left: - 5rem
      width: 5rem
      height: 5rem
      background-color: #fff
      border-width: 0.2rem
      border-radius: 1rem
