$screen-sm: 576px
$screen-md: 768px
$screen-lg: 992px

@mixin card-effect
  display: flex
  position: relative
  border-radius: 0.33rem
  box-sizing: border-box
  background-image: linear-gradient(0deg, #ffffff 0%, #f3fbff 100%)
  box-shadow: 1.5px 2.598px 19px #d0dde4

.main_card
  flex-direction: column
  width: 14.125rem
  justify-content: flex-end
  height: 10rem
  border: 0.05rem solid #003056
  transition: 0.2s linear
  cursor: pointer
  @include card-effect
  &:hover
    transform: scale(1.01,1.01)
    text-decoration: none
  &:focus
    outline: 0.2rem solid #0c4c81
    outline-offset: 0.5rem
  .img
    max-height: 50%
    width: auto
    position: absolute
    top: 40%
    left: 50%
    transform: translate(-50%, -50%)
  .title
    text-transform: uppercase
    font-size: .8rem
    white-space: nowrap
    line-height: 1.5
    color: #e1343f
    font-weight: 700
    vertical-align: end
    text-align: center
    align-self: center
    line-height: 90%
    width: 100%
  .subTitle
    font-size: 0.5rem
    line-height: 1.5
    margin-bottom: 1rem
    color: #e1343f
    font-weight: 700
    vertical-align: end
    text-align: center
    align-self: center
    line-height: 95%

.container
  display: flex
  flex-direction: column
  flex-wrap: wrap
  gap: 1rem
  transition: .2s linear
  &:hover
    transform: scale(1.01,1.01)
    text-decoration: none
  &:focus
    outline: 0.2rem solid #0c4c81
    outline-offset: 0.5rem
  .additional_card
    width: 10rem
    text-align: center
    cursor: pointer
    @include card-effect
    .img
      border: 0.01rem solid #003056
      border-radius: 5px
      background-color: white
      width: 10rem
      margin: 0 auto
  .title
    font-size: 1.2vw
    line-height: 1.2
    margin-bottom: 1rem
    color: #e1343f
    font-weight: 700

@media (max-width: 1500px)
  .main_card
    .title
      font-size: 1.15vw
      text-align: center
      align-self: center
      line-height: 90%
    .subTitle
      font-size: 1.15vw
      line-height: 1.5
      margin-bottom: 1rem
      color: #e1343f
      font-weight: 700
      vertical-align: end
      text-align: center
      align-self: center
      line-height: 95%
  .container
    .additional_card

@media (max-width: $screen-lg)
  .main_card
    .title
      font-size: 1rem
      text-align: center
      align-self: center
      line-height: 90%
    .subTitle
      font-size: 1rem
      line-height: 1.5
      margin-bottom: 1rem
      color: #e1343f
      font-weight: 700
      vertical-align: end
      text-align: center
      align-self: center
  .container
    width: 12rem
    margin-bottom: 1rem
    .additional_card
      .img
        width: 12rem
    .title
      font-size: 1.3vw

@media (max-width: $screen-md)
  .container
    margin-bottom: 1rem
    .additional_card
      .img
        width: 15rem
    .title
      font-size: 1.4vw

@media (max-width: $screen-sm)
  .main_card
    width: 46rem
    height: 30.5rem
    .title
      font-size: 3rem
      text-align: center
      align-self: center
      line-height: 90%
      
    .subTitle
      font-size: 3vw
      line-height: 1.5
      margin-bottom: 2rem
      color: #e1343f
      font-weight: 700
      vertical-align: end
      text-align: center
      align-self: center
  .container
    width: 27rem
    margin-bottom: 1rem
    .additional_card
      height: 34.5rem
      .img
        width: 27rem
        height: 34.5rem
        background-size: contain
    .title
      font-size: 3rem
