@import ../../../../styles/Container.module
@import '../../../../styles/Variables.module'

@mixin mobile-menu-item
    font-size: 3rem
    line-height: 4rem
    font-weight: 700

.main_screen
    font-family: "Lato"
    padding: 3rem 0
    margin-bottom: 2rem
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    color: $blue-color
    &__header
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        align-self: center
    &__title
        font-size: 1.77rem
        line-height: 3rem
        color: $contrast-color
        font-weight: 700
        text-align: center
    &__promo
        font-size: 1.22rem
        margin-top: 1rem
        font-weight: 400
        text-align: center
.selectors,
.buttons
    display: flex
    margin: 2rem 0

@media (max-width: $screen-sm)
    .main_screen
        padding: 5rem 0
        margin-bottom: 2rem
        &__title
            font-size: 6rem
            line-height: 8rem
        &__promo
            font-size: 3rem
            line-height: 4rem
            margin-top: 2rem
            padding: 0 6rem
    .buttons
        justify-content: center
    .mobile_selector
        display: flex
        margin: 3rem 0 2rem
        width: 97%
        height: 8rem
        padding-left: 2.5rem
        justify-content: space-between
        align-items: center
        @include mobile-menu-item
        color: $blue-color
        border: 0.01rem solid #b9cfdd
        border-radius: 0.66rem
        background-color: #ffffff
        &__image
            width: 5rem
            height: auto
            padding-right: 1.5rem
    .mobile_menu_container
        position: relative
        width: 97%
    .mobile_menu
        position: absolute
        border: 0.01rem solid #b9cfdd
        display: flex
        flex-direction: column
        align-items: flex-start
        background-color: #ffffff
        border-radius: 0 0 0.66rem 0.66rem
        width: 100%
        top: -2.2rem
