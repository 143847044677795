$contrast-color: #dd373e
$text-color: #003056
$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px

.section
  display: flex
  justify-content: center
  margin: 0 7rem
  &_img
    margin: 4rem 1rem 5rem
    max-width: 40rem
  .form
    display: flex
    flex-wrap: wrap
    align-items: baseline
    margin: 4rem 1rem 5rem
    flex-direction: column
    &__title
      font-size: 3.3rem
      line-height: 4rem
      color: $contrast-color
      font-weight: 400
      padding-bottom: 0.5rem
    &__description
      font-size: 1.25rem
      line-height: 2rem
      color: $text-color
      font-weight: 400
      margin-bottom: 1.5rem
    &__item_label
      display: flex
      flex-direction: column
      font-size: 1.25rem
      line-height: 2.5rem
      color: $text-color
      font-weight: 700
      .form__item
        margin-right: 1.5rem
        width: 18rem
        line-height: 2.4rem
        font-size: 1.0rem
        border-radius: 0.3rem
        padding: 0.2rem 1rem
        color: #78a3be
        background-color: transparent
        border: thin solid #78a3be
        &::placeholder
          color: #b4c3ccbf
        &:focus
          outline: 0.15rem solid #0c4c81
          outline-offset: 0.3rem
      .form__item_description
        font-size: 0.9rem
        line-height: 2.5rem
        color: $text-color
        font-weight: 400
        font-style: italic
    .button
      display: flex
      justify-content: center
      width: 18rem
      height: 3rem
      align-items: center
      line-height: 2.4rem
      font-size: 1.3rem
      border-radius: 2rem
      padding: 0.25rem 2.5rem
      color: #ffffff
      background: $contrast-color
      border: none
      margin-bottom: .65rem
      align-self: flex-end
      &:hover,
      &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none

@media (max-width: $screen-lg)
  .section
    margin: 0 4rem
    &_img
      max-width: 40rem
    .form
      &__title
      &__description
        font-size: 1.5rem
      &__item_label
        font-size: 1.5rem
      .form__item
        font-size: 1.4rem
        width: 20rem
      .form__item_description
        font-size: 1.2rem

@media (max-width: $screen-md)
  .section
    margin: 0 2rem
    &_img
      max-width: 40rem
    .form
      &__title
        font-size: 3.7rem
      &__description
        font-size: 1.6rem
      &__item_label
        font-size: 1.7rem
        line-height: 3rem
      .form__item
        font-size: 1.5rem
        width: 22rem
      .form__item_description
        font-size: 1.2rem
      .button
        width: 22rem
        font-size: 1.5rem
        line-height: 3rem

@media (max-width: $screen-sm)
  .section
    flex-direction: column-reverse
    margin: 0 7rem
    &_img
      max-width: 100rem
    .form
      flex-direction: column
      &__title
        font-size: 5.5rem
        line-height: 8rem
        text-align: center
      &__description
        font-size: 2.5rem
        line-height: 4.5rem
      &__item_label
        font-size: 3rem
        line-height: 7rem
      .form__item
        line-height: 4.5rem
        font-size: 2rem
        width: 80rem
        min-height: 4.5rem
      .form__item_description
        font-size: 2rem
        line-height: 4.5rem
      .button
        width: 18rem
        height: 6rem
        margin-top: 1rem
        font-size: 3rem
        line-height: 6rem
        align-self: center
        width: 30rem
