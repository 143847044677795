$screen-sm: 576px
.header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 1rem 3rem
  background: #ffffff
  z-index: 20
  .menu_btn
    display: none
  .logo
    display: flex
    align-items: center
    z-index: 20
    .img
      width: 3rem
      z-index: 20
    .h2
      font-size: 1.1458rem
      line-height: 1.25rem
      color: #dd373e
      font-weight: 700
      margin-left: .5rem
      z-index: 20
  .nav
    z-index: 20
    color: #dd373e
    .home
      text-transform: uppercase
    .li
      margin: 0 .5rem
      font-size: .8333rem
      line-height: 1.25rem
      a
        text-decoration: none
      &:hover
        color: #740006
    .btn
      font-weight: 700
      padding: .4rem 1.5rem
      border-radius: 50rem
      border: .05rem solid #dd373e
      &:hover
        text-decoration: none
        color: #740006
        border: .05rem solid #740006
    .getstarted
      background: #dd373e
      color: #ffffff
      border: none
      &:hover
        background: #740006
        color: #ffffff
        border: none
    @media (max-width: $screen-sm)
      display: none
@media (max-width: $screen-sm)
  .header
    flex-direction: row-reverse
    position: relative
    .logo
      .img
        width: 10rem
      .h2
        font-size: 3rem
    .menu_btn
      display: flex
      width: 7rem
      font-size: 3rem
      z-index: 40
.white
  background-color: transparent
  color: #ffffff
  li
    color: #dd373e
  .logo
    h2
      color: #ffffff
    .white_text
      color: #dd373e

//This is for welcomePage styles
.welcomePage
  width: 87%
  padding: 1.5rem 3rem
  color: #dd373e
  .logo
    display: flex
    .img
      width: 2.9rem
      z-index: 20
    .h2
      font-size: 1.2rem
      display: block
      color: #dd373e
  .nav
    .li
      color: #dd373e
      a
        &:hover
          text-decoration: none
          color: #740006
    .btn
      display: flex
      justify-content: center
      align-items: center
      width: 8rem
      height: 2.9rem
      background-color: transparent
      border: 1px solid #dd373e
      color: #dd373e
      font-family: 'Lato', serif
      &:hover
        text-decoration: none
        border: 1px solid #dd373e
        text-transform: none
    .getstarted
      background: #dd373e
      color: #ffffff
      border: none
      text-decoration: none
      &:hover
        color: #ffffff
        border: none
  @media (max-width: $screen-sm)
    display: none