$screen-sm: 576px

.headerWrapper
  width: 100%
  display: flex
  justify-content: start
  align-items: center
  height: 4.5625rem
  border-radius: 0.375rem
  background-color: #103155
  margin-bottom: 1.5rem
  @media (max-width: $screen-sm)
    justify-content: center
    border-radius: 2.25rem
    height: 11.4rem
  &_image
    width: 1.5625rem
    height: 1.6875rem
    margin-left: 1rem
    margin-right: 0.5rem
    @media (max-width: $screen-sm)
      width: 3.9rem
      height: 4.216rem
  &_title
    font-size: 1.5rem
    line-height: 2.125rem
    color: #FFFFFF
    font-weight: 500
    margin-left: 0.5rem
    @media (max-width: $screen-sm)
      font-size: 3.75rem