@import ../../../styles/Variables.module
// import for banner and nested elements
@import ../../UpgradePage/UpgradePage.module

.create_account
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  font-family: "Lato"
  &__title
    padding: 3rem 0 1rem
    font-size: 3rem
    color: $blue-color
    font-weight: 400
  &__description
    font-size: 1.25rem
    color: $blue-color
    font-weight: 400
  .container
    display: flex
    justify-content: space-around
.promo
  padding: 0 2rem 2rem
  @include text
  line-height: 1.5rem
  display: flex
  flex-direction: column
  &__item
    @include text
    line-height: 2.5rem
    color: $blue-color
    position: relative
  .checkImg
    height: auto
    width: 7%
  .check
    padding-left: 0.625rem
.banner
  min-width: 25rem

// Below styles different component
.header
  padding-left: 0.625rem
  font-size: 1.375rem
  line-height: 2.125rem
  color: $contrast-color
  font-weight: 700
.title
  height: 20vw
  background-size: cover
  padding: 5rem 13rem
  display: flex
  flex-direction: column
  justify-content: center
  .h2
    font-size: 3.3333rem
    line-height: 1.25rem
    font-weight: 700
    color: #ffffff
    padding-top: 1.35rem
    margin-bottom: 2.5rem
    z-index: 15
  .p
    font-size: 1.1458rem
    line-height: 1.875rem
    font-weight: 300
    color: #ffff1e
    width: 79.5%
    z-index: 10
    br
      display: none
  .img
    position: absolute
    right: 7.2917rem
    top: 4.25rem
    width: 12.2396rem
    height: 20.5729rem
    z-index: 20
    &_back
      padding-top: 1.8rem
      position: absolute
      right: 0
      top: 3.75rem
      width: 100%
      height: 22.5729rem
      z-index: 5
@media (max-width: $screen-sm)
  .create_account
    justify-content: stretch
    &__title
      padding-top: 5rem
      font-size: 6rem
    &__description
      padding: 2rem 6rem 6rem
      font-size: 4rem
      line-height: 4rem
      text-align: center
    .container
      width: 95%
  .promo
    align-self: start
    padding: 2rem 2rem 6rem
    font-size: 3.5rem
    line-height: 7rem
    &__item
      font-size: 4rem
      line-height: 9rem
    .checkImg
      padding-right: 1.5rem
      height: auto
      width: 9%
  .banner
    min-width: 100%
  .header
    font-size: 3rem
  .item
    font-size: 2.9458rem
    img
      height: 4rem
  .title_mobile
    height: 80rem
    background: url("//img/sign/mobile_background.webp") no-repeat center
    background-size: cover
    .h2
      width: 43vw
      font-size: 6rem
      line-height: normal
    .p
      font-size: 4.9458rem
      line-height: normal
      font-weight: 300
      text-align: left
      color: #ffff1e
      width: 90%
      z-index: 10
      br
        display: none
      .p_background
        display: none
    .img
      position: absolute
      right: 2.2917rem
      top: 12rem
      width: 21.2396rem
      height: 34.5729rem
      z-index: 20
      &_back
        display: none
.section
  display: flex
  justify-content: space-between
  margin: 5rem 0rem
.card
  width: 30%
  display: flex
  flex-direction: column
  &__title
    color: red
  &__subtitle
    display: none
  div
    background: #ffffff
    padding: .6rem
    margin: .5rem
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    h4
      font-size: 1.25rem
      font-weight: 600
      margin: 0 0 .5rem
    h5
      font-size: 1rem
      font-weight: 600
      margin: .5rem 0
    span
      font-size: .8rem
@media (max-width: $screen-sm)
  .section
    width: 90%
    margin-left: 5%
    order: 2
    flex-direction: column-reverse
  .card
    justify-content: center
    width: 100%
    margin-bottom: 2rem
    &__title
      color: red
    div
      background: #ffffff
      padding: 3.5rem
      width: 100%
      margin: 5rem auto 0
      border-radius: .3125rem
      box-shadow: 0 .1563rem .651rem #c6dceabf
      h4
        display: none
      .card__subtitle
        display: flex
        justify-content: left
        font-size: 5.5rem
        margin-bottom: 2.5rem
      h5
        text-align: left
        line-height: normal
        font-size: 4rem
        font-weight: 600
        margin: .5rem 0
      table
        width: 100%
      tr
        display: flex
        flex-direction: row
        justify-content: space-between
        width: 100%
        font-size: 3.8rem
        color: #9a9898
      td:first-child
        margin-top: 1rem
      td:last-child
        margin-top: 2rem
        padding-left: 4rem
      span
        font-size: 3.5rem
