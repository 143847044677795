@import ../../../../styles/Variables.module

.empty_section
    align-self: center
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    &__picture
        width: 17rem
        height: auto
        margin: 1.5rem 0
    &__description
        font-size: 1.22rem
        line-height: 1.889rem
        color: $blue-color
        font-weight: 400
        font-family: "Lato"
@media (max-width: $screen-sm)
    .empty_section
        &__picture
            width: 45rem
            height: auto
            margin-bottom: 5rem
        &__description
            font-size: 4rem
            line-height: 5rem
