@import ../../../../../styles/Variables.module

.requests
    &__title
        font-size: 1.778rem
        line-height: 3rem
        color: $blue-color
        font-weight: 700
        font-family: "Lato"
        margin: 1rem 0 1rem
.player_data
    width: 54rem
    &__header
        background-color: $background-color
        font-size: 1.1rem
        line-height: 4rem
        color: $blue-color
        font-weight: 700
        border-radius: 0.3rem 0.33rem 0 0
        border: 0.05rem solid #ccdfe9
        &__item
            width: 100%
            padding: 0 1rem
            box-sizing: border-box
            display: flex
            justify-content: space-between
            align-items: center
    &__main
        width: 100%
        border-radius: 0 0 0.33rem 0.33rem
        border: 0.05rem solid #ccdfe9

@media (max-width: $screen-sm)
    .requests
        &__title
            font-size: 3rem
            line-height: 8rem
    .player_data
        width: 100%
        &__header
            font-size: 3rem
            line-height: 8rem
            border-radius: 0.66rem 0.66rem 0 0
        &__main
            border-radius: 0 0 0.66rem 0.66rem
