@import ../../../styles/Variables.module

.header__main_dashboard_title
    display: flex
    color: #003056
    font-family: Lato, serif
    font-size: 2rem
    font-weight: 400
    line-height: 2.625rem

@media(max-width: $screen-sm)
    .header__main_dashboard_title
        font-size: 4rem
        line-height: 5.5rem
        padding-top: 3rem
        font-weight: bold
        width: 35rem
        text-align: center
