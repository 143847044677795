@import ../../../../../../styles/Variables.module

.team_summary
    display: flex
    flex-direction: row
    gap: 2.5rem
    font-size: 1.3rem
    color: $light-blue-color
    padding: 0 0 1rem 0
    &__goal_not_reached
        padding: 0 0.5rem
        font-weight: 700
        color: $contrast-color
    &__goal_reached
        padding: 0 0.4rem
        font-weight: 700
        color: #2fdcbd
.title
    font-size: 1.778rem
    line-height: 3rem
    color: $blue-color
    font-weight: 700
    font-family: "Lato"
    margin: 1rem 0 1rem
.reached_icon
    width: 1.2rem
    height: auto
    margin: 0 1rem 0.25rem 0.5rem
.manager_player
    margin-top: 1rem
    font-size: 0.8rem
    line-height: 1.2rem
    color: $blue-color
    font-style: italic
    &::before
        content: "* "
        color: $contrast-color
.player_data
    width: 54rem
    &__header
        background-color: $background-color
        font-size: 1.1rem
        line-height: 4rem
        color: $blue-color
        font-weight: 700
        border-radius: 0.33rem 0.33rem 0 0
        border: 0.05rem solid #ccdfe9
        &__item
            width: 20%
            padding: 0 1rem
            box-sizing: border-box
            &:first-child
                width: 5%
            &:nth-child(2)
                width: 30%
            &:nth-last-child(-n + 4):not(:last-child)
                &::after
                    position: relative
                    bottom: 0.075rem
                    padding: 0.3rem
                    font-size: 0.6rem
                    content: "▼"
        &__manager_item
            width: 5%
            padding: 0 1rem
            box-sizing: border-box
            &:nth-child(2)
                width: 70%
    &__main
        width: 100%
        border-radius: 0 0 0.33rem 0.33rem
        border: 0.05rem solid #ccdfe9

@media (max-width: $screen-sm)
    .team_summary
        font-size: 3rem
    .title
        font-size: 3rem
        line-height: 6rem
    .reached_icon
        width: 2.4rem
        height: auto
        margin: 0 0 0.25rem 0.5rem
    .manager_player
        margin-top: 1rem
        font-size: 1.8rem
        line-height: 2.5rem
    .player_data
        width: 100%
        &__header
            font-size: 2.5rem
            line-height: 6rem
            border-radius: 0.66rem 0.66rem 0 0
            &__item
                &:nth-last-child(-n + 4):not(:last-child)
                    &::after
                        bottom: 0.075rem
                        padding: 0.5rem
                        font-size: 1.2rem
        &__main
            border-radius: 0 0 0.66rem 0.66rem
