$screen-sm: 576px
.title
  height: 20vw
  background-size: cover
  padding: 5rem 13rem
  display: flex
  flex-direction: column
  justify-content: center
  .h2
    font-size: 3.3333rem
    line-height: 1.25rem
    font-weight: 700
    color: #ffffff
    padding-top: 1.35rem
    margin-bottom: 2.5rem
    z-index: 15
  .p
    font-size: 1.1458rem
    line-height: 1.875rem
    font-weight: 300
    color: #ffff1e
    width: 79.5%
    z-index: 10
    br
      display: none
  .img
    position: absolute
    right: 7.2917rem
    top: 4.25rem
    width: 12.2396rem
    height: 20.5729rem
    z-index: 20
    &_back
      padding-top: 1.8rem
      position: absolute
      right: 0
      top: 3.75rem
      width: 100%
      height: 22.5729rem
      z-index: 5
@media (max-width: $screen-sm)
  .title_mobile
    height: 80rem
    background: url("//img/sign/mobile_background.webp") no-repeat center
    background-size: cover
    .h2
      width: 43vw
      font-size: 6rem
      line-height: normal
    .p
      font-size: 4.9458rem
      line-height: normal
      font-weight: 300
      text-align: left
      color: #ffff1e
      width: 90%
      z-index: 10
      br
        display: none
      .p_background
        display: none
    .img
      position: absolute
      right: 2.2917rem
      top: 12rem
      width: 21.2396rem
      height: 34.5729rem
      z-index: 20
      &_back
        display: none
.section
  display: flex
  justify-content: space-between
  margin: 5rem 20rem
.card
  width: 30%
  display: flex
  flex-direction: column
  &__title
    color: red
  &__subtitle
    display: none
  div
    background: #ffffff
    padding: .6rem
    margin: .5rem
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    h4
      font-size: 1.25rem
      font-weight: 600
      margin: 0 0 .5rem
    h5
      font-size: 1rem
      font-weight: 600
      margin: .5rem 0
    span
      font-size: .8rem
@media (max-width: $screen-sm)
  .section
    width: 80%
    margin: 0 auto
    order: 2
    flex-direction: column-reverse
  .card
    justify-content: center
    width: 100%
    margin-bottom: 2rem
    &__title
      color: red
    div
      background: #ffffff
      padding: 3.5rem
      width: 100%
      margin: 5rem auto 0
      border-radius: .3125rem
      box-shadow: 0 .1563rem .651rem #c6dceabf
      h4
        display: none
      .card__subtitle
        display: flex
        justify-content: left
        font-size: 5.5rem
        margin-bottom: 2.5rem
      h5
        text-align: left
        line-height: normal
        font-size: 4rem
        font-weight: 600
        margin: .5rem 0
      table
        width: 100%
      tr
        display: flex
        flex-direction: row
        justify-content: space-between
        width: 100%
        font-size: 3.8rem
        color: #9a9898
      td:first-child
        margin-top: 1rem
      td:last-child
        margin-top: 2rem
        padding-left: 4rem
      span
        font-size: 3.5rem