.box
  align-items: center
  padding: 2.5rem
    
.column30
  margin-top: 2.9rem
  padding: 1rem
  padding-right: 2rem
  background-color: white
  border-radius: 5px
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

.videoCluster
  padding: 1.2rem 1.5rem 0.6rem 1.5rem
  border-radius: 5px
  background-color: white
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  width: 100%

.row:after
  content: ''
  display: table
  clear: both

.menuRow
  margin-bottom: 1rem
  cursor: pointer

.menuItem
  float: left
  width: 95%
  font-weight: bold
  color: #e1343f

.listItem
  color: #164162
  display: flex
  align-items: center
  font-weight: bold

.chevronColumn
  float: left
  width: 5%

.bookmarkColumn
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  gap: 10px

img.icon
  height: 1rem
  width: 1rem
  margin-right: 1rem

img.chevron
  heignt: 0.9rem
  width: 0.9rem

.wrapper
  padding: 1rem

.videoCatogoryHeader
  font-size: 1.2rem
  color: #164162
  font-weight: bold
  margin-bottom: 1rem

.videoCategory
  margin-bottom: 1.5rem

.bookmark
  height: 2rem
  width: 2rem

img.bookmark
  cursor: pointer

.pin
  height: 1.7rem
  width: 1.7rem
  margin-right: 1rem

li.listRow:not(:last-child)
  border-bottom: solid thin
  color: lightgrey
  padding-bottom: 0.7rem

li.listRow:not(:first-child)
  margin-top: 10px

li.listRow
  width: 100%
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between

ul
  width: 100%

div.listRow
  width: 100%
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between

.textListItem
  vertical-align: text-top
  font-size: 1.25rem


@media ( max-width: 576px)
  .videoCatogoryHeader
    font-size: 3.5rem
    margin: 3rem 0 1.5rem 0
  .column30
    font-size: 3.3rem
    padding-bottom: 1.5rem
    margin-bottom: 5rem
  img.icon
    height: 2.7rem
    width: 2.7rem
    margin-right: 3rem
  img.chevron
    heignt: 2.6rem
    width: 2.6rem
  .menuItem
    padding-left: 1rem
  .videoCategory
    font-size: 3rem
    margin-bottom: 2.5rem
  .pin
    height: 5rem
    width: 5rem
  .bookmark
    height: 5rem
    width: 5rem
  li.listRow
    padding-bottom: 3rem
  .videoCluster
    padding-top: 3rem
  .textListItem
    font-size: 3.25rem

@media( min-width: 576px )
  .column30
    float: left
    width: 30%
  .column70
    float: left
    width: 67%
    margin-left: 1.5rem
  .box
    margin: 0 15rem
    margin-bottom: 2rem
    align-items: center
    padding: 2.5rem 0 0 0

  .button
    padding-left: 1rem
    padding-right: 1rem
    min-width: 5rem


  ul.subCategory li
    display: block
    color: #e1343f
    margin-left: 3rem
    margin-top: 0.3rem

