img.icon
  height: 2rem
  width: 2rem
  cursor: pointer

img.icon:hover
  transform: scale(1.2)

img.icon:first-of-type
  margin-right: 30px

@media ( max-width: 576px )
  img.icon
    height: 5rem
    width: 5rem