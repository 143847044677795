@import ../../../styles/Variables.module

@mixin mobile-menu-item
    font-size: 3rem
    line-height: 4rem
    font-weight: 700
    color: $contrast-color

.mobile_menu
    &__item
    &__input
        -moz-appearance: none
        -webkit-appearance: none
        appearance: none
        &:checked+.mobile_menu__label
            color: $blue-color
    &__label
        padding-left: 2.5rem
        @include mobile-menu-item
