@import '../../../../styles/Button.module'

@mixin sm-text
  font-size: 4rem
  line-height: 10rem
.form
  width: 100%
  margin: 0.5rem
  .divTotal
    position: relative
    display: flex
    padding: 0.4rem
    background-color: #f0f8fd
    margin: 1rem 0 2rem
    width: 30rem
    border: 0.1rem solid #b9cfdd
    border-radius: 0.33rem
    @media (max-width: $screen-sm)
      width: 100%
      padding: 0
      border-radius: 1rem
  .placingText
    display: flex
    flex-direction: column
    justify-content: center
  .placingTextTitle
    margin-top: 2rem
    color: $blue-color
    font-size: 0.889rem
    font-style: italic
    line-height: 1.333rem
    font-family: "Lato"
  .linkTerms
    color: #dd373e
    text-decoration: underline
  .PaymentInfoTitle
    margin-bottom: 1.5rem
    font-size: 1.555rem
    color: $blue-color
    @media (max-width: $screen-sm)
      font-size: 6rem
      line-height: 11rem
      margin: 2rem 0 4rem
  @media (max-width: $screen-sm)
    .placingText
      margin-top: 4rem
      font-size: 3rem
      width: 100%
    .linkTerms
      font-size: 3.5rem
      margin-bottom: 3rem
    .placingTextTitle
      font-size: 3.5rem
      line-height: 6rem
  h5
    font-size: 1.25rem
    font-weight: 400
  h6
    font-size: .9375rem
    line-height: 1.4583rem
    font-weight: 400
  .section
    width: 100%
    margin: 1rem 0
    h4
      font-size: 1.25rem
      font-weight: 700
      margin-bottom: .5rem
    .row
      display: flex
      justify-content: space-between
      p
        padding-top: 1rem
        font-size: 1rem
    .col
      display: flex
      flex-direction: column
      width: 48%
      label
        font-size: .9375rem
        font-weight: 400
        margin-bottom: .5rem
      span
        color: red
      @media (max-width: $screen-sm)
        span
          color: red
          font-size: 3.5rem
          margin-left: 2rem
      select, [name=hear]
        flex-direction: column
        justify-content: center
        width: 19.55rem
        margin-bottom: .6rem
        height: 2.55rem
        padding: .3512rem .8rem
        font-size: 1rem
        line-height: 1.7708rem
        font-weight: 400
        color: #000000
        border-radius: .3125rem
        border: thin solid #b9cfdd
        background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
        background-size: 1.3rem
        -webkit-appearance: none
        background-position-x: 17.3rem
      select[name="country"]
        @extend select, [name=hear]
      input
        height: 2.3rem
        font-size: .9375rem
        line-height: 1.7708rem
        font-weight: 400
        border-radius: .3125rem
        border: thin solid #b9cfdd
        padding-left: 0.2rem
        padding-right: 0.2rem
      .Required
        border-color: #dd373e
        box-shadow: 0 0 .3rem #dd373e
      select
        height: 2.3rem
        font-size: .9375rem
        line-height: 1.7708rem
        font-weight: 400
        background: #ffffff
        border-radius: .3125rem
        border: .0521rem solid #b9cfdd
        padding-left: 0.2rem
        padding-right: 0.2rem
    .cupon
      width: 74%
      display: grid
      grid-template-areas: 'label label label label' 'input input input button' 'input input input button'
      grid-template-columns: repeat(4, 1fr)
      grid-template-rows: repeat(3, 1fr)
      grid-column-gap: 1rem
      label
        grid-area: label
      input
        grid-area: input
      button
        grid-area: button
        background: #808080
        line-height: 0
        height: 2.497rem
    p
      font-size: .7292rem
      font-weight: 400
      width: 43%
      margin: auto auto .15rem
  .btn_step
    display: flex
    justify-content: flex-end
  .buttonDefault
    @include button
    display: flex
    justify-content: center
    align-items: center
    width: 12rem
    height: 100%
    color: #ffffff
    font-weight: 700
    background-color: #dd373e
    border: none
  .grayOptions
    color: gray
  .btnGroup
    display: flex
    justify-content: space-between
    button
    &:nth-child(1)
      order: 2
    &:nth-child(2)
      order: 1
    button
      margin: 0
  @media (max-width: $screen-sm)
    .btnGroup
      width: 80%
      margin: 3.5rem 0rem
      button
        &:nth-child(2)
          margin-right: 10rem
  .invalidEmailAdressMessage
    color: red
  .emailFlexBlock
    display: flex
    div
      margin-left: 10px

@media (max-width: $screen-sm)
  .form_mobile
    display: flex
    flex-direction: column
    width: 95%
    margin: 4rem 3rem 0
    h5
      width: 100%
      font-size: 5.25rem
      margin-bottom: 1rem
    h3
      font-size: 4.5rem
      margin-bottom: 1rem
    h6
      text-align: center
      font-size: 4.375rem
      line-height: 5.4583rem
      margin-bottom: 10px
    .section
      margin: 1rem 0
      font-size: 5rem
      .row
        display: flex
        flex-direction: column
        p
          width: 100%
          padding-top: 1rem
          font-size: 3.5rem
          margin-left: 2rem
      .col
        width: 100%
        display: flex
        flex-direction: column
        label
          font-size: 3.7rem
        input
          margin-top: 1rem
          height: 8rem
          font-size: 3.3rem
          line-height: normal
          font-weight: 400
          border-radius: .9125rem
          border: .0521rem solid #b9cfdd
          padding: .2rem
        select, [name=hear]
          flex-direction: row
          width: 100%
          font-size: 3.5vw
          line-height: 5rem
          height: 8.2rem
          color: #000000
          border-radius: .9125rem
          border: thin solid #b9cfdd
          background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
          background-size: 4rem
          -webkit-appearance: none
          background-position-x: 97%
        select[name="country"]
          display: flex
          flex-direction: row
          width: 100%
          height: 8.2rem
          font-size: 3.5vw
          line-height: 5rem
          margin-bottom: 2rem
          border-radius: .9125rem
          border: thin solid #b9cfdd
          background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
          background-size: 4rem
          -webkit-appearance: none
          background-position-x: 97%
    .buttonDefault
      width: 20rem
      height: 8rem
      margin: 4.5rem 0rem 6rem 0rem
      color: #ffffff
      font-size: 3.0417rem
      line-height: 1.7708rem
      font-weight: 700
      background-color: #dd373e
      padding: 3.039rem 3.013rem
      border-radius: 3.7625rem
      @media (max-width: $screen-sm)
        @include sm-text
        border-radius: 6rem
        width: 35rem
        height: 11.5rem
      //step1step2 is done//
.confirmPass
  color: red
  width: 397px
  display: inline

.submitButton
  width: 11.5rem
  height: 3.1rem
  background-color: #dd373e
  color: #fff
  border-radius: 2rem
  border: none
  text-align: center
.PaymentInfoSubtitle
  font-size: .86rem
  margin-bottom: 1rem
  margin-top: 1rem
  @media (max-width: $screen-sm)
    font-size: 3.2rem

.parent_form
  display: block
  width: 100%
  label
    font-size: 1rem
  @media (max-width: $screen-sm)
    display: flex
    flex-direction: column
    label
      font-size: 4rem
      margin-bottom: 1.5rem
      width: 90%

.card
  background-color: #ffffff
  width: 20rem
  height: 3.0rem
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 400
  border-radius: .3125rem
  border: .0521rem solid #b9cfdd
  padding: 1rem .4rem 2rem 1rem
  margin: .5rem 0rem
  @media (max-width: $screen-sm)
    padding: 3.5rem
    width: 100%
    height: 12rem
    border-radius: .9125rem
    margin-bottom: 1.5rem
.card1
  @extend .card
  width: 20rem
  @media (max-width: $screen-sm)
    width: 55rem
    height: 12rem
    margin-right: 4rem
    padding: 3.5rem

.card2
  @extend .card
  width: 8rem
  margin-left: 2rem
  @media (max-width: $screen-sm)
    width: 35rem
    padding: 3.5rem
    margin-left: 0

.cardContainer
  display: flex
  margin-top: 1.5rem
  @media (max-width: $screen-sm)
    display: flex
    width: 100%
    margin: 3rem 0
    justify-content: space-between

.cardContainer1
  display: inline-block

.pay_button
  margin-top: .5rem
  width: 11.5rem
  height: 3.1rem
  background-color: #dd373e
  color: #fff
  border-radius: 2rem
  border: none
  text-align: center

.cardNumText
  margin: 0
  font-size: .84rem
  font-family: 'Lato', serif
  color: $blue-color
  font-weight: 600
.cardNumText_credit
  position: relative
  @extend .cardNumText
  &::after
    content: ''
    position: absolute
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAXCAMAAAC27AbQAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAAuc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/dnaJ+SwAAADV0Uk5TAIL/hIeeOCcsOZyTN5SDR6+2vS0vMLqamZaVqY2Iiae+vLkICbcEBbtoU1Zlw3AqLnLCtbRnWWtlAAAAhklEQVR4nOXSRxaCAAxF0ee3gF3BLij2Lrb9r02YQ3DuG99BkhOgJKsySRVVa46bnVNvqAkttbHqqEtPJgH18eS7g/zcoUaMzYnTJj+ZaaGZMVcQLvILAy2JCvdasdbGJFvt/tpE2pvmkNzQK7zzkZPOJrnoCjfd/fiRXew/9Url2/yMD3wBaw8V0JYtoWgAAAAASUVORK5CYII=')
    background-size: 90% 90%
    top: 2.8rem
    left: 27.3rem
    background-repeat: no-repeat
    width: 2rem
    height: 1.5rem
  @media (max-width: $screen-sm)
    display: flex
    justify-content: flex-start
    margin-left: 0
.cardNumText2
  @extend .cardNumText
  margin-left: 2.2rem
  @media (max-width: $screen-sm)
    margin-left: 0rem

.redStar
  color: #dd373e

.picCardsStyle
  width: 30rem
  margin-top: 2rem
  margin-bottom: 2rem
  @media (max-width: $screen-sm)
    width: 90%

.cardNumTextDiscCoupon
  @extend .cardNumText
  padding-top: 1rem
  @media (max-width: $screen-sm)
    display: flex
    width: 90%
    margin-left: 1rem
    font-size: 3rem
    padding-top: 0
.pay_button
  outline: none
  margin-left: 2.5rem
  width: 15rem
  margin-top: 1rem
  @media (max-width: $screen-sm)
    width: 29rem
    height: 7rem
    border-radius: 3.5rem
    font-size: 3.2rem
    font-weight: 700
.totalAndBtnPay
  width: 30rem
  margin-top: 2rem
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  @media (max-width: $screen-sm)
    margin-top: 7rem
    width: 100%
.divTotalAndPrice
  display: flex
  flex-direction: column
  justify-content: center
  font-size: 1rem
  line-height: 2rem
  font-family: 'Lato', serif
  color: $blue-color
  font-weight: 600
  .totalPrice
    display: flex
    width: 28rem
    padding: 0.5rem 1rem
    justify-content: space-between
  .subPrice
    @extend .totalPrice
@media (max-width: $screen-sm)
  .divTotalAndPrice
    @include sm-text
    height: 12rem
    width: 100%
    .totalPrice
      font-size: 4rem
      display: flex
      width: 100%
      justify-content: space-between
      padding: 0 3rem

.subPrice_firstspan
  width: 10rem
  @media (max-width: $screen-sm)
    width: auto

.totalPrice_hr
  width: 15rem
  position: absolute
  height: 1px
  top: -.1rem
  background-color: red
  @media (max-width: $screen-sm)
    width: 40rem
    top: 1rem

.container_coupon
  width: 30rem
  display: flex
  justify-content: space-between
  align-items: center
  @media (max-width: $screen-sm)
    width: 100%
    height: 12rem
  input[type="text"]
    margin-right: 2.5rem
    width: 30rem
    background-color: #ffffff
    padding: 1rem 1rem
    @extend .card1
    @media (max-width: $screen-sm)
      margin-right: 4rem
      width: 55rem
      font-size: 3.3rem
      padding: 3.5rem

.spinner
  width: 20rem !important
  height: 20rem !important
  @media (max-width: $screen-sm)
    width: 10rem !important
    height: 10rem !important
