.main_container
  display: flex
  margin: 3rem 0rem 6rem 0rem
  justify-content: center

.main_form
  display: flex
  width: 40rem
  height: 40.625rem
  padding: 2rem 3rem 2rem 3rem
  justify-content: center
  color: #000000
  border-radius: 2%
  background-color: #ffffff
  box-shadow: 2px 2px 2px 2px rgba(0.15,0.15,0.15,0.15)
.text_desc
  text-align: center
  color: #dd373e

.main_form_form
  padding-top: 2rem
  display: flex
  justify-content: space-between
  flex-wrap: wrap

.message_form
  padding-top: 1rem
  display: flex
  flex-direction: column
  justify-content: center
  flex-basis: 100%

.form_inputs
  display: flex
  flex-direction: column
  width: 15rem
  &__label
    color: #003056
    font-family: Lato
    font-size: 1.125rem
    font-weight: 700
    line-height: 2.125rem

input[name="name"], input[name="email"]
  width: 100%
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 400
  border-radius: .3125rem
  border: thin solid #b9cfdd
  margin: .5rem 3rem 1rem 0

input[name="message"]
  padding: 10rem

textarea[name="task"]
  resize: none
  font-size: .9375rem
  width: 100%
  height: 15rem
  line-height: 1.7708rem
  font-weight: 400
  color: #000000
  border-radius: .3125rem
  border: thin solid #b9cfdd
  padding: .6512rem 1rem
  margin: .5rem 3rem 1rem 0

label[name="task"]
  padding: 10rem
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 700
  color: #000000

.send_button
  margin-left: 19rem
  margin-top: 2.10rem
  width: 15rem
  height: 3rem
  color: #ffffff
  background-color: #dd373e
  border-radius: 3rem
  border: none

.modal
  margin: 30vmin auto
  .buttonStrap
    padding: .5rem 3rem