@import ../../../../styles/Variables.module

@mixin button
    font-weight: 700
    font-size: 1rem
    line-height: 2rem
    background-color: #fff
    text-decoration: underline
    border: none
    outline: none
    &:hover,
    &:active
        transform: scale(1.01)
        text-decoration: underline

.button_subscription
    @include button
    color: $contrast-color
    &:focus
        outline: 0.01rem solid #0c4c81
        border-radius: 0.33rem
    @media (max-width: $screen-sm)
        font-size: 3rem
        line-height: 4rem
        order: 4
