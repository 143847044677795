$blue-color: #003056
$contrast-color: #dc373e
$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px

@mixin bold-text
    color: $blue-color
    font-weight: 700

.container
    display: flex
    width: 52rem
    height: 33rem
    border: 0.5rem solid $blue-color
    margin: 2rem auto 1rem
    justify-content: space-between
    padding: 0 1rem
    background: center no-repeat #f4faff url('./soccer-ball.png')
.certificate
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 1.5rem 1rem
    color: $blue-color
    &__border
        position: absolute
        width: 50.5rem
        height: 31.5rem
        top: 0.3rem
        left: -0.8rem
    &__logo
        display: flex
        align-items: center
        margin: 2.5rem 0 2rem
        &__img
            width: 3rem
        &__title
            font-size: 1rem
            font-weight: 700
            padding-left: 0.3rem
            color: $contrast-color
    &__date
        font-size: 0.9rem
        line-height: 1.5rem
        @include bold-text
    &__user_name
        font-size: 1.5rem
        line-height: 3rem
        @include bold-text
    &__description
        font-size: 1.2rem
        line-height: 1.4rem
        font-weight: 400
        font-style: italic
        &_red
            font-size: 1.3rem
            line-height: 1.5rem
            color: $contrast-color
    &__reward
        margin: 3rem 0 2rem
        font-size: 1rem
        .user_reward
            margin-left: 0.5rem
            font-size: 1.1rem
            border-bottom: 0.1rem solid $blue-color
    &__sign
        width: 10rem
    &__footer
        font-size: 1.1rem
        line-height: 1.4rem
        color: $blue-color
        font-weight: 400
        &_strong
            @include bold-text
.label
    width: 9.5rem
    height: 22rem
    margin-top: 0.5rem
    margin-right: 1.5rem

@media (max-width: $screen-sm)
    .container
        width: 80rem
        height: 48rem
    .certificate
        padding: 2rem 1.5rem
        &__border
            width: 78.5rem
            height: 46.5rem
        &__logo
            margin: 2.5rem 0 2rem
            &__img
                width: 6rem
            &__title
                font-size: 1.5rem
                font-weight: 700
                padding-left: 0.5rem
                color: $contrast-color
        &__date
            font-size: 1.5rem
            line-height: 1.5rem
            @include bold-text
        &__user_name
            font-size: 2rem
            line-height: 4rem
            @include bold-text
        &__description
            font-size: 2rem
            line-height: 2.4rem
            font-weight: 400
            font-style: italic
            &_red
                font-size: 2.2rem
                line-height: 3rem
                color: $contrast-color
        &__reward
            margin: 3rem 0 2rem
            font-size: 2rem
            .user_reward
                margin-left: 0.5rem
                font-size: 2rem
                border-bottom: 0.3rem solid $blue-color
        &__sign
            width: 15rem
        &__footer
            font-size: 2rem
            line-height: 2.4rem
            color: $blue-color
            font-weight: 400
            &_strong
                @include bold-text
                font-size: 2rem
    .label
        width: 15rem
        height: 30rem
        margin-top: 0.7rem
        margin-right: 2.5rem
