@import '../../../styles/Variables.module'

.avatar_list
    display: flex
    @media (max-width: $screen-sm)
        width: 300%
        height: 130px
        display: flex
        align-items: center

.avatar_list__item
    display: flex
    justify-content: center
    align-items: center
    border-radius: 0.33rem
    background-color: #e3f0f7
    border: 0.1px solid #b9cfdd
    padding: 0.5rem 0.8rem
    margin-right: 0.5rem
    margin-bottom: 2rem
    box-sizing: border-box
    &:hover,
    &:focus,
    &:active
        border: none
    @media (max-width: $screen-sm)
        padding: 1rem 2rem
        width: 25%
        height: 20rem
        margin: 0 1.5rem
        border-radius: 1rem
        &:first-child
            margin-left: 0

.iconsHeading
    font-size: 2rem
    line-height: 4rem
    color: $blue-color
    @media (max-width: $screen-sm)
        font-size: 4rem
        line-height: 5rem

.icon
    display: inline-block
    width: 2rem
    height: 3.5rem
    @media (max-width: $screen-sm)
        width: 60%
        height: auto

.iconEffects:hover
    -webkit-transform: scale(1.01)
    -ms-transform: scale(1.01)
    transform: scale(1.01)
    box-shadow: 0px 5px 9px #adc8d8
    filter: saturate(150%)
    cursor: pointer
.avatar_list__item > .iconChooseEffect
    border: none
.iconChooseEffect
    -webkit-transform: scale(1.01)
    -ms-transform: scale(1.01)
    transform: scale(1.01)
    box-shadow: 0px 5px 9px #adc8d8
    filter: saturate(150%)
    cursor: pointer
    &:hover
        transform: rotate(90deg)
        -ms-transform: rotate(360deg)
        -webkit-transform: rotate(360deg)
.image_crop_button
    margin: 1.5rem 0
@media (max-width: $screen-sm)
    .img_selector
        padding-bottom: 12rem
        margin-top: 6rem !important
        margin-right: 0
        margin-left: 45%
        width: 35rem !important
    .text_selector
        text-align: center
        font-size: 8rem !important
        background-color: white !important
    .button_selector
        display: flex !important
        justify-content: center !important
        align-items: center !important
        background-color: #003056 !important
        width: 10rem !important
    .arrow_selector
        color: white
        font-size: 8rem !important
    .left_selector
        border-radius: 8px 0 0 8px !important
    .right_selector
        border-radius: 0 8px 8px 0 !important
    .lock_selector
        background-color: #e0eef7 !important
        border: none !important
