@import ../../../../styles/Variables.module

@mixin mobile-menu-item
    font-size: 3rem
    line-height: 4rem
    font-weight: 700

.dashboard
    font-family: "Lato"
    margin: 0 15rem
    padding: 2rem 0
    display: flex
    flex-direction: column
    align-items: center
    &__title
        font-size: 1.778rem
        line-height: 3rem
        color: $contrast-color
        font-weight: 700
        text-align: center
    .links
        display: flex
        align-self: start
        gap: 3rem
        margin: 2rem 0 1.5rem
        &__item
            font-size: 0.9rem
            line-height: 1.5rem
            color: $contrast-color
            font-weight: 700
            text-transform: uppercase
            &:nth-child(1)
                padding-left: 0.8rem
                position: relative
                &::before
                    position: absolute
                    top: 0.3rem
                    left: -0.5rem
                    content: ''
                    width: 1rem
                    height: 1.2rem
                    background-size: 50%
                    background-repeat: no-repeat
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAATCAMAAACeNWzcAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAH5QTFRFAAAA4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/4TM/cURxcQAAACp0Uk5TABXa1Qre/wXSpsu2vb60zQab4AyN0xczlRGjrQK6x8S4Aa7Znw/lG93Ya2OnJgAAAGpJREFUeJw1zlkOggAMRdEKeBVQBhlEQRSZdP8bhNDSr5M0ebkiegfHNXlHUJ3O4CsDCFUXuKoiiFU+uMmmFG7ZphyKUt/A3cYrCB7mJziJuV63GvMLWqO84WPs1prv3tjDYB6nvVdk/v0XWnoEbajGyE8AAAAASUVORK5CYII=')
            &_button
                font-size: 0.9rem
                line-height: 1.5rem
                color: $contrast-color
                font-weight: 700
                text-transform: uppercase
                text-decoration: underline
                border: none
                outline: none
                background-color: inherit
                &:focus
                    // outline: 0.1rem solid #0c4c81
                    outline-offset: -0.3rem
    .nav
        position: relative
        align-self: start
        display: flex
        margin-left: -1.4rem
        margin-bottom: -0.2rem
        &__item
            position: relative
            &__image
                height: 1.3rem
                width: auto
                padding-right: 0.5rem
            &__label
                margin: 0
                font-size: 0.9rem
                line-height: 2rem
                color: $contrast-color
                font-weight: 700
                padding: 0.5rem 1.2rem
                &:focus
                    outline: 0.1rem solid #0c4c81
                    outline-offset: -0.3rem
            &__input
                -moz-appearance: none
                -webkit-appearance: none
                appearance: none
                &:checked+.links__item_button
                    color: $blue-color
                    text-decoration: none
                &:checked+.nav__item__label
                    z-index: 2
                    border-radius: 0.33rem 0.33rem 0 0
                    box-shadow: -0.1rem -0.4rem 0.651rem #c6dceabf
                    border-bottom: none
                    background-color: #fff
                    color: $blue-color
                    &::before
                        content: ""
    .flex_container
        display: flex
        gap: 2rem
        align-items: flex-start
    .container
        background-color: #fff
        box-shadow: 0 0.1563rem 0.651rem #c6dceabf
        border-radius: 0.33rem
        width: 57rem
    .logo
        position: relative
        &__picture
            width: 13rem
            height: 55.5rem
        &__shield
            position: absolute
            width: 13rem
            height: auto
            left: 0.05rem
            z-index: 3
        &__name
            position: absolute
            top: 20rem
            right: -20.2rem
            z-index: 2
            font-size: 6rem
            color: $blue-color
            text-transform: uppercase
            font-weight: 900
            transform: rotate(-90deg)
            font-family: "Lato"
            height: 13rem
            width: 49rem
            white-space: nowrap
            overflow: hidden
        &__container
            position: absolute
            top: 3.75rem
            left: 3rem
            z-index: 4
            display: flex
            justify-content: center
            align-items: center
            width: 7rem
            height: 7rem
        &__team
            min-width: 3.5rem
            max-width: 5rem
            height: auto
            max-height: 6rem
            margin: 0 auto
            border-radius: 25%

@media (max-width: $screen-sm)
    .dashboard
        margin: 0 2rem 8rem
        &__title
            font-size: 5rem
            line-height: 8rem
        .links
            align-self: center
            width: 95%
            justify-content: space-between
            align-items: center
            &__item
                font-size: 2.8rem
                line-height: 3.5rem
                &:nth-child(1)
                    padding-left: 2rem
                    &::before
                        top: 0.3rem
                        left: -0.5rem
                        width: 3rem
                        height: 3rem
        .mobile_menu
            position: absolute
            border: 0.01rem solid #b9cfdd
            display: flex
            flex-direction: column
            align-items: flex-start
            background-color: #ffffff
            border-radius: 0 0 0.66rem 0.66rem
            width: 100%
            top: -2.2rem
        .nav__item
            &__label
                @include mobile-menu-item
            &__image
                margin: 0 1.5rem
                height: 3rem
                width: auto
            &__input:checked+.nav__item__label
                box-shadow: none
        .flex_container
            width: 95%
        .container
            width: 100%
    .mobile_selector
        display: flex
        margin: 3rem 0 2rem
        width: 95%
        height: 8rem
        padding-left: 1.5rem
        justify-content: space-between
        align-items: center
        @include mobile-menu-item
        color: $blue-color
        border: 0.01rem solid #b9cfdd
        border-radius: 0.66rem
        background-color: #ffffff
        &__image
            width: 5rem
            height: auto
            padding-right: 1.5rem
    .mobile_menu_container
        position: relative
        width: 95%
    .upgrade_mobile_menu
        position: absolute
        bottom: -2.5rem
        &::before
            content: ''
            position: absolute
            top: -2.4rem
            right: -5rem
            width: 0
            height: 0
            border: solid transparent
            border-left: 2rem solid transparent
            border-right: 2rem solid transparent
            border-bottom: 4rem solid #fff
            pointer-events: none
            z-index: 3
        &__label
            position: absolute
            background-color: #fff
            border-radius: 0.66rem
            box-shadow: 0 0.1563rem 0.651rem #c6dceabf
            font-size: 3rem
            line-height: 8rem
            color: $contrast-color
            text-transform: uppercase
            font-weight: 700
            width: 35rem
            text-align: right
            padding: 1rem 2rem
            top: 0
            left: -30rem
