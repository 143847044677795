.spinner
  position: absolute
  top: 47%
  left: 47%
  width: 6rem
  height: 6rem
  
.backGroungForVideo
  opacity: 0.6
  background: #61605b
  padding: 5px 
  z-index: 2 
  display: block
  max-width: 100%
  margin: 0 auto
  width: 100vw
  height: 253vh
  position: absolute

