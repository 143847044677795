@import '../../styles/Variables.module'

@mixin red-button
    display: flex
    justify-content: center
    align-items: center
    line-height: 3rem
    color: #ffffff
    font-weight: 700
    border-radius: 6rem
    background-color: $contrast-color
    border: none
    outline: none
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none
        color: #ffffff
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
        color: #ffffff

@mixin text
    font-size: 1rem
    font-weight: 400
    color: $blue-color

@mixin sm-text
    font-size: 4rem
    line-height: 5rem

.container
    display: flex
    justify-content: center
    margin: 0 auto
    gap: 2rem
    padding: 2rem 0 5rem
    &__title
        font-size: 3rem
        line-height: 4.5rem
        color: $blue-color
        font-weight: 400
        text-align: center
        padding-top: 2rem
    &__description
        color: $blue-color
        font-size: 1.25rem
        line-height: 2.625rem
        font-weight: 400
        text-align: center
.banner
    background-color: #fff
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    width: 26rem
    border-radius: 0.33rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    &__title
        padding: 2rem 2rem 0.5rem
        align-self: start
        font-size: 2.2rem
        line-height: 4rem
        color: $blue-color
        font-weight: 400
.promo_list
    display: flex
    flex-direction: column
    padding-bottom: 1rem
    &__item
        @include text
        line-height: 2.5rem
        position: relative
        &::before
            content: ''
            background: no-repeat url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAMAAABlXnzoAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAG9QTFRFAAAA3Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc9YcwlYAAAACV0Uk5TAAOSvwv/xVLEM/IgH2378O791vT83A8lCWn6yAYn6stKygKZxkLU8zcAAABYSURBVHicTY5HDoAwEMR2IUPvoYcO/38jUiiJb9ZI1hBp2HHJQgCe7T4Q2B5GehexJuE0Q8CU46UogYrkp7IGGmq7h34YoaY/NS9YTXgDFNs39sOMfF7abjHeBGHgMwM3AAAAAElFTkSuQmCC') 50% / 90%
            position: absolute
            left: -1.75rem
            top: 0.75rem
            width: 1rem
            height: 1rem
.last_promo
    padding: 1rem 2rem
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    border-radius: 0 0 0.33rem 0.33rem
    border-top: 0.1rem solid #bfcdd9
    margin-bottom: 7rem
    &__title
        align-self: start
        font-size: 1.375rem
        line-height: 4rem
        color: $blue-color
        font-weight: 400
    &__text
        @include text
.button
    @include red-button
    padding: 0.5rem 4rem
    font-size: 1.2rem
    margin-top: 2rem

@media (max-width: $screen-sm)
    .container
        flex-direction: column
        justify-content: center
        align-items: center
        margin-bottom: 10rem
        &__title
            font-size: 6rem
            line-height: 11rem
        &__description
            padding: 0 5rem 5rem
            @include sm-text
    .banner
        width: 95%
        border-radius: 1rem
        &__title
            font-size: 6rem
            line-height: 11rem
            align-self: center
    .promo
        padding: 5rem 4rem 2rem
        @include sm-text
        &_list
            margin-right: 8rem
            margin-bottom: 4rem
            &__item
                font-size: 3.8rem
                line-height: 8rem
                &::before
                    left: -6rem
                    top: 1.5rem
                    width: 4rem
                    height: 4rem
    .last_promo
        @include sm-text
        padding: 2rem 4rem
        border-radius: 0 0 1rem 1rem
        &__title
            font-size: 6rem
            line-height: 11rem
        &__text
            @include sm-text
    .button
        margin-top: 6rem
        @include sm-text
        padding: 3rem 10rem
