@import ../../../../../styles/Variables.module

.invite_popup
    position: fixed
    z-index: 40
    font-family: "Lato"
    background-color: #fff
    border-radius: 0.33rem
    top: 20%
    max-width: 35rem
    left: calc((100% - 34rem) / 2)
    &__title
        font-size: 1.5rem
        line-height: 4rem
        color: $blue-color
        text-align: center
        border-bottom: 0.1rem solid #bfcdd9
    .buttons
        display: flex
        justify-content: space-between
        align-items: center
        gap: 2rem
    .container
        padding: 2rem

@media (max-width: $screen-sm)
    .invite_popup
        &__title
            font-size: 3rem
            line-height: 6rem
