@import ../../../../../../../styles/Variables.module

.table_buttons
    display: flex
    gap: 1rem
.gray_button
    font-size: 1.0417rem
    line-height: 1.7708rem
    font-weight: 700
    border-radius: 2rem
    padding: 0 1.3rem
    cursor: pointer
    color: $light-blue-color
    border: thin solid $light-blue-color
    background-color: transparent
    transition: .2s linear
    &__image
        width: 0.8rem
        height: 0.8rem
        margin: 0 0.5rem 0.25rem 0
        @media (max-width: $screen-sm)
            display: none
    &:hover
        background-color: #f1f0f0
.red_button
    display: flex
    justify-content: center
    align-items: center
    color: #ffffff
    font-weight: 700
    border-radius: 6rem
    background-color: $contrast-color
    padding: .7039rem 1.3rem
    border: none
    outline: none
    &__image
        width: 1.1rem
        height: 1rem
        margin-right: 0.5rem
        @media (max-width: $screen-sm)
            display: none
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: $hover-blue-color
        text-decoration: none
        color: #ffffff
    &:focus
        outline: 0.01rem solid $hover-blue-color
        outline-offset: 0.3rem
        color: #ffffff

@media (max-width: $screen-sm)
    .gray_button,
    .red_button
        font-size: 3rem
        line-height: 7rem
        border-radius: 6rem
        padding: 0.5rem 3rem
