@import ../../../styles/Variables.module

.popup_wrapper
    position: fixed
    z-index: 40
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0.9
    background-color: $blue-color
