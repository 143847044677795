.modal
  margin: 15vmin auto !important
  max-width: 35% !important
  .header
    font-size: 1.5rem
    color: #003056

  h4
    font-size: 2rem
    line-height: 1.7708rem
    font-weight: 400
    color: #003056

  form
    h5
      font-size: 1.25rem
      line-height: 1.7708rem
      font-weight: 500
      color: #003056

  .acceptBtn
    width: 9rem
    height: 3rem
    border-radius: 2rem

  .cancelBtn
    width: 9rem
    height: 3rem
    border-radius: 2rem

  .formStyle
    span
      font-size: 8.25rem


  .errStep2
    color: red

  // STYLES FOR RADIOBUTTON
  .radioBtnContainer
    display: block
    position: relative
    padding-left: 35px
    margin-bottom: 12px
    cursor: pointer
    font-size: 1.2rem
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  input
    position: absolute
    opacity: 0
    cursor: pointer
    background-color: #eee

.checkmark
  position: absolute
  top: 0
  left: 0
  height: 25px
  width: 25px
  background-color: #eee
  border-radius: 50%

.radioBtnContainer
  &:hover input ~ .checkmark
    background-color: #ccc

  input:checked ~ .checkmark
    background-color: #2196F3

.checkmark:after
  content: ""
  position: absolute
  display: none

.radioBtnContainer
  input:checked ~ .checkmark:after
    display: block

  .checkmark:after
    top: 9px
    left: 9px
    width: 8px
    height: 8px
    border-radius: 50%
    background-color: white

.otherTextField
  min-height: 5rem
  width: 100%
