$screen-sm: 576px
$screen-sm-min: 577px


@media (max-width: $screen-sm)
  .mobile_container
    position: absolute
    top: 0
    background-color: #1b4470
    opacity: .98
    z-index: 40
    width: 100vw
    height: 140vh
.mobile_links
  display: none
  @media(max-width: $screen-sm)
    display: flex
    padding: 1.5rem 3rem
    font-family: 'Lato'
    margin-top: 30rem
    flex-direction: column
    width: 10rem
    line-height: 10rem
    color: white
    img
      position: absolute
      top: 2.8%
      width: 6.5rem
      height: 6.5rem
      right: 3rem
    .li
      margin-top: 10rem
      font-size: 7rem
.home_links
  display: none
  @media (max-width: $screen-sm)
    display: flex
    flex-direction: row
    font-size: 6rem
    margin-top: 50%
    justify-content: center
    .visit_btn
      display: flex
      justify-content: center
      align-items: center
      background-color: #dd373e
      font-weight: 400
      font-size: 4rem
      padding: 2rem 12rem
      border-radius: 50rem
      border: .05rem solid #dd373e
      color: #ffffff
      font-family: 'Lato', serif
      margin-right: 3rem
    .signIn
      @extend .visit_btn
      background-color: transparent
      color: #ffffff
