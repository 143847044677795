@import '../../../styles/Button.module'

@mixin text
    font-size: 1.125rem
    line-height: 2rem

@mixin sm-text
    font-size: 4rem
    line-height: 8rem

@mixin input-sm-size
    width: 100%
    height: 12rem
    border-radius: 1rem

@mixin button-sm-size
    border-radius: 6rem
    width: 43rem
    height: 11.5rem

.payment_form
    width: 40rem
    margin: 0.5rem
    font-family: 'Lato', serif
    color: $blue-color
    &__title
        margin-bottom: 1.5rem
        font-size: 1.555rem
        color: $blue-color
        @media (max-width: $screen-sm)
            font-size: 5rem
.container
    display: flex
    flex-wrap: wrap
    &__description
        @include text
        @media (max-width: $screen-sm)
            font-size: 3.5rem
            line-height: 7rem

.pic_cards
    width: 17rem
    padding-left: 1rem
    @media (max-width: $screen-sm)
        width: 70%
        padding: 0

.card_details_form
    display: flex
    flex-wrap: wrap
    margin-top: 1rem
    justify-content: space-between
    @media (max-width: $screen-sm)
        width: 100%
.card_label
    display: flex
    flex-direction: column
    align-items: flex-start
    @include text
    font-weight: 600
    @media (max-width: $screen-sm)
        @include sm-text
        width: 48%
        margin-top: 3rem

.card_label:first-child
    position: relative
    &::after
        content: ''
        position: absolute
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAXCAMAAAC27AbQAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAAuc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/dnaJ+SwAAADV0Uk5TAIL/hIeeOCcsOZyTN5SDR6+2vS0vMLqamZaVqY2Iiae+vLkICbcEBbtoU1Zlw3AqLnLCtbRnWWtlAAAAhklEQVR4nOXSRxaCAAxF0ee3gF3BLij2Lrb9r02YQ3DuG99BkhOgJKsySRVVa46bnVNvqAkttbHqqEtPJgH18eS7g/zcoUaMzYnTJj+ZaaGZMVcQLvILAy2JCvdasdbGJFvt/tpE2pvmkNzQK7zzkZPOJrnoCjfd/fiRXew/9Url2/yMD3wBaw8V0JYtoWgAAAAASUVORK5CYII=')
        background-size: 90% 90%
        top: 3.3rem
        left: 22rem
        background-repeat: no-repeat
        width: 2rem
        height: 1.5rem
    @media (max-width: $screen-sm)
        display: flex
        justify-content: flex-start
        margin-left: 0
        width: 100%
        &::after
            top: 12rem
            left: 78rem
            width: 7rem
            height: 5.5rem

.card_number_input
    width: 25rem
    height: 3.0rem
    border-radius: .33rem
    border: .0521rem solid #b9cfdd
    padding: 1rem 1rem 2rem 0.4rem
    margin: .5rem 0
    @media (max-width: $screen-sm)
        padding: 3.5rem
        @include input-sm-size
        margin-bottom: 1.5rem
.card_expiry_input
    width: 8rem
    height: 3.0rem
    border-radius: .33rem
    border: .0521rem solid #b9cfdd
    padding: 1rem 1rem 2rem 0.4rem
    margin: .5rem 0
    @media (max-width: $screen-sm)
        @include input-sm-size
        margin-right: 4rem
        padding: 3.5rem
.card_cvc_input
    height: 3.0rem
    border-radius: .33rem
    border: .0521rem solid #b9cfdd
    padding: 1rem 1rem 2rem 0.4rem
    margin: .5rem 0
    width: 5.5rem
    @media (max-width: $screen-sm)
        @include input-sm-size
        padding: 3.5rem
        margin-left: 0
.container_coupon
    display: flex
    justify-content: flex-start
    align-items: flex-end
    @media (max-width: $screen-sm)
        width: 100%
        margin-top: 3rem

.discount_label
    display: flex
    flex-direction: column
    justify-content: flex-start
    margin: 0
    @include text
    font-weight: 600
    padding-top: 1rem
    @media (max-width: $screen-sm)
        display: flex
        width: 50%
        @include sm-text
    input[type="text"]
        margin-right: 2.5rem
        width: 25rem
        background-color: #ffffff
        padding: 1rem 1rem
        @extend .card_expiry_input
        @media (max-width: $screen-sm)
            margin-right: 4rem
            width: 95%
            font-size: 3.3rem
            padding: 3.5rem
.grey_button
    @include button
    display: flex
    justify-content: center
    align-items: center
    width: 10rem
    height: 100%
    color: #ffffff
    font-weight: 700
    line-height: 1.8889rem
    background-color: $light-blue-color
    border: none
    outline: none
    margin-bottom: 0.5rem
    @media (max-width: $screen-sm)
        @include button-sm-size
        @include sm-text

.buttons
    width: 30rem
    margin-top: 2rem
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    @media (max-width: $screen-sm)
        margin-top: 7rem
        width: 100%
.divTotal
    position: relative
    display: flex
    padding: 0.4rem
    background-color: #f0f8fd
    margin: 1rem 0 2rem
    width: 100%
    border: 0.1rem solid #b9cfdd
    border-radius: 0.33rem
    @media (max-width: $screen-sm)
        width: 100%
        padding: 0
        border-radius: 1rem
        margin-top: 6rem
.totalPrice
    display: flex
    width: 98%
    padding: 0.5rem 1rem
    font-weight: 700
    justify-content: space-between
    @media (max-width: $screen-sm)
        font-size: 4rem
        @include input-sm-size
        align-items: center
        padding: 0 3rem
.subPrice
    @extend .totalPrice

.subPrice_firstspan
    width: 10rem
    @media (max-width: $screen-sm)
        width: auto

.totalPrice_hr
    width: 15rem
    position: absolute
    height: 1px
    top: -.1rem
    background-color: red
    @media (max-width: $screen-sm)
        top: 1rem

.bordered_button
    @include button
    display: flex
    justify-content: center
    align-items: center
    width: 12rem
    height: 100%
    color: $light-blue-color
    font-weight: 700
    background-color: rgba(221,55,62,0)
    border: 0.1rem solid $light-blue-color
    outline: none
    &:focus
        color: $light-blue-color
    @media (max-width: $screen-sm)
        @include sm-text
        @include button-sm-size

.buttonDefault
    @include button
    display: flex
    justify-content: center
    align-items: center
    width: 12rem
    height: 100%
    color: #ffffff
    font-weight: 700
    background-color: #dd373e
    border: none
    border-radius: 3.7625rem
    @media (max-width: $screen-sm)
        @include sm-text
        @include button-sm-size

@media (max-width: $screen-sm)
    .form_mobile
        display: flex
        flex-direction: column
        width: 98%
        padding-top: 3rem
