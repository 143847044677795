$screen-sm: 576px
.grid_cards
  text-align: center
  padding: 1.5rem 0
  .container_title
    position: relative
    display: flex
    justify-content: center
    width: 57.688rem
    margin: 0 auto 1rem
    color: #003056
    font-family: Lato, serif
    font-size: 2rem
    font-weight: 400
    line-height: 2.625rem
  .title
    .h2
      color: #dd373e
      font-size: 2.5rem
      line-height: 2.7083rem
      font-weight: 400
    .p
      color: #003056
      font-size: .9375rem
      line-height: 1.25rem
      font-weight: 400
      margin: .7rem 0 2rem

  .cardContainer, .cardContainerIExplorer
    display: grid
    justify-content: center
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 1rem
    color: #ffffff
    margin: 0 20rem
    .link
      text-decoration: none
    .card
      position: relative
      width: 22.5474rem
      height: 14.15rem
      padding: 0rem 2rem 0rem 2.0rem
      display: flex
      flex-direction: column
      justify-content: space-evenly
      cursor: pointer
      transition: .2s linear
      border-radius: 1rem
      &:hover
        transform: scale(1.01,1.01)
      .span
        font-size: .9375rem
        line-height: 1.25rem
        font-weight: 400
        color: #fded21
      .h3
        width: 50%
        color: #dd373e
        font-size: 1.05rem
        font-weight: bold
        text-transform: uppercase
        display: flex
        .container_star
          width: 100%
          position: relative
          display: flex
          justify-content: space-between
          &__img
            position: relative
            top: 2.05rem
            height: .6rem
        .p_title
          width: 9.125rem
          color: #ffffff
        .p_subtitle
          max-width: 20.375rem
          color: #dd373e
          font-size: 1.05rem
          font-family: Lato
          font-weight: bold
          margin: 0 auto
      .h5
        font-size: 1.0417rem
        line-height: 2.7083rem
        font-weight: 400
      .p
        font-size: .8333rem
        line-height: 1.0417rem
        font-weight: 400
  .cardContainerIExplorer
    display: flex
    flex-wrap: wrap
    margin: 0 auto
    max-width: 1300px
    justify-content: space-between
    .card
      padding: 5.5rem 2rem 0rem 2.0rem

@media (max-width: $screen-sm)
  .grid_cards
    .container_title
      font-size: 5rem
      line-height: 5.5rem
      padding-top: 3.5rem
    .cardContainer
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 2rem
      .card
        width: 46rem
        height: 32rem
        .h3
          font-size: 2.7rem
          height: 9.5rem
          .container_star
            width: 100%
            position: relative
            display: flex
            justify-content: space-between
            &__img
              height: 1.3rem
              position: relative
              top: 6rem
          .p_title
            font-size: 3.2rem
            width: 22.125rem
          .p_subtitle
            width: 22.375rem
            font-size: 2.57rem
            font-weight: bold
//From videoCategories
.desc
  &_text
    font-family: Lato
    padding: 1rem 0rem 0rem 0rem
    color: #000000
    font-size: 1.157rem
  &_btn_videos
    margin-top: .5rem
    margin-bottom: 3.57rem
    width: 9rem
    height: 3.1rem
    background-color: #dd373e
    color: #fff
    border-radius: 2rem
    border: none
    text-align: center
@media (max-width: $screen-sm)
  .desc
    &_text
      font-size: 4.3rem
    &_btn_videos
      font-size: 4.2rem
      width: 23rem
      height: 10.5rem
      border-radius: 4.5rem

.container_subtitle
  padding: 1.5rem 0rem 2rem 0rem
  font-size: 1.5rem

.container_names
  position: relative
  display: flex
  flex-direction: column

.first_line
  width: 18.473rem
  border-top: .105rem solid #BDC6D7
  position: absolute
  top: 2.55rem
  left: 19.89rem
.second_line
  width: 18.473rem
  border-top: .105rem solid #BDC6D7
  position: absolute
  top: 2.55rem
  right: 19.89rem
@media (max-width: $screen-sm)
  .container_subtitle
    font-size: 4.5rem
  .first_line
    width: 17rem
    left: 6rem
    top: 4.9rem
  .second_line
    width: 17rem
    right: 6rem
    top: 4.9rem
