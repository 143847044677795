@import '../../../../styles/Variables.module'

.success_popup
    position: fixed
    z-index: 40
    top: 20%
    left: calc((100% - 36rem) /2)
    min-width: 36rem
    display: flex
    flex-direction: column
    align-items: center
    font-family: "Lato"
    border-radius: 0.33rem
    background-color: #FFF
    padding: 4rem 4rem 2rem
    &__title
        margin: 2rem 0 1rem
        font-size: 2rem
        color: $contrast-color
        font-weight: 700
    &__text
        font-size: 1.22rem
        line-height: 1.5rem
        color: $blue-color
        padding-bottom: 4rem

@media (max-width: $screen-sm)
    .success_popup
        min-width: 80rem
        min-height: 40rem
        left: calc((100% - 80rem) /2)
        &__title
            margin: 2rem 0 1rem
            font-size: 5rem
            line-height: 6rem
        &__text
            font-size: 3rem
            line-height: 4rem
