@import ../../../../styles/Button.module.sass

.button
    padding-left: 3.5rem
    color: $blue-color
    border: thin solid $blue-color
    background-color: #fff

.button__text
    position: relative
    &::before
        content: ''
        background: no-repeat url('../../../../asset/trial.webp') 50% / 90%
        position: absolute
        left: -2.5rem
        top: -0.5rem
        width: 2rem
        height: 2.5rem

@media (max-width: $screen-sm)
    .button
        padding: 0
        font-weight: normal
        background: #ffffff
        border: none
        text-align: center
        color: #78a3be
        font-size: 4rem
        line-height: 6rem
        &:hover,
        &:active
            color: #78a3be
            transform: scale(1.01)
            text-decoration: none
        &:focus
            color: #78a3be
            outline: 0.01rem solid $hover-blue-color
            outline-offset: 0.3rem
    .button__text
        &::before
            display: none
