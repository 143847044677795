$font-size-input: .9375rem
$screen-sm: 576px
.main
  display: flex
  justify-content: center
  margin: 0rem 20rem 0rem 20rem
  padding-bottom: 11.78rem
  @media (max-width: $screen-sm)
    margin: 0 auto
    justify-content: center

  .section
    display: flex
    flex-direction: column
    align-items: center
    @media (max-width: $screen-sm)
      display: none
    .h2
      padding-top: 5rem
      padding-bottom: 1.84rem
      font-size: 1.4583rem
      line-height: 1.25rem
      font-weight: 400
      font-style: normal
      color: #dd373e
      .strong
        font-weight: 800
    .p
      padding-top: 1.89rem
      font-size: .9375rem
      line-height: 1.25rem
      font-weight: 400
      color: #78a3be
      a
        color: #dd373e
        &:hover
          text-decoration: underline
    .form
      width: 25.26rem
      height: 24.63rem
      background: #ffffff
      padding: .6rem
      border-radius: .3125rem
      box-shadow: 0 .1563rem .651rem #c6dceabf
      .linkCont
        justify-content: space-between
      .btnCont
        justify-content: flex-end
      .linkCont, .btnCont
        display: flex
        margin: .6rem
        .btn
          margin-top: 2.10rem
          color: #ffffff
          font-size: 1.0417rem
          line-height: 1.7708rem
          font-weight: 700
          background-color: #dd373e
          padding: .7039rem 3.1413rem
          border-radius: 1.5625rem
          border: none
          outline: none
          cursor: pointer
        .fpLink
          display: flex
          align-items: center
          color: #78a3be
          font-size: .9375rem
          line-height: 1.7708rem
          font-weight: 700
          &:hover
            text-decoration: underline
      .div
        display: flex
        flex-direction: column
        margin: 1rem .6rem
        .label
          font-size: .9375rem
          line-height: 1.7708rem
          font-weight: 700
          margin: .3rem 0
          color: #003056
        .input
          height: 3.1rem
          font-size: $font-size-input
          line-height: 1.7708rem
          border-radius: .3125rem
          border: .0521rem solid #b9cfdd
          padding: 0 0.2rem

        .input::placeholder
          color: #b9cfdd
          padding-left: $font-size-input / 2
        .error
          border-color: #ff0000ee
    .span
      text-align: center
      color: #ff5100
      border-radius: .3rem
      border: 1px #ff5100ee
.label__signin
  margin-top: 1.84rem
  font-weight: 700
  color: #003056
.label_user
  margin-bottom: 1rem
  $font-size-input: .9375rem
  font-weight: 700
  color: #003056
.label_password
  margin-top: 2rem
  $font-size-input: .9375rem
  font-weight: 700
  color: #003056

