.account_manage
  padding: 2rem 6rem
  .header
    display: grid
    grid-template-columns: repeat(3, 1fr)
    align-items: center
    background: #ffffff
    padding: 2rem 1.5789rem
    margin: 0 3rem
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    a
      font-size: .9375rem
      line-height: 1.7708rem
      font-weight: 400
      color: #003056
    h2
      font-size: 1.6667rem
      line-height: 1.25rem
      font-weight: 300
      color: #dd373e
      text-align: center
  .account_manage__section
    display: grid
    grid-template-columns: 3fr 2fr
    margin: 0 5rem
    form
      margin: 3rem
      h4
        font-size: 1.25rem
        line-height: 2.1875rem
        font-weight: 800
        color: #003056
        margin-bottom: 1rem
      .row
        display: flex
        width: 100%
        margin: .5rem 0
      .col
        display: flex
        flex-direction: column
        // width: 100%
        .email
          width: 36.3rem
        .required
          border-color: #dd373e
          box-shadow: 0 0 .3rem #dd373e
        .invalidEmail
          border-color: #dd373e
          box-shadow: 0 0 .3rem #dd373e
      label
        font-size: .9375rem
        line-height: 1.7708rem
        font-weight: 700
        color: #003056
      input
        font-size: .9375rem
        line-height: 1.7708rem
        font-weight: 400
        color: #78a3be
        border-radius: .3125rem
        border: thin solid #b9cfdd
        padding: .6512rem 1rem
        margin: 1rem 3rem 1rem 0

      button
        font-size: 1.0417rem
        line-height: 1.7708rem
        font-weight: 700
        color: #ffffff
        background-color: #78a3be
        padding: .6778rem 1.491rem
        margin: 1rem 3rem 1rem 0
        border-radius: 2rem
        border: none
        outline: none
        cursor: pointer
      .changePass
        width: 120%
      .submit
        width: 100%
        background-color: #dd373e
      .bordered
        width: 100%
        color: #78a3be
        background-color: transparent
        border: thin solid #78a3be
    .card
      margin: 3rem
      display: flex
      flex-direction: column
      justify-content: space-between
      background: #ffffff
      padding: 2rem 1.5789rem
      border-radius: .3125rem
      box-shadow: 0 .1563rem .651rem #c6dceabf
      .cardTitle
        margin-bottom: 1rem
        h4
          font-size: 1.4583rem
          line-height: 1.7708rem
          font-weight: 400
          color: #003056
        p
          font-size: .7292rem
          font-weight: 400
          color: #78a3be
      .cardFoot
        h5
          font-size: .9375rem
          line-height: 1.7708rem
          font-weight: 700
          color: #003056
        p
          font-size: .9375rem
          color: #78a3be
      a,
      button
        color: blue
        margin-bottom: 1.5rem
        &:hover
          text-decoration: underline
        &:last-child
          margin-bottom: 0
      button
        background-color: transparent
        border: none
        text-align: left
        padding: 0
  .emailField
    width: 16rem
  .emailFieldError
    width: 16rem
    border-color: #dd373e
    box-shadow: 0 0 .3rem #dd373e

  .modal
    // margin: 15vmin auto !important
    background-color: black !important
  .emailInput
    color: "red"
  .changeEmail
    width: 140%
  .emailText
    margin-bottom: 0px
.submitBtn
  width: 9rem 
  height: 3rem 
  border-radius: 2rem 
  color: #fff
  background-color: #007bff
  border-color: #007bff

.container_coupon
  display: flex
  height: 3rem
  margin-top: 1rem
  margin-bottom: 2rem
  align-items: center
.buttonDefault
  background: #78a3be




