@import ../../../../styles/Variables.module

.report_row
    font-family: "Lato"
    width: 60rem
    border-radius: 6px
    box-shadow: 0px 3px 12.5px rgba(198,220,234,0.75)
    margin: 0 0 6rem 2rem
    &__item
        display: flex
        justify-content: space-evenly
        align-items: center
        font-size: 1.2rem
        line-height: 4rem
        color: $blue-color
        font-weight: 400
        background-color: #ffffff
        border-bottom: 0.1rem solid #e6f1f7
        &:nth-child(3n)
            border-bottom: 0.1rem solid #9da5b5
        &:last-child
            border-bottom: none
    &__data
        display: flex
        justify-content: center
        width: 15rem
        &:first-child
            justify-content: start
            padding-left: 5rem

@media (max-width: $screen-sm)
    .report_row
        width: 91rem
        border-radius: 6px
        box-shadow: 0px 3px 12.5px rgba(198,220,234,0.75)
        margin: 0 0 8rem
        &__item
            display: flex
            justify-content: space-evenly
            align-items: center
            font-size: 3rem
            line-height: 9rem
            border-width: 0.2rem
            font-family: "Lato"
        &__data
            display: flex
            justify-content: center
            width: 25rem
            &:first-child
                justify-content: start
                padding-left: 5rem
