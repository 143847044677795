.container
  width: 30.3rem
  margin: 0 2rem 2rem 20rem
  .flex
    display: flex
  .form
    padding-top: 1.5rem
    h3
      color: #003056
      font-size: 3rem
    p
      color: #78a3be
    .row
      display: flex
      justify-content: space-between
    .col
      display: flex
      flex-direction: column
      width: 100%
      &__half
        width: 100%
        &_leftP
          padding-left: 1rem
          select
            flex-direction: column
            justify-content: center
            width: 11.85rem
            margin-bottom: .6rem
            padding: .10rem
            height: 2.15rem
            font-size: .8rem
            line-height: 1.7708rem
            font-weight: 400
            color: #000000
            border-radius: .3125rem
            border: thin solid #b9cfdd
            background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
            background-size: 1.3rem
            -webkit-appearance: none
            background-position-x: 11.8rem
        &_rightP
          padding-right: 1rem
          .userNameErr
            color: red
      label
        font-size: .9375rem
        font-weight: 400
        margin-bottom: .5rem
      input, select
        line-height: 1.7708rem
        font-weight: 400
        border-radius: .3125rem
        border: .0521rem solid #b9cfdd
        padding-left: 0.2rem
        padding-right: 0.2rem
        width: 100%
      .Required
        border-color: #dd373e
        box-shadow: 0 0 .3rem #dd373e
  .iconContainer
    img
      display: inline-block
      margin: 2rem .2rem 2rem .2rem
      width: 5rem
      height: 5rem
  .submitButton
    outline: none
    margin-left: 18rem
    width: 11.5rem
    height: 3.1rem
    background-color: #dd373e
    color: #fff
    border-radius: 2rem
    border: none
    text-align: center
  .spinner
    margin-top: 1rem
// .iconEffects
//    &:hover
//     text-decoration: underline

.iconEffects:hover
  -webkit-transform: scale(1.1)
  -ms-transform: scale(1.1)
  transform: scale(1.1)
  box-shadow: 0 0 .15rem .2rem #bebcbc
  transition: .2s
  cursor: pointer
  

.iconChoiseEffect
  -webkit-transform: scale(1.1)
  -ms-transform: scale(1.1)
  transform: scale(1.1)
  box-shadow: 0 0 .15rem .2rem #bebcbc
  cursor: pointer
  width: 200px
  height: 200px
  transition: transform .8s
  -webkit-transition: -webkit-transform .8s ease-in-out
  -ms-transition: -ms-transform .8s ease-in-out
  &:hover
    transform: rotate(90deg)
    -ms-transform: rotate(360deg)
    -webkit-transform: rotate(360deg)
  







