@import ../../../../styles/Variables.module

.selector_container
    padding: 1.5rem 0 1rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
.homework
    padding: 1rem
    &__title
        font-size: 1.778rem
        line-height: 3rem
        color: $blue-color
        font-weight: 700
        font-family: "Lato"
        margin: 1rem 0 1rem
.title_buttons_container
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap

@media (max-width: $screen-sm)
    .selector_container
        padding: 1rem 0 2rem
    .homework
        padding: 3rem 2rem
        &__title
            font-size: 4.5rem
            line-height: 6rem
            order: 2
    .title_buttons_container
        margin: 2rem 0

.box
  align-items: center
  padding: 2.5rem
    
.column30
  margin-top: 2.9rem
  padding: 1rem
  padding-right: 2rem
  background-color: white
  border-radius: 5px
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

.videoCluster
  border-radius: 5px
  background-color: white
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  overflow: hidden
  border: 1px solid #bfcdd9

.row:after
  content: ''
  display: table
  clear: both

.menuRow
  margin-bottom: 1rem
  cursor: pointer

.menuItem
  float: left
  width: 95%
  font-weight: bold
  color: #e1343f

.listItem
  color: rgb(22, 65, 98)
  display: flex
  align-items: center
  font-weight: bold

.chevronColumn
  float: left
  width: 5%

.bookmarkColumn
  float: left
  width: 5%

.tagButton
  color: #e1343f
  border: none
  border-bottom: 1px solid #e1343f
  outline: none
  background: none
  padding: 2px 0
  font-weight: 600
  font-size: 13px

.tagButton:not(:first-child)
  margin-left: 0px

.tagButton:last-child
  margin-left: 10px

  
.avatar
  &_logo
      margin-right: 1rem
      border-radius: 50%
      border: 0.1rem solid $border-color
      width: 45px
      height: 45px
      padding: 0.25rem 0.5rem
      overflow: hidden
      flex-shrink: 0
      object-fit: contain

img.icon
  height: 1rem
  width: 1rem
  margin-right: 1rem

img.chevron
  heignt: 0.9rem
  width: 0.9rem

.wrapper
  padding: 1rem

.videoCatogoryHeader
  font-size: 1.2rem
  color: #164162
  font-weight: bold
  margin-bottom: 1rem

.videoCategory
  margin-bottom: 1.5rem

.bookmark
  height: 2rem
  width: 2rem

img.bookmark
  cursor: pointer

.pin
  height: 1.7rem
  width: 1.7rem
  margin-right: 1rem

li.listRow:not(:first-child)
  margin-top: 12px

li.listRow:not(:last-child)

  border-bottom: solid thin
  color: lightgrey
  padding-bottom: 12px

li.listRow
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start

ul
  width: 100%

ul.listFolders
  padding: 1.2rem 1.5rem 0.6rem 1.5rem

div.listRow
  display: flex,
  flex-direction: row

.textListItem
  vertical-align: text-top

.desktopText
  display: block

.mobileIcon
  display: none


@media ( max-width: 576px)
  .videoCatogoryHeader
    font-size: 3.5rem
    margin: 3rem 0 1.5rem 0
  .column30
    font-size: 3.3rem
    padding-bottom: 1.5rem
    margin-bottom: 5rem
  img.icon
    height: 2.7rem
    width: 2.7rem
    margin-right: 3rem
  img.chevron
    heignt: 2.6rem
    width: 2.6rem
  .menuItem
    padding-left: 1rem
  .videoCategory
    font-size: 3rem
    margin-bottom: 2.5rem
  .pin
    height: 5rem
    width: 5rem
  .bookmark
    height: 5rem
    width: 5rem
  li.listRow
    padding-bottom: 3rem
  .listFolders
    padding-top: 3rem

@media( min-width: 576px )
  .column30
    float: left
    width: 30%
  .column70
    float: left
    width: 67%
    margin-left: 1.5rem
  .box
    margin: 0 15rem
    margin-bottom: 2rem
    align-items: center
    padding: 2.5rem 0 0 0


ul.subCategory li
  display: block
  color: #e1343f
  margin-left: 3rem
  margin-top: 0.3rem


.button
  display: flex
  justify-content: center
  align-items: center
  color: #ffffff
  font-weight: 700
  border-radius: 6rem
  background-color: $contrast-color
  padding: 1rem 1.5rem
  border: none
  outline: none
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: $hover-blue-color
    text-decoration: none
    color: #ffffff
    &:focus
      outline: 0.01rem solid $hover-blue-color
      outline-offset: 0.3rem
      color: #ffffff
.doneButton
  display: flex
  justify-content: center
  align-items: center
  color: #ffffff
  font-weight: 700
  border-radius: 6rem
  background-color: $contrast-color
  padding: 1rem 1.5rem
  border: none
  outline: none
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: $hover-blue-color
    text-decoration: none
    color: #ffffff
    &:focus
      outline: 0.01rem solid $hover-blue-color
      outline-offset: 0.3rem
      color: #ffffff

@media (max-width: $screen-sm)
  .desktopText
    display: none
  .mobileIcon
    display: inline
    width: 15px
    height: 15px
  .videoCatogoryHeader
    font-size: 20px
    line-height: 20px
    align-self: flex-start
  .button
    font-size: 3.3rem
    line-height: 7rem
    border-radius: 50%
    height: 30px
    width: 30px
  .button
    padding: 1rem 3rem
    &__image
      width: 3rem
      height: 3rem
  .doneButton
    font-size: 3.3rem
    line-height: 7rem
    border-radius: 6rem
    padding: 1rem 3rem
    &__image
      width: 3rem
      height: 3rem