$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px

.reward_card
    display: flex
    flex-direction: column
    max-width: 15rem
    border-radius: 0.33rem
    box-shadow: 1.5px 2.598px 9px #d0dde4
    background-color: #ffffff
    margin: 1rem 0
    &__image
        margin: 1.5rem auto
        padding: 0.5rem 0
    &__details
        display: flex
        justify-content: space-between
        background-color: #f4fbff
        border-top: 1px solid #d0dde4
        padding: 0.5rem
        .description
            padding: 0.5rem
            font-size: 1rem
            line-height: 1.4rem
            color: #003056
            font-weight: 400
        .main_text
            font-size: 1.2rem
            line-height: 1.5rem
            color: #dd373e
            font-weight: 800

@media(max-width: $screen-lg)
    .reward_card
        max-width: 20rem
        &__image
            margin: 2.5rem auto
        &__details
            .description
                padding: 0.7rem
                font-size: 1.4rem
                line-height: 1.6rem
            .main_text
                font-size: 1.5rem
                line-height: 1.8rem

@media(max-width: $screen-md)
    .reward_card
        max-width: 25rem
        &__image
            margin: 2.5rem auto
            padding: 1rem 0
        &__details
            .description
                padding: 1rem
                font-size: 1.8rem
                line-height: 2.2rem
            .main_text
                font-size: 2rem
                line-height: 2.4rem

@media(max-width: $screen-sm)
    .reward_card
        max-width: 39rem
        &__image
            margin: 3.5rem auto
            padding: 1rem 0
        &__details
            .description
                padding: 1rem 2.5rem
                font-size: 2.2rem
                line-height: 2.6rem
            .main_text
                font-size: 2.4rem
                line-height: 2.8rem
