$screen-sm: 576px

.profileInformationWrapper
  padding-top: 1.5rem
  width: 100%
  height: 29.0625rem
  border-radius: 0.375rem
  filter: drop-shadow(0px 3px 12.5px rgba(198,220,234,0.75))
  background-color: #ffffff
  margin-top: 1.5rem
  @media (max-width: $screen-sm)
    height: 100%
  &_header
    display: flex
    justify-content: space-around
    width: 100%
    @media (max-width: $screen-sm)
      padding-top: 4.5rem
      padding-bottom: 3rem
    &_title
      font-size: 1.25rem
      line-height: 2.25rem
      text-transform: uppercase
      color: #103155
      font-weight: 900
      margin-left: 1rem
      margin-right: 1rem
      width: 100%
      max-width: 90rem
      white-space: nowrap
      @media (max-width: $screen-sm)
        margin-top: 0.5rem
        font-size: 3.125rem
        margin-left: 5rem
        margin-right: 5rem
    &_lineMobile
      max-width: 32.6875rem
      width: 100%
      border-bottom: 1px solid #9cb2be
      margin-top: 1.4rem
      @media (min-width: $screen-sm)
        display: none
    &_line
      width: 100%
      border-bottom: 1px solid #9cb2be
      margin-top: 1.4rem
      @media (max-width: $screen-sm)
        max-width: 32.6875rem
        width: 100%
    &_email
      font-size: 1rem
      line-height: 2.25rem
      color: #103155
      font-weight: 600
      text-align: center
      width: 100%
      margin-top: .5rem
      max-width: 90rem
      white-space: nowrap
      @media (max-width: $screen-sm)
        font-size: 3.125rem
        margin-left: 5rem
        margin-right: 5rem
        margin-bottom: 2rem

.profileInformationMainSection
  margin: 1rem 1.5rem 1.5rem 1.5rem
  display: flex
  justify-content: space-between
  @media (max-width: $screen-sm)
    flex-direction: column
    justify-content: center
    align-items: center
  &_personPhoto
    display: flex
    justify-content: center
    width: 12.5rem
    height: 12.5rem
    border-radius: 6.25rem
    border: 1px solid #b9cfdd
    img
      border-radius: 6.25rem
      height: 100%
      padding: 0.25rem 0.5rem
      overflow: hidden
    @media (max-width: $screen-sm)
      margin: 0 auto
      width: 30rem
      height: 30rem
      border-radius: 45rem
    &_logo
      border-radius: 6.25rem
      height: 100%
      padding: 0.25rem 0.5rem
      overflow: hidden
      @media (max-width: $screen-sm)
        border-radius: 45rem

  &_sendMessageButton
    display: flex
    justify-content: center
    align-items: center
    margin-top: 1rem
    cursor: pointer
    width: 12.5rem
    height: 2.5rem
    border-radius: 1.25rem
    background-color: #dd373e
    font-size: 1rem
    line-height: 3.75rem
    color: #FFFFFF
    font-weight: 700
    &:hover,
    &:active
      transform: scale(1.01)
      background-color: #0c4c81
      text-decoration: none
      color: #ffffff
    &:focus
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
      color: #ffffff
    @media (max-width: $screen-sm)
      width: 31.25rem
      height: 8.25rem
      border-radius: 7.125rem
      font-size: 2.8rem
      margin: 2rem 0
      
  &_playerReportButton
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    width: 8.5rem
    height: 2.5rem
    border-radius: 1.25rem
    background-color: #dd373e
    font-size: 1rem
    line-height: 3.75rem
    color: #FFFFFF
    font-weight: 700
    &:hover,
    &:active
      transform: scale(1.01)
      background-color: #0c4c81
      text-decoration: none
      color: #ffffff
    &:focus
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
      color: #ffffff
    @media (max-width: $screen-sm)
      width: 31.25rem
      height: 8.25rem
      border-radius: 7.125rem
      font-size: 2.8rem
      margin: 2rem 0

  &_playerGoalButton
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    width: 4.5rem
    height: 2.5rem
    border-radius: 1.25rem
    background-color: #103055
    font-size: 1rem
    line-height: 3.75rem
    color: #FFFFFF
    font-weight: 700
    &:hover,
    &:active
      transform: scale(1.01)
      background-color: #0c4c81
      text-decoration: none
      color: #ffffff
    &:focus
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
      color: #ffffff
    @media (max-width: $screen-sm)
      width: 31.25rem
      height: 8.25rem
      border-radius: 7.125rem
      font-size: 2.8rem
      margin: 2rem 0

  &_playerStatsInfo
    width: 100%
    max-width: 40rem
    background-color: #ffffff
    border: 1px solid #ccdfe9
    filter: drop-shadow(0px 1.5px 6.25px rgba(198,220,234,0.75))
    @media (max-width: $screen-sm)
      max-width: 92rem
      border-radius: 1.5rem
    &_stats
      display: flex
      align-items: center
      font-size: 1.125rem
      line-height: 2.125rem
      color: #FFFFFF
      border-right: 1px solid #9cb2be
      font-weight: 700
      width: 77.5%
      height: 3.1875rem
      background-color: #dd373e
      @media (max-width: $screen-sm)
        display: none
        width: 100%
        height: 7.96875rem
        font-size: 2.8125rem
        justify-content: center
        border-radius: 1.5rem 1.5rem 0 0
      p
        margin-left: 1rem
    &_info
      height: 3.1875rem
      width: 100%
      border-bottom: 1px solid #9cb2be
      // filter: drop-shadow(0px 3px 12.5px rgba(198,220,234,0.75))
      display: flex
      @media (max-width: $screen-sm)
        height: 15.56875rem
        justify-content: center
        align-items: center
      &_name
        border-right: 1px solid #9cb2be
        display: flex
        align-items: center
        width: 75%
        margin-left: 1rem
        font-size: 1.125rem
        line-height: 1.25rem
        color: #003056
        font-weight: 700
        @media (max-width: $screen-sm)
          height: 100%
          border-right: 1px solid #9cb2be
          font-size: 3.8125rem
          justify-content: start
          margin-left: 6rem
      &_goalTitle
        border-right: 1px solid #9cb2be
        background-color: #f1f8fe
        display: flex
        align-items: center
        width: 77.5%
        padding-left: 1rem
        font-size: 1.125rem
        line-height: 1.25rem
        color: #003056
        font-weight: 700
        @media (max-width: $screen-sm)
          display: none
          height: 100%
          border-right: 1px solid #9cb2be
          font-size: 3.8125rem
          justify-content: start
          margin-left: 6rem
      &_amount
        display: flex
        justify-content: center
        align-items: center
        width: 25%
        font-size: 1.125rem
        line-height: 1.25rem
        color: #003056
        font-weight: 400
        @media (max-width: $screen-sm)
          font-size: 3.8125rem
          align-items: center
          justify-content: center
          margin-top: 2rem






