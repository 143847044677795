.none
  display: none!important
.modal
  margin: 30vmin auto
  .buttonStrap
    padding: .5rem 3rem
.container
  margin: 0 10rem
  h2
    font-size: 3rem
    padding-top: 2rem
    padding-left: 10rem
.container__billing
  width: 40rem
  display: flex
  flex-direction: row
  padding: 2rem 10rem
  flex-direction: column
  align-items: flex-start
  border: 0px solid #ffffff
  border-radius: 3%
  button
    margin-top: 2rem
    margin-right: 5rem
    width: 15rem
    height: 3.1rem
    background-color: #dd373e
    color: #fff
    border-radius: 2rem
    border: none
    text-align: center
    align-self: center
.container__billing_info
  display: flex
  flex-direction: column
  padding: 2rem 1.5rem
  border-radius: 5%
  background-color: #ffffff
  font-size: 1.4rem
  span
    font-size: 1rem
    color: #808080
.card_images
  width: auto
  img
    padding-right: .5rem
    width: 5rem

.container_cards
  margin: .7rem 0rem
  display: flex
  flex-direction: row
  align-items: center

.main
  margin: 0 20rem
  padding: 2rem 0
  .header
    h2
      font-size: 2.5rem
      line-height: 2.1875rem
      font-weight: 400
      color: #003056
      margin-bottom: 1rem
    p
      font-size: .9375rem
      line-height: 1.25rem
      font-weight: 400
      color: #000000
      margin-bottom: 2rem
  section
    width: 60%
    margin-right: auto
    form
      h4
        font-size: 1.25rem
        line-height: 2.1875rem
        font-weight: 800
        color: #003056
      .row
        display: flex
        justify-content: stretch
        margin: .2rem 0
      .col
        display: flex
        flex-direction: column
        width: 100%
      .col_name
        display: flex
        flex-direction: column
        width: 100%
      .col_name > input
        width: 83%
        .required
          border-color: #dd373e
          box-shadow: 0 0 .3rem #dd373e
        .invalidEmail
          border-color: #dd373e
          box-shadow: 0 0 .3rem #dd373e
      label
        margin: 0
        font-size: .9375rem
        line-height: .1rem
        font-weight: 700
        color: #003056
      input
        font-size: .9375rem
        line-height: .5rem
        font-weight: 400
        color: #000000
        border-radius: .3125rem
        border: thin solid #b9cfdd
        padding: .6512rem 1rem
        margin: 1rem 3rem 1rem 0
      textarea
        font-size: .9375rem
        line-height: 1rem
        font-weight: 400
        color: #000000
        border-radius: .3125rem
        border: thin solid #b9cfdd
        padding: .6512rem 1rem
        margin: 1rem 3rem 1rem 0
      button
        font-size: 1.0417rem
        line-height: 1.7708rem
        font-weight: 700
        color: #ffffff
        background-color: #78a3be
        padding: .6778rem 1.491rem
        margin: 1rem 3rem 1rem 0
        border-radius: 2rem
        border: none
        outline: none
        cursor: pointer
      .submit
        z-index: 5
        cursor: pointer
        width: 100%
        background-color: #dd373e
      .bordered
        z-index: 5
        width: 100%
        color: #78a3be
        background-color: transparent
        border: thin solid #78a3be
      .small
        label,
        input
          width: 55%
.parent_form
  margin: 0
.card
  background-color: #ffffff
  width: 32.5rem
  height: 1.0rem
  font-size: .9375rem
  line-height: 1rem
  font-weight: 400
  border-radius: .3125rem
  border: .0521rem solid #b9cfdd
  padding: 1.1rem .4rem 2rem 1rem
  margin: .2rem 0rem
// padding-top: 16px
.col
  input[name="name"]
    width: auto
  .required
    border-color: #dd373e
    box-shadow: 0 0 .3rem #dd373e
  select[data-billing="data-billing"]
    margin-top: 1rem
    flex-direction: column
    width: 14.75rem
    height: 2.55rem
    padding: .7512rem .8rem
    font-size: .89rem
    border-radius: .3125rem
    border: thin solid #ddb9d1
    background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
    background-size: 1.3rem
    -webkit-appearance: none
    background-position-x: 12.5rem
.card1
  @extend .card
  width: 20rem

.card2
  @extend .card
  width: 10.5rem
  margin-left: 2rem

.cardContainer
  display: flex

.cardContainer1
  padding-top: .5rem
  display: inline-block

.cardNumText
  font-size: .84rem
  font-family: 'Lato'
  color: #003056
  font-weight: 600
.cardNumText2
  @extend .cardNumText
  padding-left: 2rem

.redStar
  color: #dd373e

.picCardsStyle
  width: 30rem
  margin-top: 2rem
  margin-bottom: 2rem

.section
  display: block

.cardNumTextDiscCoupon
  @extend .cardNumText
  padding-top: 15px
