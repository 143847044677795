.menuRow
  padding-bottom: 3rem
  padding-top: 1rem
  border-bottom: 1px solid #cfdce3
  cursor: pointer

.menuItem
  float: left
  text-align: left
  width: 95%
  font-weight: bold
  color: #e1343f

.listItem
  color: #164162
  float: left
  width: 95%
  font-weight: bold

.chevronColumn
  float: left
  width: 5%

.bookmarkColumn
  float: left
  width: 5%

img.icon
  height: 2rem
  width: 2rem
  margin-right: 1rem

img.subIcon
  height: 1.5rem
  width: 1.5rem
  margin-right: 1rem

img.chevron
  heignt: 0.9rem
  width: 0.9rem

.wrapper
  padding: 1rem

.videoCatogoryHeader
  font-size: 1.2rem
  color: #164162
  font-weight: bold
  margin-bottom: 1rem

.videoCategory
  margin-bottom: 1.5rem

.bookmark
  height: 2rem
  width: 2rem

img.bookmark
  cursor: pointer

.pin
  height: 1.7rem
  width: 1.7rem
  margin-right: 1rem

li.listRow:not(:last-child)

  border-bottom: solid thin
  color: lightgrey
  padding-bottom: 0.7rem

li.listRow
  width: 100%

ul
  width: 100%

div.listRow
  text-align: left

.textListItem
  vertical-align: text-top




@media ( max-width: 576px)
  img.icon
    height: 6rem
    width: 6rem
    margin-right: 3rem
  img.subIcon
    height: 4rem
    width: 4rem
    margin-right: 2rem
  img.chevron
    heignt: 2.6rem
    width: 2.6rem
  .menuRow
    padding-bottom: 7rem
    padding-top: 2rem
  .menuItem
    padding-left: 2rem
    font-size: 3rem
  .pin
    height: 5rem
    width: 5rem
  ul.subCategory li
    padding-top: 2rem
    font-size: 3rem
    padding-left: 6rem


ul.subCategory li
  display: block
  text-align: left
  color: #e1343f
  margin-left: 3rem
  margin-top: 0.3rem