@import ../../../../styles/Variables.module

  
@mixin button
  display: flex
  justify-content: center
  align-items: center
  line-height: 2.4rem
  font-size: 1.2rem
  border-radius: 2rem
  padding: 0.7rem 2rem
  margin-left: 1.2rem
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: #0c4c81
    text-decoration: none
    color: #fff
  &:focus
    outline: 0.01rem solid #0c4c81
    outline-offset: 0.3rem

.bg__button_active
  width: 29px
  height: 29px
  background: no-repeat url('css_sprites.png') -10px -10px

.selector_container
    padding: 1.5rem 0 1rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
.contest
    &__title
        font-size: 1.778rem
        line-height: 3rem
        color: $blue-color
        font-weight: 700
        font-family: "Lato"
        margin: 1rem 0 1rem

    &__info
        font-size: 1.7rem
        color: $red-color
        font-family: "Lato"

    &__subtext
        font-size: 1.778rem
        color: $red-color
        font-family: "Lato"
        font-weight: 700
.title_buttons_container
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap

.contest_metadata
  background-color: $background-color
  border: 1px solid #ccdfe9

.topPerformers
  background-color: $background-color

.actionb	
  padding: 8px 10px !important
  gap: 4px

@media (max-width: $screen-sm)
    .selector_container
        padding: 1rem 0 2rem
    .contest
        padding: 3rem 2rem
        &__title
            font-size: 4.5rem
            line-height: 6rem
            order: 2
    .title_buttons_container
        margin: 2rem 0

.box
  width: 100%
  display: flex
  align-items: center

.header
  background-color: $background-color
  display: flex
  justify-content: space-between
  align-items: center
  border: 0.1rem solid #ccdfe9
  padding: 1rem 1.5rem

.column30
  margin-top: 2.9rem
  padding: 1rem
  padding-right: 2rem
  background-color: white
  border-radius: 5px
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

.videoCluster
  padding: 1.2rem 1.5rem 0.6rem 1.5rem
  border-radius: 5px
  background-color: white
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

.row:after
  content: ''
  display: table
  clear: both

.menuRow
  margin-bottom: 1rem
  cursor: pointer

.menuItem
  float: left
  width: 95%
  font-weight: bold
  color: #e1343f

.listItem
  color: #164162
  float: left
  width: 95%
  font-weight: bold

.statLabel
  color: #164162
  font-weight: 600
  font-size: 1.25rem

.statValue
  color: #e1343f
  font-weight: 700
  font-size: 2.25rem

.chevronColumn
  float: left
  width: 5%

.bookmarkColumn
  float: left
  width: 5%

img.icon
  height: 1rem
  width: 1rem
  margin-right: 1rem

img.chevron
  heignt: 0.9rem
  width: 0.9rem

.wrapper
  padding: 1rem

.videoCatogoryHeader
  font-size: 1.2rem
  color: #164162
  font-weight: bold
  margin-bottom: 1rem

.videoCategory
  margin-bottom: 1.5rem

.bookmark
  height: 2rem
  width: 2rem

img.bookmark
  cursor: pointer

.pin
  height: 1.7rem
  width: 1.7rem
  margin-right: 1rem

li.listRow:not(:last-child)

  border-bottom: solid thin
  color: lightgrey
  padding-bottom: 0.7rem

li.listRow
  width: 100%

ul
  width: 100%

div.listRow
  text-align: left

.textListItem
  vertical-align: text-top


.buttonActions 
  display: flex
  justify-content: space-between


@media ( max-width: 576px)
  .videoCatogoryHeader
    font-size: 3.5rem
    margin: 3rem 0 1.5rem 0
  .column30
    font-size: 3.3rem
    padding-bottom: 1.5rem
    margin-bottom: 5rem
  img.icon
    height: 2.7rem
    width: 2.7rem
    margin-right: 3rem
  img.chevron
    heignt: 2.6rem
    width: 2.6rem
  .menuItem
    padding-left: 1rem
  .videoCategory
    font-size: 3rem
    margin-bottom: 2.5rem
  .pin
    height: 5rem
    width: 5rem
  .bookmark
    height: 5rem
    width: 5rem
  li.listRow
    padding-bottom: 3rem
  .videoCluster
    padding-top: 3rem
  
  .buttonActions
    flex-direction: column
    align-items: flex-start

@media( min-width: 576px )
  .column30
    float: left
    width: 30%
  .column70
    float: left
    width: 67%
    margin-left: 1.5rem
  .box
    margin: 0 15rem
    margin-bottom: 2rem
    align-items: center
    padding: 2.5rem 0 0 0


ul.subCategory li
  display: block
  color: #e1343f
  margin-left: 3rem
  margin-top: 0.3rem


.button
  display: flex
  justify-content: center
  align-items: center
  color: #ffffff
  font-weight: 700
  border-radius: 6rem
  background-color: $contrast-color
  padding: 1rem 1.5rem
  border: none
  outline: none
  gap: 1rem
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: $hover-blue-color
    text-decoration: none
    color: #ffffff
    &:focus
      outline: 0.01rem solid $hover-blue-color
      outline-offset: 0.3rem
      color: #ffffff

.actionb
  padding: 8px 10px !important
  gap: 4px

.button__reward
  @include button
  color: #ffffff
  background: #0e3f67
  margin-right: 1.2rem
  border: none
  &_disabled
    @include button
    color: #ffffff
    margin-right: 1.2rem
    border: none
    background: #bfcdd9
.button__title
  margin-left: 1rem
  white-space: nowrap

      
.topPerformer
  font-size: '2rem'
  color: #e55b65
  font-weight: 600

.topPlayer
  height: 120px
  width: 120px
  display: block
  background: #fff
  border-radius: 50%
  padding: 1rem
  border: 1px solid #78a3be

  &_name
    font-size: 1.5rem
    font-weight: 600
    text-transform: uppercase
    color: #003056
  &_value
    font-size: 1.8rem
    font-weight: 600

@media (max-width: $screen-sm)
  .button
    font-size: 3.3rem
    line-height: 7rem
    margin-left: 5rem
  .button
    padding: 1rem 3rem
    &__image
      width: 3rem
      height: 3rem
  .contest
    &__title
      margin-top: 2rem
    &__info
      font-size: 4.5rem
    &__subtext
      font-size: 4rem
      margin-bottom: 2rem
    
  .statLabel
    color: #164162
    font-weight: 600
    font-size: 3rem

  .statValue
    color: #e1343f
    font-weight: 700
    font-size: 3.5rem
  .topPerformer
    font-size: 4rem
  .topPlayer
    height: 90px
    width: 90px
    &_name
      font-size: 2.5rem
      font-weight: 600
      text-transform: uppercase
      color: #003056
    &_value
      font-size: 2.8rem
      font-weight: 600
  
  .button__reward
    font-size: 3.5rem
    margin-top: 3rem
    line-height: 5rem
    border: none
    border-radius: 4rem
    &_disabled
      margin-top: 3rem
      border-radius: 4rem
      font-size: 3.5rem
      line-height: 5rem
      border: none
      background: #bfcdd9
  .button__title
    margin-left: 1rem

  .buttonActions
    flex-direction: column
    align-items: flex-start
    gap: 2rem

  .actionb
    padding: 1px 10px !important
    margin: 0