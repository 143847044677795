@import ../../../../styles/RedButton.module

@mixin input
  margin: 0
  height: 3.75rem
  font-size: .9375rem
  line-height: 1.7708rem
  font-weight: 400
  border-radius: .3125rem
  border: 0.1rem solid #b9cfdd
  padding-left: 1.2rem
  padding-right: 0.2rem

@mixin sm-input
  margin: 1.5rem 0 2rem
  height: 10rem
  font-size: 3.3rem
  border-radius: .9125rem
  border-width: 0.3rem
  padding: 1.2rem

.red
  color: $contrast-color
.label
  color: $blue-color
  font-family: "Lato"
  font-size: 1rem
  font-weight: 700
  margin: 0.5rem 0
.form__description
  font-size: 1rem
  line-height: 2rem
  color: $light-blue-color
  font-weight: 400
.mobSign
  margin: 1rem
  display: flex
  justify-content: center
  align-items: center
  font-size: 1rem
  line-height: 2rem
  color: $light-blue-color
  font-weight: 400
  .link
    @extend .red
    padding-left: 0.5rem
    text-decoration: underline
    &:hover,
    &:active
      transform: scale(1.01)
      color: #0c4c81
    &:focus
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
.form
  border-radius: 0.33rem
  box-shadow: 0 0.1563rem 0.651rem #c6dceabf
  background-color: #fff
  padding: 2rem
  .divTotal
    position: relative
    display: flex
    padding: 0.4rem
    background-color: #F1EFEE
    margin: 1rem 0
  .section
    width: 100%
    .row
      display: flex
      justify-content: space-between
      flex-direction: row
      flex-wrap: wrap
      .label
        &::after
          content: " *"
          color: $contrast-color
    .col
      display: flex
      flex-direction: column
      width: 48%
      padding-bottom: 1rem
      .input
        @include input
      .Required
        border-color: $contrast-color
        box-shadow: 0 0 .3rem $contrast-color
.container_coupon
  display: flex
  width: 100%
  .input_coupon
    @include input
    width: 48%
    margin-right: 1.5rem
  .btn_step
    display: flex
    justify-content: stretch
  .gray_button
    @include red-button
    width: 16.25rem
    height: 3.75rem
    font-size: 1.0417rem
    line-height: 1.7708rem
    background-color: $light-blue-color
.buttonDefault
  @include red-button
  width: 100%
  font-size: 1.0417rem
  line-height: 1.7708rem
  height: 3.75rem
  background-color: $contrast-color
.invalidEmailAdressMessage,
.confirmPass
  color: $contrast-color

@media (max-width: $screen-sm)
  .mobSign
    padding-top: 2rem
    font-size: 3.5rem
    line-height: 6rem
  .form
    border-radius: 1rem
    padding: 6rem 3rem
    &__description
      font-size: 3rem
      line-height: 6rem
  .label
    font-size: 3.5rem
  .section
    margin: 1rem 0
    font-size: 5rem
    .row
      display: flex
      flex-direction: column
      &:first-child
        .col
          &:nth-child(1),
          &:nth-child(2)
            width: 48%
      .col
        width: 100%
        display: flex
        flex-direction: column
        .input
          @include sm-input
  .container_coupon
    .input_coupon
      @include sm-input
      margin-right: 4rem
    .gray_button
      margin: 1rem 0 2rem
      height: 10rem
      width: 48%
      font-size: 4.0417rem
  .buttonDefault
    height: 10rem
    margin: 4.5rem 0rem
    color: #ffffff
    font-size: 3.7rem
    font-weight: 700
    background-color: $contrast-color
    padding: 3.039rem 3.013rem

.spinner
  width: 20rem !important
  height: 20rem !important
  @media (max-width: $screen-sm)
    width: 10rem !important
    height: 10rem !important
