@import ../../../../styles/Variables.module

@mixin cell
    display: flex
    width: 18rem
    height: 8rem
    box-sizing: border-box
    flex-direction: column
    justify-content: center
    align-items: center

@mixin sm-cell
    width: 28.5rem
    height: 15rem

.row
    display: flex
    border: 0.1rem solid #ccdfe9
    border-radius: 0.33rem
.cell
    @include cell
    background-color: $background-color
    &:nth-child(2)
        border-left: 0.1rem solid #ccdfe9
        border-right: 0.1rem solid #ccdfe9
    &__title
        font-size: 1.11rem
        line-height: 2rem
        color: $blue-color
        font-weight: 700
        font-family: "Lato"
    &__info
        font-size: 2.33rem
        line-height: 4rem
        color: $red-color
        font-weight: 700
        font-family: "Lato"
    &__picture
        width: 5.8rem
        height: auto
.cell_goal
    @include cell
    background-color: #fff

.goal_status
    display: flex
    flex-wrap: wrap
    padding: 0 2rem
    text-align: center
    font-size: 1rem
    line-height: 1.5rem
    color: $contrast-color
    font-weight: 900
    font-family: "Lato"
    text-transform: uppercase

@media(max-width: $screen-sm)
    .row
        border-radius: 0.66rem
    .cell
        @include sm-cell
        &__title
            font-size: 2.5rem
            padding: 0 3rem
            line-height: 3rem
            text-align: center
        &__info
            font-size: 4rem
            line-height: 5rem
            &__picture
                width: 5.8rem
                height: auto
    .cell_goal
        font-size: 2.5rem
        line-height: 3rem
        @include sm-cell
    .goal_status
        font-size: 2.5rem
        line-height: 3rem
