$screen-sm: 576px
  
.container
  width: 30.3rem
  margin: 0 2rem 2rem 20rem
  @media (max-width: $screen-sm)
    margin: 2rem 2rem 50px 2rem
    width: 95%
    
  .flex
    display: flex
  .form
    padding-top: 1.5rem
    display: flex
    flex-direction: column
    @media (max-width: $screen-sm)
      overflow: hidden
    h3
      color: #003056
      font-size: 3rem
      @media (max-width: $screen-sm)
        margin-top: 6rem
        margin-bottom: 1rem
        color: red
        font-size: 7.4rem

    
    .label
      color: #003056
      font-size: 1.75rem
      margin-top: 2rem
      @media (max-width: $screen-sm)
        margin-top: 6rem
        margin-bottom: 1rem
        color: red
        font-size: 6.5rem

    p
      color: #78a3be
      @media (max-width: $screen-sm)
        font-size: 3.5rem
    .row
      display: flex
      justify-content: space-between
      padding-top: 2px
    .wrap
      display: flex
      justify-content: space-between
      padding-top: 2px
      @media (max-width: $screen-sm)
        flex-direction: column
    .col
      display: flex
      flex-direction: column
      width: 100%
      &__half
        width: 100%
        &_leftP
          padding-left: 1rem
          @media (min-width: $screen-sm)
            select
              flex-direction: column
              justify-content: center
              width: 11.85rem
              margin-bottom: .6rem
              padding: .10rem
              height: 2.08rem
              font-size: .9375rem
              line-height: 1.7708rem
              font-weight: 400
              color: #000000
              border-radius: .3125rem
              border: thin solid #b9cfdd
              background: url('//img/JoinMailingList/arrow-point.webp') no-repeat right #fff
              background-size: 1.3rem
              -webkit-appearance: none
              background-position-x: 11.8rem
              option
                font-size: 0.9375rem
        &_rightP
          padding-right: 1rem
          .userNameErr
            color: red
      label
        font-size: .9375rem
        font-weight: 400
        margin-bottom: .5rem
        @media (max-width: $screen-sm)
          font-size: 4.3rem
          margin-top: 4rem
          margin-bottom: 2rem
      input, select
        line-height: 1.7708rem
        font-weight: 400
        border-radius: .3125rem
        border: .0521rem solid #b9cfdd
        padding-left: 0.2rem
        padding-right: 0.2rem
        width: 100%
        border-radius: 5px
        text-align: left
        @media (max-width: $screen-sm)
          height: 13.5rem
          font-size: 4.3rem
          padding: 2rem
      .Required
        border-color: #dd373e
        box-shadow: 0 0 .3rem #dd373e

  .submitButton
    outline: none
    margin-left: 18rem
    width: 11.5rem
    height: 3.1rem
    background-color: #dd373e
    color: #fff
    border-radius: 2rem
    border: none
    text-align: center
    border-radius: 25px
    @media (max-width: $screen-sm)
      width: 100%
      height: 13.5rem
      font-size: 5rem
      margin-left: 0
  .spinner
    margin-top: 1rem

.customInput
  position: relative

.col__half_leftP img
  position: absolute
  right: 5%
  top: 11%
  height: 1.5rem
  width: 1.5rem
  @media (max-width: $screen-sm)
    position: absolute
    right: 5%
    top: 10%
    height: 10rem
    width: 10rem
