$screen-sm: 576px

.main
  display: flex
  justify-content: center
  align-items: center
  padding: 5rem 20rem
  button
    outline: none
  .arrow
    width: 3.5rem
  section
    h1
      font-size: 2.5rem
      line-height: 2.5rem
      font-weight: 300
      color: #003056
      strong
        font-weight: 700
    p
      font-size: 1.25rem
      line-height: 1.7708rem
      font-weight: 300
      color: #78a3be
      margin-bottom: .2rem
    div
      display: flex
      span
        font-size: 1.25rem
        line-height: 1.7708rem
        font-weight: 300
        color: #ffffff
        border-radius: .3125rem
        background-color: #dd373e
        padding: .339rem .797rem
        margin-bottom: auto
    .social
      margin-top: 1rem
      display: flex
      flex-direction: column
      .icon
        margin-right: .5rem
        margin-top: .1rem
      .facebook
        width: 28rem
        height: 3.2rem
        border-radius: 2rem
        background-color: #3B5998
        align-items: center
        justify-content: center
        color: white
      .socialContainer
        margin-top: .5rem
        display: flex
        justify-content: space-between
        .twitter
          background-color: #04BEDF
          width: 13.5rem
          border-radius: 2rem
          height: 2.8rem
          color: white
          align-items: center
          justify-content: center
        .mail
          background-color: transparent
          width: 13.5rem
          border-radius: 2rem
          border: .1rem solid #E15A60
          color: #E15A60
          height: 2.8rem
          align-items: center
          justify-content: center
  a.other
    font-size: 1.0417rem
    line-height: .9375rem
    font-weight: 400
    color: #ffffff
    background-color: #dd373e
    padding: .7039rem 3.1413rem
    border-radius: 1.5625rem
    margin: 2rem 0
    width: 100%
    text-align: center
.img_phone
  width: 23.5rem
@media (max-width: $screen-sm)
  .main
    padding: 2rem 20rem
    img
      width: 90%
    section
      h1
        width: 35rem
        font-size: 7rem
        line-height: 7rem
        margin-bottom: 1rem
      p
        font-size: 4rem
        line-height: 4rem
        margin-bottom: 3rem
      div
        span
          font-size: 3rem
          line-height: 4.5rem
      .social
        &>div
          margin-bottom: 2rem
        font-size: 3rem
        .icon
        .facebook
          width: 35rem
          height: 8rem
          border-radius: 3rem
        .socialContainer
          .twitter
            font-size: 2.7rem
            width: 16rem
            height: 8rem
            border-radius: 3rem
            padding: 1rem
          .mail
            width: 16rem
            height: 8rem
            border-radius: 3rem

    a.other
      display: flex
      align-items: center
      justify-content: center
      font-size: 3rem
      height: 8rem
      border-radius: 4rem

