@import ../../../../styles/Variables.module
@mixin button
    font-weight: 700
    font-size: 1rem
    line-height: 2rem
    background-color: #fff
    text-decoration: underline
    border: none
    outline: none
    &:hover,
    &:active
        transform: scale(1.01)
        text-decoration: underline

@mixin sm-text
    font-size: 2.5rem
    line-height: 3rem

.management
    display: flex
    flex-direction: column
    align-items: flex-start
    font-family: "Lato"
    &__title
        font-size: 1.778rem
        line-height: 3rem
        color: $blue-color
        font-weight: 700
        padding: 2rem 1.5rem 1.5rem
    .form
        display: flex
        flex-wrap: wrap
        padding-right: 1.5rem
    .label
        display: flex
        flex-direction: column
        align-items: flex-start
        font-size: 1rem
        line-height: 2rem
        padding-left: 1.5rem
        padding-bottom: 1.5rem
        color: $blue-color
        font-weight: 700
        width: 50%
        &:last-child
            padding-bottom: 0
        &_important
            &::after
                content: ' *'
                color: $contrast-color
    .input
        width: 100%
        height: 3.5rem
        border-radius: 0.33rem
        background-color: #ffffff
        border: 0.1rem solid #b9cfdd
        color: $blue-color
        padding-left: 1.5rem
        &__error
            font-size: 0.9rem
            line-height: 1.125rem
            color: $contrast-color
            font-family: "Lato"
    &__info
        font-size: 0.9rem
        line-height: 2rem
        color: $blue-color
        font-style: italic
        padding-left: 1.5rem
        &::before
            content: "* "
            color: $red-color
            font-size: 0.9rem
            line-height: 2rem
    .label
        &:nth-child(4) .input,
        &:nth-child(6) .input,
        &:nth-child(8) .input
            background-color: $background-color
            color: $light-blue-color
    .buttons
        align-self: center
        width: 70%
        display: flex
        flex-wrap: wrap
        justify-content: center
        align-items: center
        gap: 1rem
        padding: 2rem
    .button_delete
        color: $blue-color
        @include button
        position: relative
        padding-left: 2rem
        &:focus
            outline: 0.01rem solid #0c4c81
            outline-offset: 0.3rem
            border-radius: 0.33rem
        &::before
            position: absolute
            top: 0.5rem
            left: 0.5rem
            content: ''
            background-repeat: no-repeat
            background-size: 100%
            height: 1.27rem
            width: 1rem
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAYCAMAAADJYP15AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAAADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWADBWTWBIlAAAADV0Uk5TAAip+v/8rQuXoQYpIv4KAny+s4MD6e7v8Pvy9RofDRcHARQJBOvt7PETDgwPBTs1m5+y/bUkhxkWAAAAuUlEQVR4nNWRhw7CMAxEr/SgrIadMsssq2Xz/9+GixEE/oCTnOReZMtxgKe8gk9RsRTAVZkvVVxaJWv5Xg9plDQCY0yTLXVtdsQGXfRorSSHWqMoIT5CP3eDoa/4dRihOiYnsaMpOZ5JPXL+1RWZrzG5gCcNLDUMGSteIbFAsgY2FvM31sRP/AtewW4B2weirfOcHYI9kIpL91gqznQ07qgyWQ/k0Tg6kYf89swfXZ5Js+vNheFdPuEBvuYQ86pQUHUAAAAASUVORK5CYII=')
    .button_upload
        padding-left: 1.5rem
        margin-top: 1.8rem

@media (max-width: $screen-sm)
    .management
        &__title
            font-size: 3.5rem
            line-height: 4rem
        .label
            font-size: 2.5rem
            line-height: 4rem
        .input
            width: 100%
            height: 8rem
            border-radius: 0.66rem
            padding-left: 1.5rem
            &__error
                @include sm-text
        &__info
            @include sm-text
            &::before
                @include sm-text
        .buttons
            gap: 2rem
            padding: 4rem 2rem
            width: 65%
        .button_delete
            font-size: 3rem
            line-height: 4rem
            padding-left: 4rem
            order: 3
            &::before
                top: 0.5rem
                left: 0.5rem
                height: 3rem
                width: 2.5rem
