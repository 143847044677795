$screen-sm: 576px
$contrast-color: #dd373e
$blue-color: #003056

@mixin button
    width: 13rem
    line-height: 2.4rem
    font-size: 1.2rem
    border-radius: 2rem
    padding: 0.7rem 2rem
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem

.container
    padding: 2rem 0
    margin: 0 9.4rem 2rem
    .section
        background: #ffffff
        padding: 1.5rem 1.5789rem
        border-radius: .3125rem
        box-shadow: 0 .1563rem .651rem #c6dceabf
        &__flexContainer
            display: flex
            justify-content: flex-start
    .title
        text-align: center
        flex-grow: 1
        font-size: 1.6667rem
        line-height: 1.25rem
        font-weight: 300
        color: #dd373e
        align-self: center
    .buttons
        display: flex
        justify-content: center
        margin: 3rem auto
        &__cancel
            @include button
            text-align: center
            color: #78a3be
            background-color: transparent
            border: thin solid #78a3be
            margin-right: 2rem
        &__new_plan
            @include button
            color: #ffffff
            background: $contrast-color
            border: none

@media (max-width: $screen-sm)
    .container
        .section
            font-size: 3.5rem
            margin: 3rem
            &__flexContainer
                height: 10rem
        .title
            font-size: 5rem
        .buttons
            &__cancel,
            &__new_plan
                justify-content: space-around
                font-size: 3rem
                width: 28rem
                line-height: 8rem
                height: 9rem
