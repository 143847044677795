$contrast-color: #dd373e
$screen-sm: 576px

.trial_activated
    font-size: 1.0rem
    position: relative
    line-height: 1.7708rem
    font-weight: 700
    padding: .7039rem 2rem
    color: $contrast-color
    border: none
    background-color: #fff
    padding-left: 4rem
    &::before
        content: ''
        width: 1.64rem
        height: 1.5rem
        background-repeat: no-repeat
        background-size: 100%
        position: absolute
        top: 0.75rem
        left: 1.64rem
        background-image: url('../../../asset/dashboard/warning.webp')

    @media (max-width: $screen-sm)
        font-size: 3rem
        line-height: 7rem
        justify-self: end
        &::before
            content: ''
            width: 3.5rem
            height: 3.2rem
            top: 2.5rem
            left: 0
// we use it in NavPanel (desktop) and NavPanelMobile
