@import ../../../../styles/Variables.module

.selector_container
    padding: 1.5rem 0 1rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
.team_stats
    padding: 1rem
    &__title
        font-size: 1.778rem
        line-height: 3rem
        color: $blue-color
        font-weight: 700
        font-family: "Lato"
        margin: 2.5rem 0 1rem
.player_stats
    width: 54rem
    margin-bottom: 2rem
    &__header
        background-color: $background-color
        font-size: 1rem
        line-height: 4rem
        color: $blue-color
        font-weight: 700
        border-radius: 0.33rem 0.33rem 0 0
        border: 0.1rem solid #ccdfe9
        &__item
            width: 25%
            padding: 0 1rem
            box-sizing: border-box
    &__main
        width: 100%
        border-radius: 0 0 0.33rem 0.33rem
        border: 0.1rem solid #ccdfe9

@media(max-width: $screen-sm)
    .selector_container
        padding: 3rem 0 2rem
    .team_stats
        &__title
            font-size: 4rem
            line-height: 6rem
    .player_stats
        width: 100%
        &__header
            font-size: 2.5rem
            line-height: 7rem
        &__main
        border-radius: 0 0 0.66rem 0.66rem
