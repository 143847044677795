@import ../../../../styles/Variables.module
@mixin button
  display: flex
  justify-content: center
  align-items: center
  font-size: 3rem
  line-height: 9rem
  padding: 0.4rem
  width: 35rem

@media (max-width: $screen-sm)
  .modal
    position: absolute
    top: 32rem
    right: 0
    z-index: 1
    padding: 1rem

  .modal_content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: #fff
    width: 60rem
    border-radius: 3rem
    box-shadow: 0 0.3rem 0.5rem #c6dcea
    &::before
      content: ''
      position: absolute
      top: -4rem
      right: 4.5rem
      width: 0
      height: 0
      border: solid transparent
      border-left: 3.5rem solid transparent
      border-right: 3.5rem solid transparent
      border-bottom: 5rem solid #fff
      pointer-events: none
    &::after
      content: ''
      position: absolute
      transform: rotate(45deg)
      width: 4rem
      height: 5rem
      top: -1.5rem
      right: 6.5rem
      z-index: -1
      box-shadow: 0.2rem -0.3rem 1rem 0.5rem #c6dcea
    .list_item
      width: 80%
      display: flex
      justify-content: center
      align-items: center
      font-weight: normal
      background: #ffffff
      border: none
      text-align: center
      color: #78a3be
      font-size: 4rem
      line-height: 6rem
      padding-top: 2rem
    .button
      border: none
      background-color: #fff
      &:hover,
      &:active
        color: #78a3be
        transform: scale(1.01)
        text-decoration: none
    &:focus
      color: #78a3be
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
