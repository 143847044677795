$font-stack: 'Lato', sans-serif
$font-size: 1vw
$primary-color: #000
$primary-bg-color: #fff

.backGroungForVideo
  opacity: 0.6
  background: #b4a76f
  padding: 5px 
  z-index: 2
  display: block
  max-width: 100%
  margin: 0 auto
  width: 100vw
  height: 1000vh
  position: absolute
.videoPlayer
  z-index: 3
  position: absolute


html,
body
  font-family: $font-stack
  font-size: $font-size
  background: $primary-bg-color
a,
ol,
ul,
li
  color: inherit
  text-decoration: none
  display: inline-block
li,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p
  margin: 0
  padding: 0
button
  outline: none
input
  outline: none
select
  outline: none
select option
  outline: none
option
  outline: none
#root
  display: flex
  flex-direction: column
  align-items: stretch
  min-height: 100vh

