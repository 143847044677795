@import ../../../styles/Variables.module

@mixin input
  width: 21.0526rem
  margin: 0 0 1rem
  color: $blue-color
  &::placeholder
    color: #b9cfdd

@mixin input-sm
  width: 100%
  height: 10rem
  border-radius: 1rem
  font-size: 2.8rem
  line-height: 4rem
  padding: 2rem 1.5rem
  margin: 2rem 0 3rem
  &::placeholder
    color: #b9cfdd
    font-size: 2.8rem
    line-height: 4rem
.forgot
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 2rem
  .errorsMessages
    border-color: $contrast-color
    box-shadow: 0 0 .3rem $contrast-color
  &__title
    font-size: 1.4583rem
    font-weight: 700
    margin: 3rem 0 1.5rem
    color: $contrast-color
  &__form
    padding: 2rem
    margin-bottom: 1rem
    background: #ffffff
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    display: flex
    flex-direction: column
    .forgot__input
      font-size: .9375rem
      line-height: 1.7708rem
      font-weight: 400
      border-radius: .3125rem
      border: .0521rem solid #b9cfdd
      padding: 0.6512rem 1rem
      @include input
      &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
    .errorsMessages
      border-color: $contrast-color
      box-shadow: 0 0 .3rem $contrast-color
      @include input
  &__label
    font-size: .9375rem
    font-weight: 700
    margin-bottom: .5rem
    color: $blue-color
  &__error
    width: 21.0526rem
    font-weight: 400
    font-size: .9375rem
    line-height: 1.25rem
    color: $contrast-color
  &__button
    margin-top: 1rem
    color: #ffffff
    font-size: 1.0417rem
    line-height: 1.7708rem
    font-weight: 700
    background-color: $contrast-color
    padding: .7039rem 3.1413rem
    border-radius: 1.5625rem
    border: none
    outline: none
    cursor: pointer
    &:hover,
    &:active
      transform: scale(1.01)
      background-color: #0c4c81
      text-decoration: none
      color: #ffffff
    &:focus
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
  &__sign_in
    margin-top: 1rem
    align-self: center
    font-size: .9375rem
    line-height: 1.25rem
    color: $light-blue-color
  &__link
    margin-left: 0.25rem
    font-size: .9375rem
    line-height: 1.25rem
    font-weight: 400
    color: $contrast-color
    &:focus
      outline: 0.01rem solid #0c4c81
      outline-offset: 0.3rem
    &:hover
      color: $contrast-color
      text-decoration: underline

@media (max-width: $screen-sm)
  .forgot
    &__title
      font-size: 5rem
      line-height: 10rem
    &__form
      width: 95%
      padding: 7rem 5rem
      .forgot__input,
      .errorsMessages
        @include input-sm
    &__label,
    &__link
      font-size: 3.5rem
      line-height: 4rem
    &__error
      width: 100%
      font-size: 3.25rem
      line-height: 6rem
    &__sign_in
      margin-top: 3rem
      font-size: 3.25rem
      line-height: 4rem
    &__button
      height: 10rem
      width: 100%
      border-radius: 6rem
      font-size: 3.5rem
      line-height: 6rem
      margin-top: 2rem
