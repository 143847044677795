@mixin select
    font-weight: 400
    font-family: "Lato"
    border-radius: 0.33rem
    background-color: #ffffff
    border: 0.1rem solid #b9cfdd
    color: #b9cfdd
    -moz-appearance: none
    -webkit-appearance: none
    appearance: none
    background-repeat: no-repeat
    background-position: 90% 50%
    background-size: 1.8rem
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAMAAAACJixMAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEhQTFRFuc/duc/duc/dAAAAuc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/dAv+DlAAAABh0Uk5TS9qgAJrbUv+vqOGw5iDs8SUf8CSVkM7Q/L8KcgAAAGlJREFUeJxlzFkOgCAMRdFqVVRUQBz2v1PBUkY+SHgnXICux+YM4wRCiLneFzfC6i7Z7hvsR/3H70oDmkr8fmoEJJHFbtFD8Yc6SJBJ6DDEGncikFw3dxKQxE4GQUInh1+4UwCa57Xp9QEbrgfddyKvhQAAAABJRU5ErkJggg==')
    &:hover
        box-shadow: 0 0.1rem 0.5rem rgba(103, 180, 243, 0.5)
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
