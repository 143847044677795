@import ../../../styles/Variables.module

.bordered_button
    display: flex
    justify-content: center
    align-items: center
    padding: .7039rem 3rem
    color: $light-blue-color
    background-color: transparent
    border-radius: 6rem
    border: thin solid $light-blue-color
    font-size: 1rem
    line-height: 1.5rem
    font-weight: 700
    font-family: "Lato"
    outline: none
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: $hover-blue-color
        text-decoration: none
        color: #ffffff
    &:focus
        outline: 0.01rem solid $hover-blue-color
        outline-offset: 0.3rem
    @media (max-width: $screen-sm)
        font-size: 3rem
        line-height: 5rem
        padding: 1rem 3.5rem
        min-width: 12rem
        min-height: 8rem
