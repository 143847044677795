@import '../../../styles/Variables.module'

.payment
    background-color: #fff
    border-radius: 0.33rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    padding: 1rem 2rem
    @media (max-width: $screen-sm)
        width: 95%
        border-radius: 1rem
        padding-bottom: 2rem
        margin-bottom: 4rem