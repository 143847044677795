.container
  width: 100%
  height: 56rem
  background: url('//img/employment/football.webp')
  background-size: cover
  z-index: 1
  &::before
    content: ''
    position: absolute
    background-color: rgba(0, 17, 31, 0.702)
    width: 100%
    height: 56rem
    z-index: 1

.container__main
  position: relative
  padding-top: 9.47rem
  padding-left: 9.94rem
  background: transparent
  z-index: 5
  &_title
    color: #ffffff
    padding-bottom: 2.52rem
    font-family: "Slabo 27px"
    font-size: 5.25rem
    font-weight: 400
    line-height: 3rem
  &_list
    color: #ffffff
    padding-bottom: 1rem
    img
      width: 3rem
      height: 2rem
    div
      display: flex
      justify-items: center
      align-items: center
      color: #ffffff
      font-size: 1.5rem
      font-weight: 400
      line-height: 2.25rem
      padding-bottom: 2.10rem
    div > img
      padding-right: 0.94rem
    p
      width: 13.375rem
      padding-bottom: 1.89rem
      color: #b9cfdd
      font-size: 2rem
      font-weight: 400
      line-height: 2.25rem
  &_subtitle
    width: 40.688rem
    color: #b9cfdd
    font-family: Lato, serif
    font-size: 1.375rem
    font-weight: 400
    line-height: 2rem
    a
      color: #dd373e
