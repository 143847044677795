@import ../../../../styles/Variables.module

@mixin text
  font-size: 1rem
  color: $blue-color

.invite_popup
  position: fixed
  z-index: 40
  background-color: #fff
  border-radius: 0.33rem
  top: 20%
  max-width: 34rem
  left: calc((100% - 34rem) / 2)
  &__title
    font-size: 1.5rem
    line-height: 4rem
    color: $blue-color
    text-align: center
    border-bottom: 0.1rem solid #bfcdd9
  &__label
    font-size: 1rem
    line-height: 2rem
    color: $blue-color
    font-weight: 700
    display: flex
    flex-direction: column
    align-items: flex-start
  &__description
    display: flex
    justify-content: center
    align-items: center
    font-size: 1rem
    line-height: 2rem
  .buttons
    display: flex
    justify-content: space-evenly
    align-items: center
    margin-top: 2rem
    button:not(:last-child)
      margin-right: 2rem
    @media (max-width: $screen-sm)
      button
        font-size: 6rem
        line-height: 9rem
  .container
    padding: 2rem
    @media (max-width: $screen-sm)
      padding-bottom: 10rem

@media (max-width: $screen-sm)
  .invite_popup
    border-radius: 0.66rem
    top: 20%
    width: 93rem
    max-width: 93rem
    left: calc((100% - 94rem) / 2)
    &__title
      font-size: 6rem
      line-height: 14rem
    &__label
      font-size: 4.5rem
      line-height: 6rem
    &__textarea
      padding: 1rem
      font-size: 2rem
      width: 60rem
      height: 30rem
      border-radius: 0.5rem
    &__description
      margin-top: 4rem
      font-size: 4.5rem
      line-height: 5rem
.form
  display: flex
  flex-direction: column
  align-items: flex-start
  &__label
    display: flex
    flex-direction: column
    @include text
    font-weight: 700
    line-height: 2.5rem
  &__input
    border: 0.1rem solid #b9cfdd
    border-radius: 0.33rem
    width: 22rem
    @include text
    height: 3rem
    padding-left: 0.5rem
  &__checkbox
    margin-right: 1rem
  &__label__checkbox
    display: flex
    margin-top: 0.5rem
    margin-left: 0.9rem
    width: 21rem

.form_radio_redText
  color: #dd373e
  font-size: 0.8rem
  @media (max-width: $screen-sm)
    font-size: 4rem

.form_radio [type="radio"]:checked,.form_radio [type="radio"]:not(:checked)
  position: absolute
  left: -9999px

.form_radio [type="radio"]:checked + .form_radio_label,.form_radio [type="radio"]:not(:checked) + .form_radio_label
  position: relative
  padding: 0.375rem 0 0.25rem 2.375rem
  cursor: pointer
  line-height: 1.25rem
  display: inline-block
  margin-bottom: 0.25rem
  @media (max-width: $screen-sm)
    padding: 1.875rem 0 1.25rem 9.875rem
    line-height: 6.25rem
    font-size: 5.4rem

.form_radio [type="radio"]:checked + .form_radio_label:before,.form_radio [type="radio"]:not(:checked) + .form_radio_label:before
  content: ''
  position: absolute
  left: 0.4rem
  top: 0.2rem
  width: 1.625rem
  height: 1.625rem
  border: 0.125rem solid #ddd
  border-radius: 100%
  background: #fff
  @media (max-width: $screen-sm)
    left: 1.5rem
    top: 2.25rem
    width: 6.09375rem
    height: 6.09375rem
.form_radio [type="radio"]:checked + .form_radio_label:after,.form_radio [type="radio"]:not(:checked) + .form_radio_label:after
  content: ''
  width: 1.26rem
  height: 1.28rem
  background: #dd373e
  position: absolute
  top: 0.36rem
  left: 0.57rem
  border-radius: 100%
  -webkit-transition: all .2s ease
  transition: all .2s ease
  @media (max-width: $screen-sm)
    width: 4.725rem
    height: 4.8rem
    top: 2.85rem
    left: 2.1915rem

.form_radio [type="radio"]:not(:checked) + .form_radio_label:after
  opacity: 0
  -webkit-transform: scale(0)
  transform: scale(0)

.form_radio [type="radio"]:checked + .form_radio_label:after
  opacity: 1
  -webkit-transform: scale(1)
  transform: scale(1)
