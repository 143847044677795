$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px
$text-color: #003056
$contrast-color: #dd373e
$ligthblue-color: #78a3be

@mixin button
  font-size: 1.0417rem
  line-height: 1.7708rem
  font-weight: 400
  border-radius: 2rem
  padding: .7039rem 2rem
  &:hover,
  &:active
    transform: scale(1.01)
    text-decoration: underline
  &:focus
    outline: 0.01rem solid #0c4c81
    outline-offset: 0.3rem
    color: #ffffff

.mobile_navpanel
  display: none
.section
  display: flex
  justify-content: space-between
  align-items: center
  background: #ffffff
  padding: 1rem 1.5789rem
  border-radius: .3125rem
  box-shadow: 0 .1563rem .651rem #c6dceabf
  margin: 2rem 0
  .header
    font-size: 1.6667rem
    line-height: 1.25rem
    font-weight: 300
    color: $contrast-color
    margin: 0
  a,
  .createPlayer
    @include button
    color: #ffffff
    background-color: $ligthblue-color
    margin-left: 1rem
    border-radius: 2rem
    border: none
    outline: none
    cursor: pointer
  .bordered
    color: $ligthblue-color
    background-color: transparent
    border: thin solid $ligthblue-color
  .buttonDev
    @include button
    color: #fff
    background-color: $contrast-color
    margin-left: 1rem
    border-radius: 2rem
    border: none
    outline: none
  .button_free_trial
    @include button
    color: $contrast-color
    border: thin solid $contrast-color
    background-color: #d8e7f0
  @import TrialActivated.module.sass

@media (max-width: $screen-sm)
  .mobile_navpanel
    display: block
    padding-top: 2rem
  .section
    display: none
