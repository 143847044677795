@import ../../../../../../../styles/Variables.module

@mixin text
    padding: 0 1rem
    box-sizing: border-box
    font-size: 1rem
    line-height: 4rem

@mixin sm-text
    font-size: 2.5rem
    line-height: 7rem

.row
    border-bottom: 0.05rem solid #ccdfe9
    width: 100%
    box-sizing: border-box
.cell
    @include text
    color: $contrast-color
    &_first
        @include text
        color: $blue-color
        font-weight: 500
    &_player_name
        @include text
        color: $blue-color
    &__logo
        overflow: hidden
        border-radius: 50%
        border: 0.1rem solid $border-color
        margin-right: 1rem
        max-width: 3rem
        max-height: 3rem
        padding: 0.35rem 0.5rem

@media (max-width: $screen-sm)
    .cell
        @include sm-text
        padding: 0
        &_first
            @include sm-text
        &_player_name
            @include sm-text
        &__logo
            max-width: 5rem
            max-height: 5rem
