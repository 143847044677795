@import ../../../styles/Variables.module

.header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 1rem 3rem
  background: #ffffff
  z-index: 30
  position: relative
  .action_button
    padding-left: 0.7rem
    width: 1.7rem
  .li__action
    position: relative
    cursor: pointer
    user-select: none
  .nested_list
    width: 12rem
    padding: 1rem 0
    position: absolute
    top: 2.3rem
    left: -5.5rem
    display: flex
    flex-direction: column
    align-items: center
    background-color: #fff
    border-radius: 0.33rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    &::before
      content: ''
      position: absolute
      top: -1rem
      right: 0.9rem
      width: 0
      height: 0
      border: solid transparent
      border-left: 1rem solid transparent
      border-right: 1rem solid transparent
      border-bottom: 1rem solid #fff
      pointer-events: none
    &::after
      content: ''
      position: absolute
      transform: rotate(45deg)
      width: 1rem
      height: 1rem
      top: -0.5rem
      right: 1.5rem
      z-index: -1
      box-shadow: 0 .1563rem .651rem #c6dceabf
    &__item
      font-size: 1rem
      line-height: 2.5rem
      color: $blue-color
  .menu_btn
    display: none
  .logo
    display: flex
    align-items: center
    z-index: 30
    .logo_img
      width: 3rem
      z-index: 30
    .h2
      font-size: 1.1458rem
      line-height: 1.25rem
      color: #dd373e
      font-weight: 700
      margin-left: .5rem
      z-index: 30
  .nav
    color: #dd373e
    z-index: 30
    .li
      margin: 0rem .5rem 0rem 2.05rem
      font-size: 1rem
      line-height: 1.25rem
    .link
      text-decoration: none
      user-select: none
      &:hover,
      &:active
        transform: scale(1.01)
        color: $blue-color
        text-decoration: none
      &:focus
        border-radius: 0.3rem
        outline: 0.2rem solid #0c4c81
        outline-offset: 0.3rem
    .li_welcome
      margin: 0rem 3.5rem 0rem 2.05rem
      font-size: 1rem
      font-family: Lato, serif
      color: #78a3be
      line-height: 1.25rem
      span
        margin-left: 1.5rem
    .welcome__photo
    .photo
      width: 4rem
      max-height: 4.5rem
      border-radius: 0.33rem
    .welcome__avatar img
      width: 1.8rem
      filter: saturate(150%)
    .welcome__default
      width: 2rem
    .avatar
      width: 4rem
      vertical-align: middle
    .image_arrow
      display: none
      width: 4.5rem
      padding-left: .526rem
      padding-right: 3.15rem
    .pointer
      cursor: pointer
      .back
      &:hover
        color: #740006
@media(max-width: $screen-sm)
  .header
    height: 13rem
    display: flex
    flex-direction: row-reverse
    .menu_btn
      display: flex
      width: 7rem
      font-size: 3rem
      z-index: 40
    .logo
      .img
        width: 9rem
      .h2
        font-size: 3.5rem
    .nav
      display: none
