$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px
$text-color: #003056
$contrast-color: #dd373e

.popup__wrapper
    position: fixed
    z-index: 40
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0.9
    background-color: $text-color
.popup
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: fixed
    z-index: 40
    top: 15%
    left: 18%
    width: 60%
    border-radius: 0.2rem
    background-color: #FFF
    margin: 0 auto
.closeImg
    position: absolute
    top: -3.8rem
    right: -2.2rem
    color: #FFF
    font-size: 3.5rem
    font-weight: 300
    cursor: pointer
.popup__title
    margin: 1rem 0
    text-align: center
    color: $contrast-color
    font-size: 2rem
    line-height: 4rem
    font-weight: 700
.popup__button
    border: none
    font-size: 1.1rem
    line-height: 1.8rem
    text-decoration: underline
    color: #003056
    font-weight: 500
    text-align: center
    background-color: #fff
    margin: 1rem 0
.popup__video
    width: 54rem
    height: 31rem

@media (max-width: $screen-lg)
    .popup
        top: 20%
        left: 15%
        width: 65%
    .popup__button
        font-size: 1.3rem
        line-height: 2rem
    .popup__video
        width: 54rem
        height: 31rem

@media (max-width: $screen-md)
    .popup
        top: 20%
        left: 12%
        width: 75%
    .popup__title
        margin: 2.5rem 0 1.5rem
        font-size: 3.2rem
        line-height: 5rem
    .popup__button
        margin: 1.5rem 0 2.5rem
        font-size: 1.9rem
        line-height: 2.7rem
    .popup__video
        width: 68rem
        height: 38rem

@media (max-width: $screen-sm)
    .popup
        top: 20%
        left: 5%
        width: 90%
    .closeImg
        top: -6.5rem
        right: -3.8rem
        font-size: 6rem
    .popup__title
        font-size: 3.5rem
        line-height: 5rem
    .popup__button
        font-size: 2rem
        line-height: 4rem
    .popup__video
        width: 85rem
        height: 47.6rem
