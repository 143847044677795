$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px

.reward_container
    display: grid
    grid-template-columns: repeat(5, 1fr)
    grid-gap: 1.1rem

@media(max-width: $screen-lg)
    .certificate_center
        font-size: 1.5rem
    .reward_container
        grid-template-columns: repeat(4, 1fr)
        grid-gap: 1.1rem

@media(max-width: $screen-md)
    .certificate_center
        font-size: 2rem
    .reward_container
        grid-template-columns: repeat(3, 1fr)

@media(max-width: $screen-sm)
    .certificate_center
        font-size: 3rem
    .reward_container
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 1.1rem
