$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px
$text-color: #003056
$red-color: #dd373e

@mixin button
    display: flex
    width: 12rem
    justify-content: center
    align-items: center
    font-size: 1.2rem
    line-height: 2.8rem
    border-radius: 2rem
    padding: 0.4rem 1.2rem
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none
        color: #ffffff
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
        color: #ffffff

.new_plan_popup__wrapper
    position: fixed
    z-index: 40
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0.9
    background-color: $text-color
.new_plan_popup
    display: flex
    flex-direction: column
    position: fixed
    z-index: 40
    top: 20%
    left: 32%
    width: 32%
    max-width: 480px
    border-radius: 0.2rem
    background-color: #FFF
.closeImg
    position: absolute
    top: -3.8rem
    right: -2.2rem
    color: #FFF
    font-size: 3.5rem
    font-weight: 300
    cursor: pointer
.container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding-top: 20px
.header
    font-weight: 400
    text-align: center
    text-transform: capitalize
    font-size: 2rem
    line-height: 3rem
    color: $text-color
.hr
    width: 100%
    border-top: 1px solid #bfcdd9
.popup_description
    padding: 1rem 5rem
    font-weight: 700
    text-align: center
    font-size: 1.5rem
    line-height: 2rem
    color: $text-color
    .important
        color: $red-color
        font-size: 1.4rem
        line-height: 2.5rem
        font-weight: 400
        font-style: italic
.form_video_adjustment
    display: flex
    margin: 1rem 0 3rem
    justify-content: space-evenly
.select_name
    display: flex
    flex-direction: column
    font-size: 1.25rem
    line-height: 2.6rem
    color: #003056
    font-weight: 700
.select
    width: 12rem
    height: 4rem
    border-radius: 0.33rem
    background-color: #ffffff
    border: 0.1rem solid #b9cfdd
    padding: 1rem
    color: #b9cfdd
    -moz-appearance: none
    -webkit-appearance: none
    appearance: none
    background-repeat: no-repeat
    background-position: 90% 50%
    background-size: 1.8rem
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAMAAAACJixMAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEhQTFRFuc/duc/duc/dAAAAuc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/duc/dAv+DlAAAABh0Uk5TS9qgAJrbUv+vqOGw5iDs8SUf8CSVkM7Q/L8KcgAAAGlJREFUeJxlzFkOgCAMRdFqVVRUQBz2v1PBUkY+SHgnXICux+YM4wRCiLneFzfC6i7Z7hvsR/3H70oDmkr8fmoEJJHFbtFD8Yc6SJBJ6DDEGncikFw3dxKQxE4GQUInh1+4UwCa57Xp9QEbrgfddyKvhQAAAABJRU5ErkJggg==')
    &:hover
        box-shadow: 0 0.1rem 0.5rem rgba(103, 180, 243, 0.5)
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
.buttons
    display: flex
    justify-content: space-evenly
    margin-bottom: 2rem
    &__create_button
        @include button
        color: #ffffff
        background: $red-color
        border: none
    &__cancel_button
        @include button
        color: #78a3be
        background-color: transparent
        border: thin solid #78a3be

@media (max-width: $screen-lg)
    .new_plan_popup
        top: 20%
        left: 30%
        width: 40%
        max-width: 100rem
    .header
        font-size: 2.5rem
        line-height: 4rem
    .closeImg
        top: -5rem
        right: -3.3rem
        font-size: 4.5rem
    .popup_description
        padding: 1rem 5rem
        font-size: 1.8rem
        line-height: 2.5rem
        .important
            font-size: 1.6rem
            line-height: 2.8rem
    .form_video_adjustment
        margin: 1rem 0 3rem
    .select_name
        font-size: 1.4rem
        line-height: 2.8rem
    .select
        width: 15rem
        height: 5rem
        border-radius: 0.33rem
        border: 0.1rem solid #b9cfdd
        padding: 1rem
    .buttons
        margin-bottom: 2rem
        &__cancel_button,
        &__create_button
            width: 15rem
            font-size: 1.5rem
            line-height: 3rem
            padding: 0.4rem 1rem

@media (max-width: $screen-md)
    .new_plan_popup
        top: 20%
        left: 22.5%
        width: 55%
        max-width: 140rem
    .closeImg
        top: -5.8rem
        right: -4rem
        font-size: 5.5rem
    .container
    .header
        font-size: 3rem
        line-height: 4rem
    .popup_description
        padding: 1rem 5rem 2rem
        font-size: 2.2rem
        line-height: 3rem
        .important
            font-size: 1.8rem
            line-height: 3.5rem
    .form_video_adjustment
        margin: 1rem 0 3rem
    .select_name
        font-size: 1.8rem
        line-height: 3.5rem
    .select
        width: 22rem
        height: 6rem
        font-size: 2rem
        border-radius: 0.33rem
        background-color: #ffffff
        border: 0.1rem solid #b9cfdd
        padding: 1rem
    .buttons
        &__cancel_button,
        &__create_button
            border-radius: 4rem
            margin: 2.5rem 0
            width: 22rem
            font-size: 2.2rem
            line-height: 5rem
            padding: 0.4rem 0.4rem

@media (max-width: $screen-sm)
    .new_plan_popup
        top: 10%
        left: 15%
        width: 70%
        max-width: 200rem
    .closeImg
        top: -6.5rem
        right: -3.8rem
        font-size: 6rem
    .container
    .header
        font-size: 4rem
        line-height: 6rem
    .popup_description
        padding: 1rem 5rem 2rem
        font-size: 3rem
        line-height: 4.5rem
        .important
            font-size: 2.5rem
            line-height: 6rem
    .form_video_adjustment
        margin: 1rem 0 3rem
    .select_name
        font-size: 2.5rem
        line-height: 5rem
    .select
        width: 25rem
        height: 8rem
        border-radius: 0.33rem
        font-size: 3rem
        background-color: #ffffff
        border: 0.1rem solid #b9cfdd
        padding: 1rem
    .buttons
        &__cancel_button,
        &__create_button
            margin: 2.5rem 0
            width: 25rem
            font-size: 3rem
            line-height: 6rem
            padding: 0.4rem
