@import ../../../styles/Variables.module

@mixin text
 padding: 0 1rem
 box-sizing: border-box
 font-size: 1rem
 line-height: 4rem
@mixin cellWithLogo
 display: flex
 align-items: center
@mixin sm-text
 font-size: 2.5rem
 line-height: 7rem
$screen-sm: 576px

.dropdown
 &_container
  text-align: left
  border: 0.125rem solid #ccc
  position: relative
  width: 100%
  border-radius: 0.3125rem
  @media (max-width: $screen-sm)
   border: 0.4375rem solid #ccc
 &_input
  padding: 0.3125rem
  display: flex
  height: 3rem
  width: 100%
  align-items: center
  justify-content: space-between
  user-select: none
  @media (max-width: $screen-sm)
   padding: 1.09375rem
   height: 10.5rem
   font-size: 4rem
 &_menu
  position: absolute
  transform: translateY(0.25rem)
  width: 95%
  left: 0.5rem
  border: 0.125rem solid #ccc
  box-shadow: 0 3px 12.5px rgba(198,220,234,0.75)
  border-radius: 0.3125rem
  overflow: auto
  background-color: white
  max-height: 9.375rem
  z-index: 1
  &::-webkit-scrollbar
   background-color: white
  &::-webkit-scrollbar-track
   background-color: white
  &::-webkit-scrollbar-track-piece
   background-color: white
  &::-webkit-scrollbar-thumb
   background-color: white
  @media (max-width: $screen-sm)
   left: 1.75rem
   border: 0.4375rem solid #ccc
   max-height: 32.8125rem
 &_item
  padding: 0.3125rem
  cursor: pointer
  height: 3rem
  display: flex
  align-items: center
  @media (max-width: $screen-sm)
   height: 10.5rem
   padding: 1.09375rem
   font-size: 4rem
  &:hover
   background-color: #eaf3f8
 &_selected
  padding: 0.3125rem
  cursor: pointer
  background-color: #eaf3f8
  color: #000
  @media (max-width: $screen-sm)
   padding: 1.09375rem
   font-size: 4rem
 &_personPhoto

 &_tags
  display: flex
  flex-wrap: wrap
  gap: 0.3125rem
  @media (max-width: $screen-sm)
   gap: 1.09375rem
 &_tag
  &_item
   background-color: #eaf3f8
   padding: 0.125rem 0.25rem
   border-radius: 0.125rem
   display: flex
   align-items: center
   @media (max-width: $screen-sm)
    padding: 0.4375rem 0.875rem
  &_close
   display: flex
   cursor: pointer
   width: 2.25rem
   height: 2.25rem
   @media (max-width: $screen-sm)
    width: 7.875rem
    height: 7.875rem
   svg
    fill: $contrast-color
.icon
 width: 1.2rem
 height: auto
 @media (max-width: $screen-sm)
  width: 4.2rem
.cell
 @include text
 color: $contrast-color
 &_logo
  margin-right: 1rem
  border-radius: 50%
  border: 0.1rem solid $border-color
  max-width: 3rem
  max-height: 2.2rem
  padding: 0.25rem 0.5rem
  overflow: hidden
  @media (max-width: $screen-sm)
   margin-right: 3.5rem
   max-width: 10.5rem
   max-height: 7.7rem
   padding: 0.875rem 1.75rem



@media (max-width: $screen-sm)
 .icon
  width: 2rem
  height: auto
 .cell
  @include sm-text




