@import '../../../styles/Variables.module'
@import '../../../styles/VisuallyHidden.module'
  
$screen-sm: 576px
$screen-md: 768px
$screen-lg: 992px

.row:after
  content: ""
  display: table
  clear: both

.leftColumn
  margin-left: 10rem
  float: left
  width: 30.5%

.rightColumn
  margin-right: 10rem
  float: right
  width: 48.5%

.box
  align-items: center
    
.column30
  padding: 2rem
  padding-top: 1rem
  background-color: white
  border-radius: 5px
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

@media (max-width: $screen-sm)
  .leftColumn
    margin-left: 1rem
    width: 100%
  .rightColumn
    margin-left: 1rem
    margin-right: 0
    margin-top: 1rem
    width: 100%
    .additional_section__title
      margin-left: 2rem
  .column30
    margin: 2rem

.skillAreas
  color: red
  font-size: 1.2rem
  font-weight: bold
  position: relative
  top: 40%
  margin: .7rem 0 2rem
  

.grid_cards
  text-align: center
  padding: 1.2rem 0 4.5rem
  &__pagination
    width: 70%
    display: flex
    margin: 1rem 15.5rem 0
  .title
    .h2
      color: #dd373e
      font-size: 2.5rem
      line-height: 2.7083rem
      font-weight: 400
    .p
      color: #003056
      font-size: .9375rem
      line-height: 1.25rem
      font-weight: 400
      margin: .7rem 0 2rem
  .cardContainer, .cardContainerIExplorer
    display: grid
    justify-content: center
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 1rem
    color: #ffffff
    margin: 0 15rem
    .link
      text-decoration: none
    .card
      position: relative
      width: 21.5474rem
      height: 14.15rem
      padding: 3rem 2rem 0rem 2.0rem
      border: 1px solid #78a3be
      display: flex
      flex-direction: column
      justify-content: space-evenly
      cursor: pointer
      // transition: .2s linear
      background-size: cover
      background-repeat: no-repeat
      background-position: center
      &:hover
        transform: scale(1.01,1.01)
        color: #79f364
      &:focus
        outline: 0.2rem solid #0c4c81
        outline-offset: 0.3rem
      .span
        font-size: .9375rem
        line-height: 1.25rem
        font-weight: 400
        color: #fded21
      .h3
        width: 50%
        color: #dd373e
        font-family: Lato
        font-size: 1.05rem
        font-weight: bold
        text-transform: uppercase
        .p_title
          width: 9.125rem
          color: #ffffff
        .p_subtitle
          max-width: 20.375rem
          color: #dd373e
          font-size: 1.05rem
          font-family: Lato
          font-weight: bold
      .h3_inMain
        position: absolute
        top: 3rem
        right: 0
        .p_title
          width: 100%
          padding: 0 .7rem
          text-align: left
          color: #dd373e
          font-size: 1.1rem
          font-weight: 800
          line-height: 1.5rem
        .p_subtitle
          padding: 0.5rem .7rem 0
          text-align: left
          text-transform: initial
          color: #0a5087
          font-size: .8rem
          line-height: 1.1rem
        .p_comingSoon
          padding: 0.5rem .7rem 0
          text-align: left
          text-transform: initial
          font-size: .8rem
          line-height: .5rem
      .h5
        font-size: 1.0417rem
        line-height: 2.7083rem
        font-weight: 400
      .p
        font-size: .8333rem
        line-height: 1.0417rem
        font-weight: 400
    .card
      padding: 5rem 2rem 0rem 2.0rem
      margin: 1rem 0rem 1rem 0rem
  .card_container_main_categories
    display: flex
    
    flex-direction: row
    justify-content: left
    // grid-template-columns: repeat(2, 1fr)
    grid-gap: 1rem
    color: #ffffff
    // margin: 0 15rem
  .additional_section
    display: flex
    flex-direction: column
    justify-content: flex-start
    // margin: 0 10rem
    &__title
      text-align: left
      font-size: 1.5vw
      line-height: 2
      margin-top: 2rem
      margin-bottom: 1rem
      color: #e1343f
      font-weight: 700
    .flex_container
      display: flex
      flex-wrap: wrap
    .flex_container > *
      margin-right: 0
.modalAntd
  width: 90%
.p_coming
  position: absolute
  top: 60%
  right: 30%
  display: flex
  margin: 0 auto
  width: 9.188rem
  color: #a3a6b8
  font-family: Lato
  font-size: 1.5rem
  font-weight: 400
  line-height: 1.625rem

@media (max-width: 2000px)
  .grid_cards
    .additional_section
      &__title
        font-size: 1.75vw
      .flex_container
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-row-gap: 1rem
        grid-column-gap: 0

@media (max-width: $screen-lg)
  .grid_cards
    .additional_section
      .flex_container
        grid-template-columns: repeat(2, 1fr)
  .skillAreas
    font-size: 1.5rem

@media (max-width: $screen-md)
  .grid_cards
    .additional_section
      .flex_container
        grid-template-columns: repeat(2, 1fr)
  .skillAreas
    font-size: 1.8rem

@media (max-width: $screen-sm)
  .grid_cards
    margin-top: 2rem
    &__pagination
      margin: 3rem 3rem 0
      font-size: 4rem
    .card_container_main_categories
      grid-template-columns: repeat(2, 1fr)
      grid-template-rows: repeat(2, 1fr)
      grid-gap: 4rem
    .additional_section
      &__title
        font-size: 4vw
      .flex_container
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 0
        grid-row-gap: 4rem
    .cardContainer
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 1rem
      .card
        width: 46rem
        height: 30.5rem
        background-size: contain
      .h3
        font-size: 2.7rem
        height: 4rem
        .p_title
          font-size: 3.2rem
          width: 22.125rem
          color: #ffffff
          .p_subtitle
            max-width: 22.375rem
            color: #dd373e
            font-size: 2.57rem
            font-family: Lato, serif
            font-weight: bold
        .h3_inMain
          top: 7.5rem
          .p_title
            width: 100%
            padding: 0 1.5rem
            color: #dd373e
            font-size: 2.5rem
            line-height: 3.5rem
          .p_subtitle
            width: 100%
            padding: 0.5rem 1.5rem 0
            color: #0a5087
            font-size: 1.7rem
            line-height: 2.5rem
          .p_comingSoon
            padding: 0.5rem 1.5rem 0
            font-size: 1.7rem
            line-height: 1.5rem
    .p_coming
      position: absolute
      top: 60%
      right: 25.5%
      display: flex
      margin: 0 auto
      width: 20.188rem
      color: #a3a6b8
      font-family: Lato
      font-size: 3.2rem
      font-weight: 400
      line-height: 3.625rem
  .modalAntd
    width: 90%
  .skillAreas
    font-size: 3rem






.search
    display: flex
    justify-content: center
    align-items: flex-start
    gap: 2rem
    align-self: center
    margin: 0rem 10rem
    font-family: "Lato"
    background-color: #e8f1f8
    padding: 1rem
    cursor: pointer
    &__input
        border: 0.1rem solid #b9cfdd
        border-radius: 0.33rem
        width: 63rem
        height: 4rem
        font-size: 1rem
        color: $blue-color
        padding-left: 1.5rem
        background-color: white
        cursor: pointer
    &__info
        font-size: 0.9rem
        line-height: 1.5rem
        color: $contrast-color
.search_result
    &__title
        font-size: 1.78rem
        line-height: 2.5rem
        color: $blue-color
        font-weight: 700
.button_img
  height: 2rem
  margin: 0.2rem
.red_btn p
  font-size: 1.25rem

@media (max-width: $screen-sm)
    .search
        gap: 0
        align-self: center
        margin: 2rem 
        &__input
            border-width: 0.2rem
            border-radius: 1rem
            border-end-end-radius: 0
            border-top-right-radius: 0
            width: 80rem
            height: 8rem
            font-size: 2.5rem
        &__info
            font-size: 3rem
            line-height: 4.5rem
    .search button
      border-radius: 3px
      width: 5%
    .search_result
        &__title
            display: none
    .button_img
      height: 4rem
