@import ../../../../../styles/Variables.module

.challenge_buttons
  display: flex
  flex-direction: row
  gap: 1rem
  margin-right: 1rem
.challenge_toggler
  border-radius: 6rem
  line-height: 1.5rem
  padding: 1rem 1.5rem
  color: $light-blue-color
  font-weight: 700
  text-transform: capitalize
  border: 1px solid $light-blue-color
  outline: none
  background-color: inherit
  &:focus
    outline: 0.1rem solid #0c4c81
    outline-offset: -0.3rem
.challenge_button
  display: flex
  justify-content: center
  align-items: center
  color: #ffffff
  font-weight: 700
  border-radius: 6rem
  background-color: $contrast-color
  padding: 1rem 1.5rem
  border: none
  outline: none
  &__image
    width: 1rem
    height: 1rem
    margin: 0 0.75rem 0 0
  &__award
    width: 1.5rem
    height: 1.5rem
    margin: 0 0.75rem 0 0
  &:hover,
  &:active
    transform: scale(1.01)
    background-color: $hover-blue-color
    text-decoration: none
    color: #ffffff
    &:focus
      outline: 0.01rem solid $hover-blue-color
      outline-offset: 0.3rem
      color: #ffffff

@media (max-width: $screen-sm)
  .challenge_toggler,
  .challenge_buttons
    flex-direction: column
  .challenge_button
    font-size: 3.3rem
    line-height: 4rem
  .challenge_button
    padding: 1rem 3rem
    &__image
      width: 3rem
      height: 3rem
