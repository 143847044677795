@import ../../../../styles/Variables.module

@mixin text
    font-size: 1rem
    color: $blue-color
    font-family: "Lato"

.create_form
    display: flex
    flex-direction: column
    position: fixed
    z-index: 40
    top: 20%
    left: 20%
    border-radius: 0.33rem
    background-color: #FFF
    font-family: "Lato"
    max-width: 55rem
    &__title
        margin: 1rem 0
        font-size: 1.55rem
        line-height: 3rem
        color: $blue-color
        font-weight: 400
        align-self: center
    .flex_container
        border-top: 0.1rem solid #bfcdd9
        display: flex
    .description
        background-color: $background-color
        width: 50%
        box-sizing: border-box
        border-bottom-left-radius: 0.33rem
        &__title
            margin: 1.5rem 2rem 1rem
            font-size: 1.44rem
            line-height: 2.5rem
            color: $contrast-color
            font-weight: 700
        &__promo
            @include text
            margin: 0 2rem 1rem
            line-height: 1.25rem
        .pros
            display: flex
            flex-direction: column
            align-items: flex-start
            margin-left: 2rem
            &__item
                @include text
                line-height: 1.25rem
                padding: 1rem 0 1rem 3.5rem
                position: relative
                &:nth-child(1)::before
                    content: ''
                    background: no-repeat url('//img/teams/united.webp') 50% / 90%
                    position: absolute
                    left: 0.5rem
                    top: 0.25rem
                    width: 2rem
                    height: 2.5rem
                &:nth-child(2)::before
                    content: ''
                    background: no-repeat url('//img/teams/growth.webp') 50% / 90%
                    position: absolute
                    left: 0.5rem
                    top: 0.25rem
                    width: 2rem
                    height: 2.5rem
                &:nth-child(3)::before
                    content: ''
                    background: no-repeat url('//img/teams/medal.webp') 50% / 90%
                    position: absolute
                    left: 0.5rem
                    top: 0.25rem
                    width: 2rem
                    height: 2.5rem
    .form
        width: 50%
        display: flex
        flex-direction: column
        align-items: flex-start
        padding: 1rem 2.5rem 2rem
        &__label
            display: flex
            flex-direction: column
            @include text
            font-weight: 700
            line-height: 2.5rem
        &__input
            border: 0.1rem solid #b9cfdd
            border-radius: 0.33rem
            width: 22rem
            @include text
            height: 3rem
            padding-left: 0.5rem
        &__error
            font-size: 0.9rem
            line-height: 1.125rem
            color: $contrast-color
            font-family: "Lato"
        &__importance
            font-size: 0.9rem
            line-height: 1.125rem
            color: $contrast-color
            font-family: "Lato"
            font-style: italic
            padding-bottom: 2rem
    .buttons
        display: flex
        justify-content: space-evenly
        gap: 1rem

@media (max-width: $screen-sm)
    .create_form
        max-width: 90%
        min-width: 88%
        top: 20%
        left: 5%
        border-radius: 1.5rem
        &__title
            margin: 1.5rem 0
            font-size: 5rem
            line-height: 10rem
        .flex_container
            border-top: 0.1rem
            flex-direction: column
            .description
                width: 100%
                border-top: 0.1rem solid #bfcdd9
                &__title
                    font-size: 4rem
                    line-height: 7rem
                .pros
                    margin-bottom: 2rem
                    &__item
                        font-size: 2.8rem
                        line-height: 4rem
                        padding-left: 5rem
                        &:nth-child(1)::before,
                        &:nth-child(2)::before,
                        &:nth-child(3)::before
                            left: 0
                            width: 4rem
                            height: 4.7rem
                        &__title
                            margin: 1.5rem 2rem 1rem
                            font-size: 4.5rem
                            line-height: 6rem
                &__promo
                    margin: 0 2rem 1rem
                    font-size: 2.9rem
                    line-height: 4rem
        .form
            width: 100%
            padding-bottom: 6rem
            &__label
                font-size: 3rem
                line-height: 6rem
                padding-top: 2rem
                width: 100%
            &__input
                border-radius: 1rem
                width: 100%
                height: 9rem
                padding-left: 0.5rem
                font-size: 2.8rem
                line-height: 4rem
            &__error
                font-size: 2.5rem
                line-height: 3.5rem
            &__importance
                font-size: 2.5rem
                line-height: 3.5rem
                padding-bottom: 2rem
        .buttons
            margin: 4rem auto 0
