@import ../../../styles/Variables.module

@mixin button
    display: flex
    justify-content: center
    align-items: center
    font-size: 1rem
    line-height: 2rem
    border: none
    outline: none
    background-color: inherit
    &:hover,
    &:active
        transform: scale(1.01)
        text-decoration: none
    &:focus
        border-radius: 0.3rem
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem

@mixin mobile-button
    font-size: 3rem
    line-height: 9rem
    width: 35rem

.container
    position: relative
.buttons
    min-width: 10rem
    padding: 1rem
    position: absolute
    z-index: 20
    top: -0.5rem
    right: -0.5rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    background-color: #fff
    border-radius: 0.3rem
    box-shadow: 0 0.3rem 0.5rem $box-shadow-color
    // &::before
    //     content: ''
    //     position: absolute
    //     top: -1rem
    //     right: 1rem
    //     width: 0
    //     height: 0
    //     border: solid transparent
    //     border-left: 1rem solid transparent
    //     border-right: 1rem solid transparent
    //     border-bottom: 1rem solid #fff
    //     pointer-events: none
    // &::after
    //     content: ''
    //     position: absolute
    //     transform: rotate(45deg)
    //     width: 1rem
    //     height: 1rem
    //     top: -0.5rem
    //     // right: 1.5rem
    //     z-index: -1
    //     box-shadow: 0.2rem -0.3rem 1rem 0.5rem $box-shadow-color
    &__item
        @include button
        color: $contrast-color

@media (max-width: $screen-sm)
    .buttons
        top: 24.5rem
        right: 4rem
        width: 35rem
