$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px
$text-color: #003056
$contrast-color: #dd373e

@mixin button
    display: flex
    width: 13rem
    justify-content: center
    align-items: center
    font-size: 1.2rem
    line-height: 2.8rem
    border-radius: 2rem
    padding: 0.4rem 1.2rem
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem

.preview_popup_wrapper
    position: fixed
    z-index: 40
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0.98
    background-color: #003056
.reward_certificate_container
    display: flex
    flex-direction: column
    position: fixed
    z-index: 40
    top: 10%
    left: 20%
    width: 60%
    border-radius: 1rem
    background-color: #FFF
    opacity: 1
.buttons
    display: flex
    justify-content: center
    margin: 1.2rem auto 2.5rem
    &__cancel_button
        @include button
        color: #78a3be
        background-color: transparent
        border: thin solid #78a3be
        margin-right: 2rem
    &__download_button
        @include button
        color: #ffffff
        background: $contrast-color
        border: none

@media (max-width: $screen-lg)
    .reward_certificate_container
        width: 70%
        left: 15%

@media (max-width: $screen-md)
    .reward_certificate_container
        left: 10%
        width: 80%
        max-width: 140rem
    .buttons
        &__cancel_button,
        &__download_button
            border-radius: 3rem
            margin: 2.5rem 1rem
            width: 20rem
            font-size: 2rem
            line-height: 4rem
            padding: 0.4rem 0.4rem

@media (max-width: $screen-sm)
    .reward_certificate_container
        top: 10%
        left: 2.5%
        width: 95%
        max-width: 200rem
    .buttons
        &__cancel_button,
        &__download_button
            border-radius: 4rem
            margin: 2.5rem 2rem
            width: 30rem
            font-size: 3rem
            line-height: 6rem
            padding: 0.4rem
