@import ../../../styles/Variables.module

@mixin red-button
    display: flex
    justify-content: center
    align-items: center
    color: #ffffff
    font-weight: 700
    border-radius: 6rem
    background-color: $contrast-color
    padding: .7039rem 3rem
    border: none
    outline: none
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: $hover-blue-color
        text-decoration: none
        color: #ffffff
    &:focus
        outline: 0.01rem solid $hover-blue-color
        outline-offset: 0.3rem
        color: #ffffff

.red_button
    @include red-button
    font-size: 1rem
    min-width: 10rem
    line-height: 1.5rem
    font-weight: 700
    font-family: "Lato"
    @media (max-width: $screen-sm)
        font-size: 3rem
        line-height: 5rem
        padding: 1rem 3.5rem
        min-width: 12rem
        min-height: 8rem
