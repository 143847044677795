@import ../../../styles/Variables.module

.radio_button_label
    display: flex
    justify-content: flex-start
    align-items: center
    margin-right: 1rem
    gap: 0.2rem
    color: $blue-color
    font-size: 1rem
.radio_button
    -moz-appearance: none
    -webkit-appearance: none
    appearance: none
.round
    position: relative
    width: 1rem
    height: 1rem
    border: 0.11rem solid $blue-color
    border-radius: 50%
    &_checked
        position: absolute
        width: 0.5rem
        height: 0.5rem
        top: 0.13rem
        left: 0.13rem
        background-color: $contrast-color
        border-radius: 50%

@media (max-width: $screen-sm)
    .radio_button_label
        font-size: 3.5rem
        line-height: 6rem
        gap: 0.5rem
        margin-right: 2rem
    .round
        width: 2.5rem
        height: 2.5rem
        &_checked
            width: 1.5rem
            height: 1.5rem
            top: 0.39rem
            left: 0.39rem
