$screen-sm: 576px

.modalWindowButtonWrapper
  display: flex
  justify-content: end

.buttonWrapper
  display: flex
  justify-content: space-around
  width: 39rem
  margin-right: 3rem
  @media (max-width: $screen-sm)
    width: 100%
    margin-top: 2rem
    padding-bottom: 6rem
    flex-wrap: wrap
    justify-content: center
  &_button
    display: flex
    cursor: pointer
    @media (max-width: $screen-sm)
      width: 40%
      justify-content: center
      margin-top: 1rem
    img
      margin-left: 1rem
      margin-right: 0.5rem
      @media (max-width: $screen-sm)
        margin-right: 2rem
        margin-left: 1.5rem
    p
      font-size: 1rem
      line-height: 1.5rem
      text-decoration: underline
      color: #dd373e
      font-weight: 800
      margin-top: 0.3rem
      @media (max-width: $screen-sm)
       font-size: 3.5rem
       line-height: 5.25rem
    &_toDoList
      width: 0.9875rem
      height: 1.3725rem
      @media (max-width: $screen-sm)
        width: 3.6687rem
        height: 4.62125rem
    &_edit
      width: 1.1125rem
      height: 1.1125rem
      @media (max-width: $screen-sm)
        width: 3.78125rem
        height: 3.78125rem
    &_update
      width: 0.9875rem
      height: 1.1125rem
      @media (max-width: $screen-sm)
        width: 3.46875rem
        height: 3.78125rem
    &_leave
      width: 1.2625rem
      height: 1.2625rem
      @media (max-width: $screen-sm)
       width: 4.453125rem
       height: 4.953125rem

