@import '../../../styles/Button.module'

.upload_button
    background-color: #78a3be
    color: #ffffff
    font-weight: 700
    position: relative
    padding-left: 4rem
    border: none
    line-height: 2rem
    @media (max-width: $screen-sm)
        font-size: 3rem
        line-height: 6rem
        margin: 3rem 0
        border-radius: 6rem
        padding-left: 9rem
    &::before
        content: ''
        background: no-repeat url('../../../asset/dashboard/add-photo.webp') 50% / 90%
        position: absolute
        left: 2rem
        width: 1.5rem
        height: 2rem
        @media (max-width: $screen-sm)
            width: 4rem
            height: 4rem
            left: 3rem
            bottom: 1.25rem
    .input_file
        display: none
.crop_button
    background-color: #78a3be
    color: #ffffff
    font-weight: 700
    margin-top: .5rem
    display: flex
    width: 12rem
    border: none
    justify-content: center
    @media (max-width: $screen-sm)
        font-size: 3rem
        line-height: 5rem
        width: 15rem
        margin: 3rem 0
        border-radius: 6rem
crop,
.preview
    width: 20rem
    margin-top: 2rem
.image_preview
    max-height: inherit
    background-color: #fff
