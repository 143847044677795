@import ../../../styles/Variables.module

.promo__item
    position: relative
    padding-left: 0.5rem
.check
    padding-left: 0.625rem
    text-align: left
    font-size: 1rem
    font-weight: 400
    color: $blue-color
    line-height: 2rem

@media (max-width: $screen-sm)
    .check
        font-size: 3.5rem
        line-height: 7rem
        padding-left: 1.5rem
