@import Variables.module

@mixin button
    font-size: 1.0417rem
    line-height: 1.7708rem
    font-weight: 400
    border-radius: 2rem
    padding: .7039rem 2rem
    &:hover,
    &:active
        transform: scale(1.01)
        text-decoration: underline
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
        color: #ffffff

.button
    @include button
    