.upContainer
  display: flex
  padding-top: 3.7rem

.upLeftContainer
  margin-left: 14.7rem

.upRightContainer
  margin-left: 7rem

.displayFlex
  display: flex
  margin-top: 2rem
  .img
    display: inline-block
    width: 1rem
    height: 1rem
.title
  height: 2.1rem
  color: #003056
  font-size: 2.5rem
  line-height: 2.1rem

.subTitle
  margin-top: 1.8rem
  height: 1.2rem
  color: #003056
  font-size: 1.4rem
  font-weight: 600
  line-height: 2.1rem
  margin-bottom: 1.3rem

.namesCont1
  display: flex
  margin-top: .68rem

.namesCont2
  display: flex
  margin-top: .8rem

.names
  color: #003056
  font-weight: 700
  font-size: 1rem

.namesInfo
  color: #78a3be
  font-size: 1rem
  padding-left: .8rem

.namesInfo1
  margin-top: 1.6rem
  color: #78a3be
  font-size: 1rem

.checkBoxContainer
  margin-top: 1.5rem
  display: flex
.checkBox
  width: 1.6rem
  height: 1.6rem
  border: .05rem solid #b9cfdd
  background-color: #ffffff
  &__label
    padding-left: .5rem
.edit
  height: .84rem
  color: #dd373e
  font-size: 1rem
  margin-top: .8rem
  padding-right: .5rem

.redText
  height: .9rem
  color: #dd373e
  font-size: 1rem
  font-weight: 700
  line-height: 1.8rem

.downLeftContainer
  margin-left: 14.7rem
  margin-top: 2.7rem


.submitBtnContainer
  display: flex
  margin-bottom: 2.3rem
  margin-top: 3.1rem

.stripeBtn
  margin-left: 14.7rem
.card
  width: 20.7rem
  display: flex
  flex-direction: column
  div
    background: #ffffff
    padding: .6rem
    margin: .5rem
    border-radius: .3125rem
    box-shadow: 0 .1563rem .651rem #c6dceabf
    h4
      height: 1.36rem
      color: #003056
      font-size: 1.5rem
      line-height: 1.8rem
    h5
      height: .9rem
      color: #003056
      font-size: 1rem
      font-weight: 700
      line-height: 1.8rem
      margin-top: 1.1rem
    span
      font-size: .8rem



.upRightContainer
  color: #78a3be
  display: flex
  justify-content: space-between
  padding: .26rem

.spanUpRightContainer
  display: block
  margin-top: .9rem
  margin-left: -7rem

.upRightContainerHr
  margin: .1rem

.upRightContainerLast
  color: #003056
.payBtn
  margin-left: 15rem
  &__submite
    width: 11.5rem
    height: 3.1rem
    background-color: #dd373e
    color: #fff
    border-radius: 2rem
    border: none
    text-align: center
// .divTotal
//   padding: 0.4rem
//   background-color: #F1EFEE
//   width: 12rem
//   height: 2rem
//   display: flex
//   align-items: center
//   justify-content: space-between
//   margin:  1rem 0 7rem 0

.divTotal
  padding: 0.4rem
  background-color: #F1EFEE
  width: 20rem
  height: 3rem
  display: flex
  align-items: center
  justify-content: space-between
  margin:  1rem 0 6rem 0rem



