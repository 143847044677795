$screen-sm: 576px
$blue-color: #003056

@mixin button
    display: flex
    justify-content: center
    align-items: center
    font-size: 3rem
    line-height: 9rem
    padding: 0.4rem
    width: 35rem
    &:hover,
    &:active
        transform: scale(1.01)
        background-color: #0c4c81
        text-decoration: none
    &:focus
        outline: 0.01rem solid #0c4c81
        outline-offset: 0.3rem
.buttons
    display: none

@media (max-width: $screen-sm)
    .mobile_container
        margin: 0
        padding: 0
        position: absolute
        top: 0
        z-index: 20
        width: 100%
        height: 35rem
        max-height: 35rem
        border: 0
        .buttons
            position: absolute
            top: 24.5rem
            z-index: 20
            right: 4rem
            width: 35rem
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-between
            background-color: #fff
            border-radius: 0.6rem
            box-shadow: 0 0.3rem 0.5rem #c6dcea
            &::before
                content: ''
                position: absolute
                top: -2.4rem
                right: 3rem
                width: 0
                height: 0
                border: solid transparent
                border-left: 3rem solid transparent
                border-right: 3rem solid transparent
                border-bottom: 3rem solid #fff
                pointer-events: none
            &::after
                content: ''
                position: absolute
                transform: rotate(45deg)
                width: 3rem
                height: 3rem
                top: -0.5rem
                right: 4.5rem
                z-index: -1
                box-shadow: 0.2rem -0.3rem 1rem 0.5rem #c6dcea
            &__item
                @include button
                color: #78a3be
                background: #ffffff
                border: none
